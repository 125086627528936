import { Skeleton } from 'antd'

import { ArrayElements } from 'common/components/ArrayElements/ArrayElements'

import styles from './patientScheduleSkeleton.module.scss'
import type { FC } from 'react'
import { memo } from 'react'

export const PatientScheduleSkeleton: FC = memo(() => {
  return (
    <div className={styles.parent}>
      <Skeleton.Input active block className={styles.parentSkeleton} size='small' />

      <div className={styles.parentHeader}>
        <ArrayElements total={3}>
          <div className={styles.parentHeaderElement}>
            <ArrayElements total={3}>
              <Skeleton.Input active block className={styles.parentSkeleton} />
            </ArrayElements>
          </div>
        </ArrayElements>
      </div>
      <div className={styles.parentHeader}>
        <ArrayElements total={3}>
          <div className={styles.parentHeaderElement}>
            <ArrayElements total={3}>
              <Skeleton active />
            </ArrayElements>
          </div>
        </ArrayElements>
      </div>
    </div>
  )
})
