import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { PusherProvider } from 'app/providers/pusher/PusherProvider'

import { TabPanel } from 'common/components/TabPanel/TabPanel'
import { Staff } from 'features/Home/Book/Booking/Staff/Staff'
import type { FC } from 'react'
import { memo, useEffect, useMemo } from 'react'
import { PATH_SEGMENT } from 'routes/pathSegments'
import { generateRouter } from 'routes/routes.utils'
import { promotionConfig } from './config'
import { Documents } from './Documents/Documents'
import { PromotionDetails } from './PromotionDetails/PromotionDetails'
import { useLazyFetchPromotionsInfoQuery } from './state/promotionsApi'
import { Notifications } from './Notifications/Notifications'
import { Contracts } from './Contracts/Contracts'
import { usePrevious } from 'react-use'
import Schedule from 'features/Home/Book/Booking/Schedule/Schedule'

export const Promotion: FC = memo(() => {
  const navigate = useNavigate()

  const { bookingId, siteId } = useParams()

  const [fetchPromotion, { data: promotion, isLoading, isSuccess }] =
    useLazyFetchPromotionsInfoQuery()
  const previousSiteId = usePrevious(siteId)

  const routesConfig = useMemo(
    () => ({
      [`${PATH_SEGMENT.DETAILS}/*`]: (
        <PromotionDetails promotion={promotion} isLoading={isLoading} isSuccess={isSuccess} />
      ),
      [PATH_SEGMENT.SCHEDULE]: <Schedule booking={promotion} />,
      [`${PATH_SEGMENT.SCHEDULE}/:day`]: <Schedule booking={promotion} />,
      [PATH_SEGMENT.STAFF]: <Staff />,
      [PATH_SEGMENT.CONTRACTS]: <Contracts promotion={promotion} />,
      [PATH_SEGMENT.NOTIFICATIONS]: <Notifications promotion={promotion} isLoading={isLoading} />,
      [`${PATH_SEGMENT.DOCUMENTS}/*`]: <Documents />,
      [PATH_SEGMENT.ROOT]: <Navigate to={PATH_SEGMENT.DETAILS} replace />,
    }),
    [promotion, isLoading, isSuccess],
  )

  useEffect(() => {
    if (!bookingId || !siteId) {
      return
    }

    fetchPromotion({ bookingId, siteId })
  }, [bookingId, siteId])

  useEffect(() => {
    if (!!siteId && !!previousSiteId && siteId !== previousSiteId) {
      navigate(PATH_SEGMENT.ROOT)
    }
  }, [siteId, previousSiteId])

  return (
    <>
      <PusherProvider selectedSite={siteId} selectedLease={bookingId}>
        <>
          <TabPanel tabs={promotionConfig.tabs} />
          {generateRouter(routesConfig)}
        </>
      </PusherProvider>
    </>
  )
})
