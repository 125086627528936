import { UtilService } from 'common/services/utilService'

import { type DepositNote } from './type'

export const depositColumns = [
  {
    title: 'Credit Card',
    dataIndex: 'card_last',
    key: 'card_last',
    type: 'text',
    render: (card_last: number) => <p className='m-0'>•••• {card_last}</p>,
    sorter: (a: DepositNote, b: DepositNote) => a.card_last?.localeCompare(b.card_last),
  },
  {
    title: 'Payment',
    dataIndex: 'transaction_description',
    key: 'transaction_description',
    type: 'text',
    sorter: (a: DepositNote, b: DepositNote) =>
      a.transaction_description?.localeCompare(b.transaction_description),
  },

  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    type: 'text',
    render: (amount: number) => UtilService.numberToDollar(Number(amount)),
    sorter: (a: DepositNote, b: DepositNote) => a.amount?.localeCompare(b.amount),
  },

  {
    title: 'Date',
    dataIndex: 'created_at',
    key: 'created_at',
    type: 'text',
    render: (created_at: string) => UtilService.formatTableDate(created_at),
    sorter: (a: DepositNote, b: DepositNote) =>
      new Date(a.created_at).valueOf() - new Date(b.created_at).valueOf(),
  },
  {
    title: 'Status',
    dataIndex: 'transaction_status',
    key: 'transaction_status',
    type: 'text',
    render: (status: string) => (
      <p className='payment-status' data-status={status}>
        {status}
      </p>
    ),
    sorter: (a: DepositNote, b: DepositNote) =>
      a.transaction_status?.localeCompare(b.transaction_status),
  },
]
