import './guestLocationThumbnail.scss'

import type { FC } from 'react'

import { Col, Row } from 'antd'

import { Button } from 'common/components/Button/Button'
import { BUTTON_CONSTANTS } from 'common/constants/buttonConstants'
import type { ISiteSelector } from 'common/interfaces/ISiteSelector'

interface IProps {
  sites: ISiteSelector[]
  handleSelectSite: (siteSelector: string) => void
}

export const GuestLocationThumbnails: FC<IProps> = ({ sites = [], handleSelectSite }) => (
  <>
    <Row className='guest-thumb-container' gutter={[32, 32]}>
      {sites?.map((site) => {
        return (
          <Col
            className='card-container'
            key={site.code}
            onClick={() => handleSelectSite(site.code)}>
            <Row className='card' justify='center'>
              <Col xs={24} className='image-container'>
                <div
                  style={{
                    backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${site?.image_url})`,
                  }}
                  className='image'
                />
                <div className='site-info-container'>
                  <Row gutter={8} align='middle'>
                    <Col>
                      <p className='name'>{site.name} </p>
                    </Col>
                  </Row>
                  <Row>
                    <p className='address'>{site.address}</p>
                  </Row>
                </div>
              </Col>
              <Col>
                <Button>{BUTTON_CONSTANTS.CHOOSE_CONTINUE}</Button>
              </Col>
            </Row>
          </Col>
        )
      })}
    </Row>
  </>
)
