import type { FC } from 'react'
import React, { memo, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { Menu } from 'antd'
import { PATH_SEGMENT } from 'routes/pathSegments'

import styles from './menuWidget.module.scss'
import type { MenuWidgetProps } from './menuWidget.types'
import { profileMenuConfig } from 'features/Profile/config'

export const iconStyle = {
  color: 'var(--secondary-100)',
  fontSize: '16px',
}

const menuPaths = [
  PATH_SEGMENT.PERSONAL_PROFILE,
  PATH_SEGMENT.NOTIFICATIONS,
  PATH_SEGMENT.CHANGE_PASSWORD,
  PATH_SEGMENT.DEPOSIT,
  PATH_SEGMENT.MAILBOX_PROFILE,
  PATH_SEGMENT.CREDIT_CARDS,
  PATH_SEGMENT.BILLING_HISTORY,
  PATH_SEGMENT.PAYMENTS_HISTORY,
  PATH_SEGMENT.COMPLETED_BOOKINGS,
]

export const MenuWidget: FC<MenuWidgetProps> = memo(({ isStaff }) => {
  const location = useLocation()

  const selectedKeys = useMemo(() => {
    const pathname = location.pathname.split('/').at(-1)
    const selectedKey = menuPaths.find((segment) => pathname === segment)
    return selectedKey ? [selectedKey] : [PATH_SEGMENT.PERSONAL_PROFILE]
  }, [location.pathname])

  const openKeys = useMemo(() => {
    const accountsRoutes = [
      PATH_SEGMENT.PERSONAL_PROFILE,
      PATH_SEGMENT.NOTIFICATIONS,
      PATH_SEGMENT.CHANGE_PASSWORD,
    ]
    const financeRoutes = [
      PATH_SEGMENT.BILLING_HISTORY,
      PATH_SEGMENT.PAYMENTS_HISTORY,
      PATH_SEGMENT.DEPOSIT,
    ]
    if (accountsRoutes.includes(selectedKeys[0])) {
      return ['account']
    }
    if (financeRoutes.includes(selectedKeys[0])) {
      return ['finance']
    }
    return []
  }, [selectedKeys])

  return (
    <Menu
      className={styles.container}
      selectedKeys={selectedKeys}
      defaultOpenKeys={openKeys}
      mode='inline'
      items={profileMenuConfig.getMenus(isStaff)}
    />
  )
})
