import type { FC } from 'react'
import { memo, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { Calendar as BigCalendar, dateFnsLocalizer } from 'react-big-calendar'
import type { withDragAndDropProps } from 'react-big-calendar/lib/addons/dragAndDrop'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import { useLocation, useToggle } from 'react-use'

import classNames from 'classnames'
import {
  add,
  addMinutes,
  compareAsc,
  differenceInMinutes,
  format,
  getDay,
  getHours,
  getMinutes,
  isAfter,
  isSameMinute,
  parse,
  startOfWeek,
  sub,
  toDate,
} from 'date-fns'
import enUS from 'date-fns/locale/en-US'

import { useScreenWidth } from 'app/hooks/useScreenWidth'
import { useNotification } from 'app/providers'

import sendMessageIcon from 'assets/icons/send-message.svg'

import { Button } from 'common/components/Button/Button'
import { Show } from 'common/components/Show/Show'
import SpinnerOverlay from 'common/components/SpinnerOverlay/SpinnerOverlay'
import { ALERT_CONSTANTS } from 'common/constants/alertConstants'
import { ALT_CONSTANTS } from 'common/constants/altConstants'
import { BUTTON_CONSTANTS, BUTTON_MODIFIER } from 'common/constants/buttonConstants'
import { DATE_FORMAT } from 'common/constants/dateFormatConstants'
import { TIME_FORMAT } from 'common/constants/timeFormat'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { DateService } from 'common/services/dateService'
import { UtilService } from 'common/services/utilService'

import {
  ACTION_TYPES,
  ALLOW_RESCHEDULE_CURRENT_DAY_PAST_EVENTS,
  COUNT_AS_OVERLAPPING,
  INFO_CONSTANTS,
  MIN_LONG_PRESS_TO_ADD_SCHEDULE_MS,
  MIN_PRE_POPULATE_TIME,
  MIN_SCHEDULE_TIME_IN_MINUTES,
  PATIENT_STATUSES,
  ROOM_STATUS,
  STATUSES_TO_BE_HIDDEN_IN_PATIENT_ACTIVITY,
} from 'features/Home/constants/infoConstants'
import { useInfoManager } from 'features/Home/hooks/useInfoManager'
import { useMutationObserver } from 'features/Home/hooks/useMutationObserver'
import type { IPatientRoom } from 'features/Home/interfaces/IInfoPatient'
import type { IPatientSchedule } from 'features/Home/interfaces/IInfoSchedule'
import { InfoService } from 'features/Home/services/infoService'
import { UPDATE_INFO_DRAGGING } from 'features/Home/Book/state/slice/bookSlice'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'

import calendarEvent from './CalendarEvent/CalendarEvent'
import styles from './calendar.module.scss'

import './grid.css'
import type { CalendarProps } from './calendar.types'
import { ZoomControls } from './ZoomControls/ZoomControls'
import { Warning } from './Warning/Warning'
import { SendSMSConfirmation } from './SendSMSConfirmation/SendSMSConfirmation'
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'

const DndCalendar = withDragAndDrop(BigCalendar)

const locales = {
  'en-US': enUS,
}
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
})

export const Calendar: FC<CalendarProps> = memo(
  ({
    roomsHeader = [],
    message = null,
    subtitle,
    disabledEverything = false,
    isRealTimeTracker = false,
    handleDisplayEventDetails,
    createCustomAppointment,
  }) => {
    const [events, setEvents] = useState([])
    const [time, setTime] = useState(new Date())
    const [timeInterval, setTimeInterval] = useState<number>(5)
    const [timeIndicator, setTimeIndicator] = useState<any>(null)
    const [awaitConfirmationEvent, setAwaitConfirmationEvent] = useState<any>(null)
    const [showBusyNotification, setShowBusyNotification] = useToggle(false)
    const [isSendSmsConfirmVisible, setIsSendSmsConfirmVisible] = useToggle(false)
    const [allowAddSchedule, setAllowAddSchedule] = useState<boolean>(false)
    const [zoomControlsWidth, setZoomControlsWidth] = useState<number>(0)

    const location = useLocation()
    const dispatch = useAppDispatch()

    const { day } = useParams()
    const formattedDate = dayjs(day).format('YYYY-MM-DD')

    const { setNotification } = useNotification()
    const { isMobile, screenWidth } = useScreenWidth()
    const { updatePatientSchedule, sendInfoPatientsNotification } = useInfoManager(formattedDate)

    const timerRef = useRef(null)
    const calendarRef = useRef(null)
    const timeIndicatorRef = useRef(null)

    const {
      schedules,
      rooms,
      isFetching,
      isUpdating,
      isSendingNotifications,
      eventsToSendNotification,
      disabledIntervals,
      dayInterval,
      timeZone,
    } = useAppSelector((state) => state.bookReducer)

    const defaultDate = useMemo(() => {
      if (!formattedDate || !dayInterval) {
        return
      }

      return new Date(`${formattedDate} ${dayInterval.min}`)
    }, [formattedDate, dayInterval])

    useEffect(() => {
      const timeIndicator = document.getElementById('time-indicator')
      if (timeIndicator) {
        timeIndicator?.scrollIntoView({ block: 'center', behavior: 'auto' })
      }
    }, [timeIndicator])

    useEffect(() => {
      const observer = new MutationObserver(() => {
        const timeIndicatorElements = document.querySelectorAll('.rbc-current-time-indicator')
        if (timeIndicatorElements.length > 0) {
          const timeIndicator = timeIndicatorElements[0] as HTMLElement
          setTimeout(() => {
            if (!timeIndicatorRef.current) {
              setTimeIndicator(timeIndicator)
            }
            if (!isRealTimeTracker) {
              const timeIndicatorElementsArray = Array.from(timeIndicatorElements) as HTMLElement[]
              if (timeIndicatorElementsArray.length > 0) {
                timeIndicatorElementsArray.forEach((element) => {
                  element.style.display = 'none'
                  setTimeIndicator(timeIndicator)
                })
              }
              observer.disconnect()
            }
          })
        }
      })
      observer.observe(document.body, { childList: true, subtree: true })

      return () => {
        observer.disconnect()
      }
    }, [timeIndicator])

    useEffect(() => {
      const interval = setInterval(() => {
        setTime(new Date())
      }, 1000)

      return () => clearInterval(interval)
    }, [])

    useEffect(() => {
      updateDragging(false)
      window.document.addEventListener('wheel', handleManageTimeInterval, { passive: false })
      return () => {
        window.document.removeEventListener('wheel', handleManageTimeInterval)
      }
    }, [])

    useEffect(() => {
      const events: any = schedules
        .filter((item: IPatientSchedule) =>
          isRealTimeTracker
            ? !STATUSES_TO_BE_HIDDEN_IN_PATIENT_ACTIVITY.includes(item?.status?.code)
            : true,
        )
        .map((item: IPatientSchedule) => {
          return {
            id: item.id,
            title:
              item?.patient?.name || `${item?.patient?.first_name} ${item?.patient?.last_name}`,
            start: toDate(new Date(`${formattedDate} ${item.start_time}`)),
            end: toDate(new Date(`${formattedDate} ${item.end_time}`)),
            resourceId: `${item?.room?.label}/${item?.room?.value}`,
            status: item.status,
            temperature_bypass: item.temperature_bypass,
            isDraggable: true,
            current_location: item.current_location,
            sms_notification: item.sms_notification,
            error_sms_message: item.error_sms_message,
            notes: item.notes,
            temperature: item.temperature,
            consultation_staff: item.consultation_staff,
            appointment_start_time: item.appointment_start_time,
            appointment_end_time: item.appointment_end_time,
            original_room: item.original_room,
            room_type_id: item.room_type_id,
            procedure: item.procedure,
          }
        })
      setEvents(events)
    }, [schedules])

    const handleUpdateTimeIndicator = useCallback((mutationRecord: MutationRecord[]) => {
      const timeWrapper = document.getElementById('time-indicator')
      if (timeWrapper) {
        timeWrapper.innerHTML = format(
          DateService.getNowToServerZone(timeZone),
          TIME_FORMAT.TIME_GUTTER_PICKER_HH_SS,
        )
        timeWrapper.style.top = `calc(${
          (mutationRecord[0].target as HTMLElement).style.top
        } - 12px)`
      }
    }, [])

    useMutationObserver(timeIndicator, handleUpdateTimeIndicator, {
      attributes: true,
      attributeFilter: ['style'],
    })

    const handleZoomIn = () => {
      setTimeInterval((interval: number) => (interval > 1 ? interval - 1 : interval))
    }

    const handleZoomOut = () => {
      setTimeInterval((interval: number) => (interval < 5 ? interval + 1 : interval))
    }

    const updateDragging = (value: boolean) => {
      dispatch(UPDATE_INFO_DRAGGING(value))
    }

    const handleManageTimeInterval = (event: any): void => {
      if (event.altKey) {
        event.preventDefault()
        if (event.deltaY > 0)
          setTimeInterval((interval: number) => (interval > 1 ? interval - 0.1 : interval))
        else if (event.deltaY < 0)
          setTimeInterval((interval: number) => (interval < 5 ? interval + 0.1 : interval))
      }
    }

    const isBetween = (number: number, lowerBound: number, upperBound: number): boolean => {
      return number >= lowerBound && number <= upperBound
    }

    const shouldProceedToUpdate = ({
      resourceId,
      start,
      end,
      event,
      room,
      isResizing,
      isDrag,
    }: {
      resourceId: string
      start: any
      end: any
      event: any
      room: IPatientRoom
      isResizing?: boolean
      isDrag?: boolean
    }) => {
      const diffInMinutes = differenceInMinutes(end, start)

      if (diffInMinutes < MIN_SCHEDULE_TIME_IN_MINUTES) {
        setNotification({
          title: 'Update schedule error',
          description: `You're schedule can't be less than ${MIN_SCHEDULE_TIME_IN_MINUTES} minutes.`,
          type: ALERT_CONSTANTS.ERROR,
        })

        return false
      }

      const eventsFromRoom = isDrag
        ? events.filter(
            (item) =>
              item.resourceId === resourceId &&
              item.id !== event.id &&
              COUNT_AS_OVERLAPPING.includes(item.status.code),
          )
        : events.filter(
            (item) =>
              item.resourceId === resourceId &&
              item.id !== event.id &&
              COUNT_AS_OVERLAPPING.includes(item.status.code),
          )

      const { nrOfOverlappingEvents, overlappingEvents } =
        InfoService.getNumberOfOverlappingIntervals({ start, end }, eventsFromRoom, resourceId)

      if (!isBetween(nrOfOverlappingEvents, 1, room.max_patients || 1) && !room?.is_waiting_room) {
        setNotification({
          title: 'Update schedule error',
          description: `You reached the max number of ${
            room.max_patients || 1
          } patients at the same time for this room.`,
          type: ALERT_CONSTANTS.ERROR,
        })
        return false
      }

      const shouldApplyRoomRule = !InfoService.checkIfCurrentDateOverlapDisabledIntervals(
        disabledIntervals,
        room,
        dayInterval,
        timeZone,
      )
      if (
        shouldApplyRoomRule &&
        room.status &&
        room?.status?.code !== ROOM_STATUS.FREE &&
        room?.status?.code !== ROOM_STATUS.CLEANUP &&
        room?.status?.code !== ROOM_STATUS.BUSY &&
        !isResizing
      ) {
        return false
      }

      const { start: event_start, end: event_end, resourceId: eventRoom } = event
      const { overlappingEvents: overlappingEventsBeforeUpdate } =
        InfoService.getNumberOfOverlappingIntervals(
          { start: event_start, end: event_end },
          eventsFromRoom,
          eventRoom,
        )

      const shouldShowOverlappingEventsNotification =
        nrOfOverlappingEvents > 1 &&
        !UtilService.arrDeepEqual(overlappingEventsBeforeUpdate, overlappingEvents) &&
        room.value !== rooms[rooms.length - 1].value

      if (shouldShowOverlappingEventsNotification) {
        setAwaitConfirmationEvent({
          start,
          end,
          event,
          room,
        })

        return false
      }

      return true
    }

    const handleUpdatedEvents = async (adjustedData: any, event: any): Promise<boolean> => {
      const successUpdate = await updatePatientSchedule(adjustedData, event.id)
      return successUpdate
    }

    const proceedToUpdateEvents = async () => {
      const { start, end, event, room } = awaitConfirmationEvent
      const adjustedData = {
        room: room?.value,
        start_time: format(start, TIME_FORMAT.TIME_PICKER_HH_SS),
        end_time: format(end, TIME_FORMAT.TIME_PICKER_HH_SS),
        temperature_bypass: event.temperature_bypass,
        consultation_staff: event.consultation_staff,
        procedure_code: !event?.procedure?.isCustom ? event?.procedure?.code : null,
        procedure_description: event?.procedure?.description,
      }

      const copyOfEvents = [...events]

      const updated = events.map((item) =>
        item.id === event.id ? { ...item, end, start, resourceId: room.label } : item,
      )
      setEvents(updated)

      const successUpdate = await handleUpdatedEvents(adjustedData, event)

      if (!successUpdate) {
        setEvents(copyOfEvents)
      }

      setAwaitConfirmationEvent(null)
    }

    const handleResize: withDragAndDropProps['onEventResize'] = async (
      data: any,
    ): Promise<void> => {
      if (isUpdating || disabledEverything) return
      const { end, start, event, resourceId }: any = data

      const isPastAndHasStatus =
        DateService.isPast(start, timeZone) &&
        DateService.isPast(end, timeZone) &&
        !ALLOW_RESCHEDULE_CURRENT_DAY_PAST_EVENTS.includes(event.status.code)

      const isPastAtLeastOneDimension =
        DateService.isPast(start, timeZone) || DateService.isPast(end, timeZone)

      if (isPastAndHasStatus || isPastAtLeastOneDimension) return

      const isInsideDisabledInterval = disabledIntervals.some((disabledInterval: any) =>
        InfoService.checkIsOverlappingIntervals(disabledInterval, start, end, resourceId),
      )

      if (isInsideDisabledInterval) {
        return
      }

      const [label, value] = resourceId.split('/')
      const room = rooms.find((room: IPatientRoom) => room.label === label && room.value === value)
      const shouldUpdateEvent = shouldProceedToUpdate({
        resourceId,
        start,
        end,
        event,
        room,
        isResizing: true,
      })

      if (!shouldUpdateEvent) return

      const adjustedData = {
        room: room?.value,
        start_time: format(start, TIME_FORMAT.TIME_PICKER_HH_SS),
        end_time: format(end, TIME_FORMAT.TIME_PICKER_HH_SS),
        temperature_bypass: event.temperature_bypass,
        consultation_staff: event.consultation_staff,
        procedure_code: !event?.procedure?.isCustom ? event?.procedure?.code : null,
        procedure_description: event?.procedure?.description,
      }

      const copyOfEvents = [...events]
      const updated = events.map((item) => (item.id === event.id ? { ...item, end, start } : item))
      setEvents(updated)

      const successUpdate = await handleUpdatedEvents(adjustedData, event)
      if (!successUpdate) {
        setEvents(copyOfEvents)
      } else {
        dispatch(UPDATE_INFO_DRAGGING(false))
      }
    }

    const handleDrag: withDragAndDropProps['onEventDrop'] = async (props: any): Promise<void> => {
      updateDragging(false)

      if (isUpdating || disabledEverything) return

      const shouldDisableDrag =
        DateService.isPast(props.start, timeZone) &&
        DateService.isPast(props.end, timeZone) &&
        !ALLOW_RESCHEDULE_CURRENT_DAY_PAST_EVENTS.includes(props.event.status.code)
      if (shouldDisableDrag) return

      const { event, resourceId }: any = props
      let { start, end } = props
      const oldEvent = events.find((item: any) => item.id === event.id)

      const shouldMoveToCurrentLine =
        DateService.isPast(start, timeZone) &&
        (!isSameMinute(start, oldEvent.start) || oldEvent.resourceId !== resourceId)

      if (shouldMoveToCurrentLine) {
        let diffInMinutes = differenceInMinutes(end, start)
        start = DateService.getNowToServerZone(timeZone)
        end = add(start, { minutes: diffInMinutes })
      }

      const isInsideDisabledInterval = disabledIntervals.some((disabledInterval: any) =>
        InfoService.checkIsOverlappingIntervals(disabledInterval, start, end, resourceId),
      )

      if (isInsideDisabledInterval) {
        return
      }

      const [label, value] = resourceId.split('/')
      const room = rooms.find((room: IPatientRoom) => room.label === label && room.value === value)

      const shouldUpdateEvent = shouldProceedToUpdate({
        resourceId,
        start,
        end,
        event,
        room,
        isResizing: false,
        isDrag: true,
      })

      if (!shouldUpdateEvent) return

      const adjustedData = {
        room: room.value,
        consultation_staff: event.consultation_staff,
        start_time: format(
          start.getSeconds() >= 1 ? addMinutes(start, 1) : start,
          TIME_FORMAT.TIME_PICKER_HH_SS,
        ),
        end_time: format(
          end.getSeconds() >= 1 ? addMinutes(end, 1) : end,
          TIME_FORMAT.TIME_PICKER_HH_SS,
        ),
        temperature_bypass: event.temperature_bypass,
        procedure_code: !event?.procedure?.isCustom ? event?.procedure?.code : null,
        procedure_description: event?.procedure?.description,
      }

      const copyOfEvents = [...events]
      const updated = events.map((item) => {
        return item.id === event.id ? { ...item, end, start, resourceId } : item
      })

      setEvents(updated)
      const successUpdate = await handleUpdatedEvents(adjustedData, event)
      if (!successUpdate) {
        setEvents(copyOfEvents)
      }
    }

    const handleSendNotifications = () => {
      sendInfoPatientsNotification()
      setIsSendSmsConfirmVisible(false)
    }

    const timeToMinutes = (time: string) => {
      const parsedTime = parse(time, TIME_FORMAT.TIME_PICKER_HH_SS, new Date(formattedDate))
      const startOfDay = new Date(parsedTime).setHours(0, 0) // Resetting only hours and minutes to midnight
      return differenceInMinutes(parsedTime, startOfDay)
    }

    const getEventTimesInMinutes = (event: any) => {
      const startHour = getHours(event.start)
      const startMinutes = getMinutes(event.start)
      const endHour = getHours(event.end)
      const endMinutes = getMinutes(event.end)

      return {
        eventStartTimeInMinutes: timeToMinutes(`${startHour}:${startMinutes}`),
        eventEndTimeInMinutes: timeToMinutes(`${endHour}:${endMinutes}`),
      }
    }

    const getTimeOptionsInMinutes = (timeOptions: any) => {
      return {
        timeOptionEndStartTimeInMinutes: timeToMinutes(timeOptions[0].end_time),
        timeOptionStartEndTimeInMinutes: timeToMinutes(timeOptions[1].start_time),
      }
    }

    const handleSelectSlot = (event: any) => {
      const isSelectedOneSlot = event.slots.length === 2 && !allowAddSchedule
      if (DateService.isPast(event.start, timeZone) || disabledEverything || isSelectedOneSlot)
        return
      setAllowAddSchedule(false)
      const { start, resourceId } = event
      let { end } = event

      const diffInMinutes = differenceInMinutes(end, start)

      const [label, value] = event.resourceId.split('/')
      const selectedRoom = rooms.find(
        (room: IPatientRoom) => room.label === label && room.value === value,
      )

      const timeOptions = InfoService.getTimePickerOptions(
        schedules,
        formattedDate,
        timeZone,
        selectedRoom,
      )
      const { eventStartTimeInMinutes, eventEndTimeInMinutes } = getEventTimesInMinutes(event)
      const { timeOptionEndStartTimeInMinutes, timeOptionStartEndTimeInMinutes } =
        getTimeOptionsInMinutes(timeOptions)

      //Restrict select time before and after time options
      // if (
      //   eventStartTimeInMinutes < timeOptionEndStartTimeInMinutes ||
      //   eventEndTimeInMinutes > timeOptionStartEndTimeInMinutes
      // ) {
      //   return false
      // }

      const updatedEnd = add(start, { minutes: MIN_PRE_POPULATE_TIME })
      const disabledIntervalsForRoom = disabledIntervals
        .filter((interval: any) => interval.resourceId === resourceId)
        .sort((a: any, b: any) => compareAsc(a.start, b.start))

      const firstDisabledInterval = disabledIntervalsForRoom.find(
        (interval: any) => isAfter(interval.start, start) && isAfter(updatedEnd, interval.start),
      )

      const endDayDate = new Date(`${formattedDate} ${dayInterval.max}`)

      const dateToCheck = firstDisabledInterval?.start || endDayDate

      if (diffInMinutes < MIN_PRE_POPULATE_TIME && !isAfter(updatedEnd, dateToCheck)) {
        end = updatedEnd
      } else if (diffInMinutes < MIN_PRE_POPULATE_TIME && isAfter(updatedEnd, dateToCheck)) {
        end = dateToCheck
      }

      if (createCustomAppointment) {
        createCustomAppointment(start, end, resourceId)
      }
    }

    const handleSelecting = (event: any) => {
      if (DateService.isPast(event.start, timeZone) || disabledEverything) return false
      const [label, value] = event.resourceId.split('/')
      const selectedRoom = rooms.find(
        (room: IPatientRoom) => room.label === label && room.value === value,
      )
      const timeOptions = InfoService.getTimePickerOptions(
        schedules,
        formattedDate,
        timeZone,
        selectedRoom,
      )

      const disabledTime = InfoService.generateDisabledTimes(
        timeOptions,
        format(event.start, DATE_FORMAT.CALENDAR_DATE),
      )

      const startHour = getHours(event.start)
      const endHour = getHours(event.end)
      const startMinutes = getMinutes(event.start)
      const endMinutes = getMinutes(event.end)

      // const { eventStartTimeInMinutes, eventEndTimeInMinutes } = getEventTimesInMinutes(event)

      // const { timeOptionEndStartTimeInMinutes, timeOptionStartEndTimeInMinutes } =
      //   getTimeOptionsInMinutes(timeOptions)

      // //Restrict selecting time before and after time options
      // if (
      //   eventStartTimeInMinutes < timeOptionEndStartTimeInMinutes ||
      //   eventEndTimeInMinutes > timeOptionStartEndTimeInMinutes
      // ) {
      //   return false
      // }

      if (isUpdating) {
        return false
      }

      const endHourToCheck =
        endMinutes === 0
          ? getHours(sub(DateService.getDateByTime(`${endHour}:00`), { minutes: 1 }))
          : endHour

      if (
        !InfoService.checkAreAllHoursBetweenAvailable(startHour, endHourToCheck, disabledTime.hours)
      ) {
        return false
      }

      const updatedStart = add(DateService.getDateByTime(`${startHour}:${startMinutes}`), {
        minutes: 1,
      })

      const updatedEnd = sub(DateService.getDateByTime(`${endHour}:${endMinutes}`), { minutes: 1 })
      const startMinutesToCheck = getMinutes(updatedStart)
      const startHoursToCheck = getHours(updatedStart)
      const endMinutesToCheck = getMinutes(updatedEnd)
      const endHoursToCheck = getHours(updatedEnd)

      const shouldDisableMinute = !InfoService.checkAreAllMinutesBetweenAvailable(
        startHoursToCheck,
        endHoursToCheck,
        startMinutesToCheck,
        endMinutesToCheck,
        disabledTime,
      )

      if (shouldDisableMinute) {
        return false
      }

      if (
        DateService.getIsValidAndCurrentDay(event.start, timeZone) &&
        (DateService.isPast(event.start, timeZone) || DateService.isPast(event.end, timeZone))
      ) {
        return false
      }

      return true
    }

    const timeGutterWrapper = (prop: any) => {
      const { children } = prop
      const timeIndicator = document.getElementsByClassName('rbc-current-time-indicator')[0]
      let currentHour = ''

      if (Boolean(timeIndicator) && isRealTimeTracker) {
        currentHour = format(
          DateService.getNowToServerZone(timeZone),
          TIME_FORMAT.TIME_GUTTER_PICKER_HH_SS,
        )
      }
      const newElement = (
        <div
          key={currentHour}
          className={styles.time}
          style={{
            top: `calc(${(timeIndicator as HTMLElement)?.style?.top} - 12px)`,
          }}
          id='time-indicator'>
          {currentHour}
        </div>
      )

      if (isRealTimeTracker && currentHour) {
        children.props.children.push(newElement)
      }
      return children
    }

    const getIsEventDraggable = (event: any) => {
      const isSelectedDateFromPast = DateService.isPast(
        new Date(`${formattedDate} 23:59`),
        timeZone,
      )
      const isEventFromPast =
        isSelectedDateFromPast ||
        (DateService.isPast(event.start, timeZone) &&
          DateService.isPast(event.end, timeZone) &&
          !ALLOW_RESCHEDULE_CURRENT_DAY_PAST_EVENTS.includes(event.status.code))

      const isAllowedToDrag = InfoService.checkIfActionShouldBeVisible(
        ACTION_TYPES.RESCHEDULE,
        event.status.code,
      )

      return event.isDraggable && !isUpdating && !isEventFromPast && isAllowedToDrag
    }

    const handleAllowScheduleForSmallInterval = () => {
      timerRef.current = setTimeout(() => {
        setAllowAddSchedule(true)
      }, MIN_LONG_PRESS_TO_ADD_SCHEDULE_MS)
    }

    const handleCancelAllowScheduleForSmallInterval = () => {
      clearTimeout(timerRef.current)
    }

    const adjustHeaderWidth = (
      header: HTMLElement,
      isWaitingRoom: boolean,
      allRoomsAreShown: boolean,
    ) => {
      if (rooms.length === 1) {
        header.style.width = 'calc(100vw - 128px)'
      } else if (isWaitingRoom && allRoomsAreShown) {
        header.style.width = `calc(100vw - ${rooms.length * 380 - 128}px)`
      } else {
        header.style.width = '380px'
      }
    }

    const allRoomsAreShown = useMemo(() => {
      const sidebarWidth = 100
      const padding = 64
      const zoomControlsWidth = 45
      const roomsWidth = screenWidth - sidebarWidth - padding - zoomControlsWidth

      return rooms.length * 380 < roomsWidth
    }, [screenWidth, rooms])

    const adjustColumnWidth = (column: HTMLElement, index: number) => {
      const isWaitingRoom = rooms[index]?.is_waiting_room
      const width =
        rooms.length === 1
          ? 'calc(100% - 78px)'
          : isWaitingRoom && allRoomsAreShown
          ? `calc(100% - ${(rooms.length - 1) * 380 + 78}px)`
          : '380px'

      column.style.minWidth = width
      column.style.width = width
    }
    useLayoutEffect(() => {
      if (calendarRef.current && rooms.length > 0 && !isFetching) {
        const columns = calendarRef.current.querySelectorAll('.rbc-day-slot')
        columns.forEach(adjustColumnWidth)

        const headers = calendarRef.current.querySelectorAll('.rbc-row-resource')
        headers.forEach((header: HTMLElement, index: number) => {
          const isWaitingRoom = rooms[index]?.is_waiting_room
          adjustHeaderWidth(header, isWaitingRoom, allRoomsAreShown)
        })
      }
    }, [calendarRef, rooms, screenWidth, zoomControlsWidth, location])
    return (
      <>
        {message}
        <div
          ref={calendarRef}
          onMouseDown={handleAllowScheduleForSmallInterval}
          onTouchStart={handleAllowScheduleForSmallInterval}
          onMouseUp={handleCancelAllowScheduleForSmallInterval}
          onTouchEnd={handleCancelAllowScheduleForSmallInterval}
          className={classNames(styles.parentCalendar, {
            [styles.parentCalendarDisabled]: disabledEverything,
          })}>
          {isUpdating && <SpinnerOverlay />}
          {!isFetching && subtitle}
          <DndCalendar
            selectable
            defaultDate={defaultDate}
            timeslots={3}
            toolbar={null}
            events={[...events, ...disabledIntervals]}
            defaultView='day'
            components={{
              toolbar: null,
              event: (prop: any) => calendarEvent(prop, timeZone),
              timeGutterHeader: () => (
                <ZoomControls
                  onZoomIn={handleZoomIn}
                  onZoomOut={handleZoomOut}
                  setZoomControlsWidth={setZoomControlsWidth}
                />
              ),
              timeGutterWrapper: timeGutterWrapper,
            }}
            step={timeInterval}
            resources={roomsHeader}
            onEventDrop={handleDrag}
            onSelectEvent={handleDisplayEventDetails}
            showMultiDayTimes={false}
            onEventResize={handleResize}
            localizer={localizer}
            getNow={() => DateService.getNowToServerZone(timeZone, time)}
            formats={{
              timeGutterFormat: 'h:mm a',
              eventTimeRangeFormat: (time: any) => {
                const start = format(time.start, TIME_FORMAT.TIME_GUTTER_PICKER_HH_SS)
                const end = format(time.end, TIME_FORMAT.TIME_GUTTER_PICKER_HH_SS)
                return `${start}${isMobile ? '\n' : ' — '}${end}`
              },
            }}
            eventPropGetter={(event: any) => ({
              style: InfoService.getScheduleColor(event.status.code),
              className: classNames({
                'rbc-disabled-event': event.status.code === PATIENT_STATUSES.DISABLED,
                'rbc-event-over-temp': event.status.code === PATIENT_STATUSES.CHECKED_IN_ON_SITE,
                'rbc-fixed-event': !event.isDraggable,
              }),
            })}
            onDragStart={() => updateDragging(true)}
            resourceIdAccessor={({ resourceId }: any) => resourceId}
            resourceTitleAccessor={({ resourceTitle }: any) => resourceTitle}
            min={new Date(`${formattedDate} ${dayInterval.min}`)}
            max={new Date(`${formattedDate} ${dayInterval.max}`)}
            dayLayoutAlgorithm='no-overlap'
            draggableAccessor={getIsEventDraggable}
            onSelectSlot={handleSelectSlot}
            onSelecting={handleSelecting}
            tooltipAccessor={null}
          />

          <Show
            when={
              !DateService.isPast(new Date(`${formattedDate} 23:59`), timeZone) &&
              eventsToSendNotification.length > 0 &&
              !isRealTimeTracker
            }>
            <div className={styles.parentAction}>
              <Button
                modifier={BUTTON_MODIFIER.QUATERNARY}
                onClick={() => setIsSendSmsConfirmVisible(true)}
                loading={isSendingNotifications}>
                <span className='center' style={{ gap: 8 }}>
                  {!isSendingNotifications && (
                    <img
                      className={styles.parentActionIcon}
                      src={sendMessageIcon}
                      alt={ALT_CONSTANTS.ACTION_ICON}
                    />
                  )}
                  <span>Notify patients</span>
                </span>
              </Button>
            </div>
          </Show>
        </div>
        <Warning
          open={Boolean(awaitConfirmationEvent)}
          title={INFO_CONSTANTS.ARE_YOU_SURE}
          warning={INFO_CONSTANTS.MULTIPLE_PATIENTS_TITLE}
          okButtonText={INFO_CONSTANTS.YES}
          handleClose={() => setAwaitConfirmationEvent(null)}
          handleConfirm={proceedToUpdateEvents}
          hasCancel
        />

        <Warning
          open={showBusyNotification}
          title={INFO_CONSTANTS.INFO}
          warning={INFO_CONSTANTS.BUSY_ROOM_WARNING}
          handleClose={() => setShowBusyNotification(false)}
          okButtonText={BUTTON_CONSTANTS.CLOSE}
          handleConfirm={() => setShowBusyNotification(false)}
        />

        <SendSMSConfirmation
          open={isSendSmsConfirmVisible}
          handleConfirm={handleSendNotifications}
          handleClose={() => setIsSendSmsConfirmVisible(false)}
        />
      </>
    )
  },
)
