import type { FC } from 'react'
import { memo } from 'react'

import styles from './goodToKnowSection.module.scss'
import type { GoodToKnowSectionProps } from './goodToKnowSection.types'

export const GoodToKnowSection: FC<GoodToKnowSectionProps> = memo(({ title, children }) => {
  return (
    <div className={styles.parent}>
      <h4 className={styles.parentTitle}>{title}</h4>
      {children}
    </div>
  )
})
