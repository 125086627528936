export enum AUTH_VALIDATE_CONSTANTS {
  LOG_OUT = 'Log Out',
  LOCATION = 'Location',
  FIRST_NAME = 'First name',
  LAST_NAME = 'Last name',
  UPLOAD_DOCUMENTS = 'Upload Documents',
  DRIVER_LICENSE = 'Driver License',
  MEDICAL_LICENSE = 'Medical License',
  VALIDATE_IDENTITY = 'Validate your identity',
  VALIDATION_PROGRESS = 'Validation in progress',
  VALIDATION_FAILED = 'It seems you have to verify one or more documents',
  PLEASE_UPLOAD = 'Please upload the documents below to verify your identity',
  LICENSE_STATE = 'Enter Your License’s State',
  LICENSE_NUMBER = 'Enter Your License’s Number',
  UPLOAD_LATER = 'Upload Later',
  MOBILE_PHONE = 'Mobile phone',
}
