import type { FC } from 'react'
import { useCallback, useMemo } from 'react'

import classNames from 'classnames'

import cartIconFilled from 'assets/icons/cart-filled.svg'
import cartIcon from 'assets/icons/cart.svg'

import { ALT_CONSTANTS } from 'common/constants/altConstants'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'

import { useCartManager } from 'features/Cart/hooks/useCartManager'

import styles from './cartIcon.module.scss'
import { TOGGLE_IS_OPEN_CART_DETAILS } from 'features/Cart/state/slices/cartSlice'

interface IProps {
  isEmbedded?: boolean
  disabled?: boolean
}

export const CartIcon: FC<IProps> = ({ isEmbedded, disabled }) => {
  const dispatch = useAppDispatch()
  const { storageCart, mailboxCart, bookingCart, recurringCarts } = useCartManager()

  const { optionShopItems, optionTypeSlots, optionStaffList, optionTypeFixed } = useAppSelector(
    (state) => state.optionReducer,
  )
  const cartItemsLength = useMemo(() => {
    let total = 0
    const defaultArr = [
      optionShopItems?.length,
      bookingCart?.data?.rooms?.length,
      optionTypeSlots?.length,
      optionTypeFixed?.length,
      optionStaffList?.length ? 1 : 0,
      mailboxCart?.data ? 1 : 0,
      storageCart?.data ? 1 : 0,
      Object.keys(recurringCarts ?? {}).length,
    ]

    defaultArr.forEach((item) => {
      total += item || 0
    })

    return total
  }, [
    optionShopItems,
    bookingCart,
    optionTypeSlots,
    optionTypeFixed,
    optionStaffList,
    mailboxCart,
    storageCart,
    recurringCarts,
  ])

  const handleOpenCartDetails = useCallback(() => {
    dispatch(TOGGLE_IS_OPEN_CART_DETAILS())
  }, [])

  return (
    <button
      {...(!isEmbedded && { onClick: handleOpenCartDetails })}
      disabled={disabled}
      className={styles.parent}
      data-cy='cart-btn'>
      <span
        className={classNames(styles.parentNotification, {
          [styles.embeddedNotification]: isEmbedded,
        })}>
        {cartItemsLength}
      </span>
      {isEmbedded ? (
        <img draggable={false} src={cartIconFilled} alt={ALT_CONSTANTS.CART_ICON} />
      ) : (
        <img
          draggable={false}
          className={styles.parentIcon}
          src={cartIcon}
          alt={ALT_CONSTANTS.CART_ICON}
        />
      )}
    </button>
  )
}
