import type { FC } from 'react'

import { Checkbox, Tooltip } from 'antd'
import classNames from 'classnames'

import { useAuth } from 'app/providers'

import detailsIcon from 'assets/icons/info.svg'

import { InputNumber } from 'common/components/InputNumber/InputNumber'
import { Show } from 'common/components/Show/Show'
import { ALT_CONSTANTS } from 'common/constants/altConstants'
import { useAppSelector } from 'common/hooks/redux'

import { BOOKING_CONSTANTS } from 'features/Booking/constants/bookingConstants'
import type { IRoomOptionProduct } from 'features/Booking/interfaces/ILeaseInfoRoom'

import styles from './leaseOption.module.scss'

interface IProps {
  siteValue: number
  option: IRoomOptionProduct
  onCheck: (option: IRoomOptionProduct) => void
  onUpdate: (option: IRoomOptionProduct) => void
  selectedOption?: IRoomOptionProduct | undefined
  disabled?: boolean
}

export const LeaseOption: FC<IProps> = ({
  onCheck,
  option,
  siteValue,
  onUpdate,
  selectedOption,
  disabled = false,
}) => {
  const { reservedService } = useAppSelector((state) => state.leaseReducer)
  const { user } = useAuth()
  const handleCheckCurrentOption = (): void => {
    if (option?.max_stock && !option.stock) onCheck({ ...option, stock: 1 })
    else onCheck(option)
  }

  return (
    <div className={styles.parent}>
      <div
        className={classNames(styles.parentInfo, {
          [styles.siteOptionUnavailable]:
            !option.is_visible || reservedService?.includes(option.name),
          [styles.parentChecked]: selectedOption,
        })}>
        <Checkbox
          data-cy='lease-option'
          onClick={handleCheckCurrentOption}
          disabled={(!option.is_visible && !selectedOption) || disabled}
          checked={!!selectedOption || !!Boolean(option?.is_checked)}
          style={{ height: '100%' }}
        />

        <div
          className={classNames(styles.parentDescription, {
            [styles.parentContent]: option?.content,
          })}>
          <h4 className={styles.parentTitle}>{option.name}</h4>
          {option?.description ? (
            <Tooltip title={option.description}>
              <img
                src={detailsIcon}
                alt={ALT_CONSTANTS.ACTION_ICON}
                className={styles.parentInfoIcon}
              />
            </Tooltip>
          ) : (
            <p>{option?.content}</p>
          )}
        </div>
      </div>
      {option?.max_stock && (
        <div className={styles.parentStock}>
          <InputNumber
            style={{ width: 148 }}
            max={option?.max_stock}
            disabled={!selectedOption}
            visible={!option.is_visible}
            value={selectedOption?.stock || 1}
            onChange={(value) => onUpdate({ ...option, stock: value as number })}
          />
        </div>
      )}
      <div
        className={classNames(styles.optionPriceDetails, {
          [styles.siteOptionUnavailable]: !option.is_visible,
        })}>
        <span className={styles.optionPrice}>${option.price}</span>
        <Show when={user}>
          <span className={styles.optionPoints}>
            {option.price * siteValue} {BOOKING_CONSTANTS.POINTS}
          </span>
        </Show>
      </div>
    </div>
  )
}
