import type { ForwardRefRenderFunction } from 'react'
import { useEffect, useRef, forwardRef, useImperativeHandle } from 'react'
import SignatureCanvas from 'react-signature-canvas'

import classNames from 'classnames'

import styles from './signature.module.scss'

interface IProps {
  width: number
  height: number
  onFinish: (signature: string) => void
  signature?: string //base64String
  className?: string
  disabled?: boolean
}

const Signature: ForwardRefRenderFunction<{}, IProps> = (
  { width, height, onFinish, signature, className, disabled },
  ref,
) => {
  let signatureRef = useRef(null)

  useEffect(() => {
    // in case we need to autocomplete signature
    if (signatureRef.current && signature) {
      signatureRef.current.fromDataURL(signature)
    }
  }, [signature, signatureRef])

  useImperativeHandle(ref, () => ({
    clear: () => signatureRef.current.clear(),
  }))

  return (
    <div className={classNames(styles.parent, className)} data-disabled={disabled}>
      <SignatureCanvas
        penColor='black'
        ref={signatureRef}
        onEnd={() => onFinish(signatureRef.current.toDataURL())}
        canvasProps={{ width: width, height: height, className: 'signatureCanvas' }}
      />
    </div>
  )
}

export default forwardRef(Signature)
