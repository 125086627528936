export enum MODAL_LABELS {
  CHOOSE_LOCATION = 'Choose Location',
  CONFIRM_CLEAR_CART = 'Confirm location change',
  SUBMIT_TICKET = 'Submit a ticket',
  DELETE_STAFF = 'Delete staff',
  DELETE_PATIENT = 'Delete patient',
  NOTIFY_PATIENT = 'Notify Patient',
  REMOVE_STAFF = 'Remove Staff',
  CHOOSE_OPTIONS = 'Choose your options',
  CHOOSE_LEASE_TYPE = 'I would like to book a:',
  REQUEST_TO_COMPLETE = 'Request to Complete',
}
