import type { ErrorInfo } from 'react'
import { Component } from 'react'

import type { ErrorBoundaryProps, ErrorBoundaryState } from './errorBoundary.types'
import { Navigate } from 'react-router-dom'
import { PathUtils } from 'routes/routes.utils'

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error(error, errorInfo)
  }

  handleReloadPage() {
    window.location.reload()
  }

  render() {
    const { children } = this.props
    const { hasError } = this.state

    if (hasError) {
      return <Navigate to={PathUtils.getError('500')} />
    }

    return children
  }
}
