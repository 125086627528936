import { useMemo } from 'react'
import { BOOKING_TYPE_CODE } from 'features/Booking/constants/booking.constants'

export type useBookingReturnType = {
  isRoom: boolean
  isStorage: boolean
  isMailbox: boolean
  isRecurring: boolean
  isEhr: boolean
}

export const useBooking = (booking: Record<string, any>): useBookingReturnType => {
  const isRoom = useMemo(() => booking?.lease_type_code === BOOKING_TYPE_CODE.BOOKING, [booking])
  const isStorage = useMemo(() => booking?.lease_type_code === BOOKING_TYPE_CODE.STORAGE, [booking])
  const isMailbox = useMemo(() => booking?.lease_type_code === BOOKING_TYPE_CODE.MAILBOX, [booking])
  const isEhr = useMemo(() => booking?.lease_type_code === BOOKING_TYPE_CODE.EHR, [booking])

  const isRecurring = useMemo(
    () => !isRoom && !isStorage && !isMailbox && !isEhr,
    [isRoom, isStorage, isMailbox, isEhr],
  )

  return {
    isRoom,
    isStorage,
    isMailbox,
    isRecurring,
    isEhr,
  }
}
