import './appointmentProcedure.scss'

import { type FC, memo, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { AutoComplete, Input, Radio, Spin } from 'antd'

import { useAuth } from 'app/providers'

import { Label } from 'common/components/Label/Label'
import { INPUT_PLACEHOLDER } from 'common/constants/inputConstants'
import { useApiResponse } from 'common/hooks/useApiResponse'
import { useDebounce } from 'common/hooks/useDebounce'
import type { ITriggerRequest } from 'common/interfaces/IRequestResponse'
import { UtilService } from 'common/services/utilService'

import type { IPatientProcedure } from 'features/Home/interfaces/IInfoSchedule'
import { useLazyFetchProceduresQuery } from 'features/Home/Book/state/api/bookApi'
import type { AppointmentProcedureProps } from './appointmentProcedure.types'

const { getInitials } = UtilService

export const AppointmentProcedure: FC<AppointmentProcedureProps> = memo(
  ({ debounceTimeout = 500, label, required, setSelectedValue, selectedValue, ...rest }) => {
    const { siteId } = useParams()
    const { user } = useAuth()
    const [open, setOpen] = useState(false)
    const [fetchProcedures]: ITriggerRequest<IPatientProcedure[]> = useLazyFetchProceduresQuery()
    const [isLoading, setIsLoading] = useState(false)
    const [optionsData, setOptionsData] = useState<IPatientProcedure[]>([])
    const { processApiResponse } = useApiResponse()

    const [searchValue, setSearchValue] = useState<string>('')
    const debouncedValue = useDebounce<string>(searchValue, debounceTimeout)
    const defaultCustomCode = `${getInitials(user?.first_name, user?.last_name)}001`

    useEffect(() => {
      return () => {
        setSearchValue('')
      }
    }, [])

    useEffect(() => {
      if (!open) {
        setOptionsData([])
      }
    }, [open])

    useEffect(() => {
      const fetchData = async () => {
        if (debouncedValue.length >= 3 && siteId) {
          setIsLoading(true)
          const response = await fetchProcedures({ search: debouncedValue, siteId })
          processApiResponse(response, {
            successCallback: () => {
              setOptionsData(response.data || [])
            },
          })
          setIsLoading(false)
        }
      }
      fetchData()
    }, [debouncedValue])

    const defaultOption = (() => {
      return searchValue
        ? [
            {
              code: '',
              description: searchValue,
              isCustom: true,
            },
          ]
        : []
    })()

    const onClearSelectedValue = () => {
      setSelectedValue({ code: null, description: null })
    }

    useEffect(() => {
      const value = searchValue ? defaultOption[0] : selectedValue
      setSelectedValue(value)
    }, [searchValue])

    const onChangeValue = (val: string) => {
      if (!val) {
        onClearSelectedValue()
      }
      setSearchValue(val)
    }

    const options = [...defaultOption, ...(optionsData || [])]

    const Prefix = () => {
      const { code, description } = selectedValue || {}
      if (code || (code === null && description))
        return <b>[{selectedValue?.code || defaultCustomCode}]</b>
    }
    return (
      <div className='searchable-select-input-container'>
        <Label label={label} required={required} />
        <AutoComplete
          open={open}
          onDropdownVisibleChange={(status) => {
            setOpen(status)
            if (!status)
              if (!selectedValue.code) {
                setSearchValue('')
                onClearSelectedValue()
              }
          }}
          showSearch
          filterOption={false}
          onSearch={onChangeValue}
          value={selectedValue?.description}
          autoFocus
          allowClear
          onClear={onClearSelectedValue}
          className='input-container'
          fieldNames={{ value: 'code', label: 'description' }}
          options={options as any}
          getPopupContainer={(trigger) => trigger.parentNode}
          {...rest}
          dropdownRender={() => {
            return (
              <Spin spinning={isLoading}>
                <Radio.Group
                  className='searchable-dropdown-container'
                  value={selectedValue?.code || defaultCustomCode}>
                  {options.map(({ code, description, isCustom }) => {
                    const optionCode = code || defaultCustomCode
                    return (
                      <Radio
                        className='radio'
                        value={code}
                        key={code}
                        onClick={() => {
                          setOpen(false)
                        }}
                        onChange={() => {
                          setSelectedValue({ code: optionCode, description, isCustom })
                        }}>
                        <p>
                          <b className='code' data-custom={isCustom}>
                            [{optionCode}]
                          </b>{' '}
                          {description}
                        </p>
                      </Radio>
                    )
                  })}
                </Radio.Group>
              </Spin>
            )
          }}>
          <Input
            className='input-wrapper'
            prefix={<Prefix />}
            placeholder={INPUT_PLACEHOLDER.SEARCH_PROCEDURE}
            bordered={false}
          />
        </AutoComplete>
      </div>
    )
  },
)
