import { memo } from 'react'

import type { IRequestResponse, ITriggerRequest } from 'common/interfaces/IRequestResponse'

import MailboxForm from 'features/Profile/features/Mailbox/components/MailboxProfileForm/MailboxProfileForm'
import type { MailboxProfileType } from 'features/Profile/features/Mailbox/components/MailboxProfileForm/types'
import {
  useFetchMailboxProfileQuery,
  useUpdateMailboxProfileMutation,
} from 'features/SettingsOld/state/api/settingsApi'
import './mailbox-settings.scss'

export const Mailbox = memo(() => {
  const { data, isFetching: isFetchingProfile }: IRequestResponse<any> =
    useFetchMailboxProfileQuery()

  const [updateProfile, { isLoading: isUpdatingProfile }]: ITriggerRequest =
    useUpdateMailboxProfileMutation()

  const onSubmit = (submitData: MailboxProfileType, { resetForm }) => {
    const valueToSubmit = {
      ...submitData,
    }

    const forwardMailBoxFields = [
      'forwarding_address',
      'forwarding_city',
      'forwarding_state',
      'forwarding_zip',
      'forwarding_phone_number',
    ]

    if (!valueToSubmit.is_forward_mailbox) {
      forwardMailBoxFields.forEach((field) => {
        delete valueToSubmit[field]
      })
    }

    resetForm()

    updateProfile(valueToSubmit)
  }

  return (
    <>
      <h1 className='m-0'>Receive mail addressed to</h1>
      <MailboxForm
        onSubmit={onSubmit}
        data={data}
        isLoading={isFetchingProfile || isUpdatingProfile}
      />
    </>
  )
})
