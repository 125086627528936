import type { FC } from 'react'
import { useCallback } from 'react'
import { useParams } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import useNavigateParams from 'common/hooks/useNavigateParams'
import type { ISiteRoom } from 'common/interfaces/ISiteRoom'

import { BOOKING_PARAMS, BOOKING_TYPE } from 'features/Booking/constants/booking.constants'
import { CartShopExtension } from 'features/Cart/components/CartShopExtension/CartShopExtension'
import { CartShopServiceList } from 'features/Cart/components/CartShopServiceList/CartShopServiceList'
import { TOGGLE_IS_OPEN_CART_DETAILS } from 'features/Cart/state/slices/cartSlice'
import type { IOptionShopCartItem } from 'features/Option'
import { SET_OVERLAY_OPTION, TOGGLE_IS_OPEN_OPTION_OVERLAY } from 'features/Option'

import styles from './cartShopCardList.module.scss'

interface IProps {
  shopItem: IOptionShopCartItem
}

export const CartShopCardList: FC<IProps> = ({ shopItem }) => {
  const { isOpenCart } = useAppSelector((state) => state.cartReducer)
  const params = useParams()
  const navigate = useNavigateParams()
  const dispatch = useAppDispatch()
  const handleEditOption = useCallback(
    (option: ISiteRoom) => {
      if (params?.bookingId !== shopItem.bookingId || params?.tabKey !== 'shop')
        navigate(`/${shopItem.siteId}/home/booking/${shopItem.bookingId}/shop`, {
          [BOOKING_PARAMS.TYPE]: BOOKING_TYPE.BOOKING,
        })

      if (isOpenCart) dispatch(TOGGLE_IS_OPEN_CART_DETAILS())
      dispatch(SET_OVERLAY_OPTION(option))
      dispatch(TOGGLE_IS_OPEN_OPTION_OVERLAY())
    },
    [params],
  )

  return (
    <div className={styles.parent}>
      <CartShopServiceList
        options={shopItem?.options}
        bookingId={shopItem.bookingId}
        handleEdit={handleEditOption}
      />
      <CartShopExtension
        bookingId={shopItem.bookingId}
        handleEdit={handleEditOption}
        slotExtension={shopItem?.optionSlotExtension}
      />
    </div>
  )
}
