import { NavLink, useLocation, useNavigate } from 'react-router-dom'

import congratulation from 'features/Auth/assets/images/congratulations.png'
import logo from 'features/Auth/assets/images/logo2.svg'

import { Button } from 'common/components/Button/Button'
import { Show } from 'common/components/Show/Show'
import { ALT_CONSTANTS } from 'common/constants/altConstants'
import { BUTTON_CONSTANTS } from 'common/constants/buttonConstants'

import { AuthLogout } from 'features/Auth/components/AuthLogout/AuthLogout'
import { AUTH_CONSTANTS } from 'features/Auth/constants/authConstants'

import styles from './authValidationStatus.module.scss'
import { PATH_SEGMENT } from 'routes/pathSegments'
import { PathUtils } from 'routes/routes.utils'
import type { FC } from 'react'
import { memo } from 'react'

export const AuthValidationStatus: FC = memo(() => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const handleNavigateToValidate = () => navigate(PathUtils.getValidateIdentity())

  return (
    <div className={styles.parent}>
      <AuthLogout />
      <img className={styles.parentLogo} src={logo} alt={ALT_CONSTANTS.LOGO_ICON} />
      <div className={styles.parentContent}>
        <img src={congratulation} alt={ALT_CONSTANTS.CONGRATULATION_ICON} />
        <h1 className={styles.parentTitle}>{AUTH_CONSTANTS.CONGRATULATIONS}</h1>
        <p className={styles.parentDescription}>
          {pathname.includes('start')
            ? AUTH_CONSTANTS.THANK_YOU_START
            : AUTH_CONSTANTS.THANK_PROCESSING}
        </p>
        <Show when={pathname.includes('start')}>
          <div className={styles.parentValidateButton}>
            <Button onClick={handleNavigateToValidate}>{AUTH_CONSTANTS.VALIDATE_IDENTITY}</Button>
          </div>
        </Show>
        <NavLink to={PATH_SEGMENT.ROOT} className={styles.parentHomeButton}>
          <Button>{BUTTON_CONSTANTS.HOME}</Button>
        </NavLink>
      </div>
    </div>
  )
})
