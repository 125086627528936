import './deposit.scss'

import React, { memo } from 'react'

import { Spin } from 'antd'

import { ReactComponent as ShareCardIcon } from 'assets/icons/share-card.svg'

import { Table } from 'common/components/Table/Table'
import { useApiResponse } from 'common/hooks/useApiResponse'
import type { IRequestResponse } from 'common/interfaces/IRequestResponse'
import { UtilService } from 'common/services/utilService'

import {
  useFetchDepositQuery,
  useRequestDepositRefundMutation,
} from 'features/SettingsOld/state/api/settingsApi'

import { depositColumns } from './deposit.columns'
import type { DepositData } from './type'

const { numberToDollar } = UtilService

export const Deposit: React.FC = memo(() => {
  const { data: depositData, isLoading }: IRequestResponse<DepositData> = useFetchDepositQuery()
  const [requestDepositRefund, { isLoading: isRefundLoading }] = useRequestDepositRefundMutation()
  const { processApiResponse } = useApiResponse()

  const { deposit } = depositData ?? {}

  const depositValue = deposit?.value
  const requestStatus = deposit?.request_refund

  const isRefundAvailable = depositValue > 0 && Boolean(requestStatus)

  const getRefundMessage = () => {
    if (depositValue > 0) {
      return (
        <>
          <span>{numberToDollar(depositValue)}</span> available deposit
        </>
      )
    }
    return 'No deposit in your account'
  }

  const getIconStatus = () => {
    // green for value > 0 and  requestStatus = 1 | otherwise yellow
    if (depositValue > 0) {
      return requestStatus === 1 ? 1 : 2
    }
    return 0
  }

  const handleRequestDeposit = async () => {
    if (!isRefundAvailable) return
    const response = await requestDepositRefund()

    processApiResponse(response, {
      success: 'Deposit was requested!',
    })
  }

  return (
    <Spin spinning={isRefundLoading}>
      <div className='deposit-container'>
        <>
          <h1 className='m-0'>Deposit</h1>
          <Spin spinning={isLoading}>
            <div className='deposit-refund-container'>
              <div className='share-container'>
                <div className='share-icon' data-status={getIconStatus()}>
                  <ShareCardIcon />
                </div>
                {!isLoading && <div className='deposit-money'>{getRefundMessage()}</div>}
              </div>
              {depositValue > 0 && (
                <button
                  onClick={handleRequestDeposit}
                  disabled={!isRefundAvailable}
                  className='request-button'>
                  Request Deposit Refund
                </button>
              )}
            </div>
          </Spin>
        </>

        <div>
          <h1>Notes</h1>
          <ul className='deposit-notes'>
            <li>Deposit is saved in your account after your first booking</li>
            <li>30 days from your last booking you can request refund of the deposit</li>
          </ul>
        </div>
        <Table
          itemKey='id'
          columns={depositColumns}
          dataSource={depositData?.notes}
          isLoading={isLoading}
          haveActions={false}
        />
      </div>
    </Spin>
  )
})
