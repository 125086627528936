import { UtilService } from 'common/services/utilService'

import type { IRoomOptionProduct } from 'features/Booking/interfaces/ILeaseInfoRoom'
import type {
  ISlotExtensionRoom,
  ISlotExtensionRoom1,
} from 'features/Option/interfaces/IOptionShop'
import type { IOptionShopCartItem } from 'features/Option/interfaces/IOptionShopCartItem'
import type { ICalculateRoomsExtensionPriceProps } from 'features/Option/interfaces/IOptionSlotExtenionService'
import { OptionShopService } from 'features/Option/services/optionShopService'

const { structuredClone, deepEqual } = UtilService
const { findLeaseById, cleanupShopItems } = OptionShopService

const updateRoomService = (
  room: ISlotExtensionRoom1,
  service: IRoomOptionProduct,
): ISlotExtensionRoom1 => {
  const updatedRoom: ISlotExtensionRoom1 = structuredClone<ISlotExtensionRoom1>(room)

  const serviceIndex: number = updatedRoom.slot.selectedOptions.findIndex(
    ({ id }: IRoomOptionProduct): boolean => id === service.id,
  )
  if (serviceIndex === -1) updatedRoom.slot.selectedOptions.push(service)
  else updatedRoom.slot.selectedOptions.splice(serviceIndex, 1)

  return updatedRoom
}

const getIndexRoomByCode = (rooms: ISlotExtensionRoom1[], roomCode: number): number =>
  rooms.findIndex(({ room: rRoom }: ISlotExtensionRoom1): boolean => rRoom.code === roomCode)

const getRoomByCode = (
  rooms: ISlotExtensionRoom1[],
  roomCode: number,
): ISlotExtensionRoom1 | undefined =>
  rooms.find(({ room: rRoom }: ISlotExtensionRoom1): boolean => rRoom.code === roomCode)

const updateRooms = (
  rooms: ISlotExtensionRoom1[],
  roomInfo: ISlotExtensionRoom1,
): ISlotExtensionRoom1[] => {
  const { room } = roomInfo
  let updatedRooms: ISlotExtensionRoom1[] = structuredClone<ISlotExtensionRoom1[]>(rooms)

  const roomIndex: number = getIndexRoomByCode(rooms, room.code)

  if (roomIndex === -1) updatedRooms.push(roomInfo)
  else updatedRooms.splice(roomIndex, 1, roomInfo)

  // Clean up the structure
  updatedRooms = updatedRooms.filter(
    (room: ISlotExtensionRoom1): boolean => !!room.slot.selectedOptions.length,
  )

  return updatedRooms
}

const getIsDisabledAddToCart = (
  rooms: ISlotExtensionRoom1[],
  roomInfo: ISlotExtensionRoom1,
): boolean => {
  if (!roomInfo) return true
  const rRoom: ISlotExtensionRoom1 | undefined = getRoomByCode(rooms, roomInfo.room.code)
  const roomOptions: IRoomOptionProduct[] = roomInfo?.slot?.selectedOptions
  if (!rRoom && !roomOptions.length) return true

  return deepEqual(rRoom?.slot?.selectedOptions, roomOptions)
}

// V2
const getRoomsExtensionIds = (rooms: ISlotExtensionRoom[] | undefined) =>
  rooms ? rooms.map(({ code }: ISlotExtensionRoom): number => code) : []

const getRoomsExtensionByIds = (rooms: ISlotExtensionRoom[], ids: number[]): ISlotExtensionRoom[] =>
  rooms.filter(({ code }: ISlotExtensionRoom): boolean => ids.includes(code))

const calculateRoomsPrice = (rooms: ISlotExtensionRoom[]): number =>
  rooms.reduce((acc: number, room: ISlotExtensionRoom) => acc + room.price, 0)

const calculateRoomsExtensionPrice = ({
  rooms,
  roomsIds,
  initialRooms,
}: ICalculateRoomsExtensionPriceProps): number => {
  const initialPrice: number = calculateRoomsPrice(initialRooms)
  const updatedRooms: ISlotExtensionRoom[] = getRoomsExtensionByIds(rooms, roomsIds)
  const updatedPrice: number = calculateRoomsPrice(updatedRooms)

  if (!updatedPrice) return 0
  return updatedPrice - initialPrice
}

const updateRoomsExtension = (shopItems: IOptionShopCartItem[], shopItem: IOptionShopCartItem) => {
  const updatedExtensionPrice: number = calculateRoomsPrice(shopItem.optionSlotExtension.rooms)
  const updatedShopItem: IOptionShopCartItem = {
    ...shopItem,
    amount: { subtotal: updatedExtensionPrice },
  }
  if (!shopItems.length) return [updatedShopItem]
  const reservedShopItem: IOptionShopCartItem | undefined = findLeaseById(
    shopItems,
    shopItem.bookingId,
  )

  if (!reservedShopItem) return [...shopItems, updatedShopItem]

  return shopItems.map(
    (rShopItem: IOptionShopCartItem): IOptionShopCartItem =>
      shopItem.bookingId === rShopItem.bookingId ? { ...rShopItem, ...shopItem } : rShopItem,
  )
}

const removeRoomExtension = (
  bookingId: string,
  roomCode: number,
  shopOptions: IOptionShopCartItem[],
): IOptionShopCartItem[] => {
  const option: IOptionShopCartItem = findLeaseById(shopOptions, bookingId)

  const updatedOption: IOptionShopCartItem = structuredClone<IOptionShopCartItem>(option)

  updatedOption.optionSlotExtension.rooms = updatedOption.optionSlotExtension.rooms.filter(
    ({ code }: ISlotExtensionRoom): boolean => code !== roomCode,
  )

  updatedOption.optionSlotExtension.amount = calculateRoomsPrice(
    updatedOption.optionSlotExtension.rooms,
  )

  let updatedOptions: IOptionShopCartItem[] = shopOptions.map(
    (rOption: IOptionShopCartItem): IOptionShopCartItem =>
      rOption.bookingId === updatedOption.bookingId ? updatedOption : rOption,
  )

  // Clean up the structure
  updatedOptions = cleanupShopItems(updatedOptions)
  return updatedOptions
}

export const OptionSlotExtensionService = {
  updateRooms,
  updateRoomService,
  getIndexRoomByCode,
  calculateRoomsPrice,
  removeRoomExtension,
  getRoomsExtensionIds,
  updateRoomsExtension,
  getRoomsExtensionByIds,
  getIsDisabledAddToCart,
  calculateRoomsExtensionPrice,
}
