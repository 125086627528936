import type { FC } from 'react'

import { Button } from 'common/components/Button/Button'
import { BUTTON_MODIFIER } from 'common/constants/buttonConstants'

import { PAYMENT_CONSTANTS } from 'features/Payment/constants/paymentConstants'

import styles from './paymentBilling.module.scss'

interface IProps {
  isLoading: boolean
  isDisabled: boolean
  handleGoHome: () => void
  handleSubmit: () => Promise<void>
}

export const PaymentBilling: FC<IProps> = ({
  handleGoHome,
  handleSubmit,
  isLoading,
  isDisabled,
}) => {
  return (
    <div className={styles.parent}>
      <div className={styles.parentSubmit}>
        <Button
          data-cy='pay-now-btn'
          onClick={handleSubmit}
          disabled={isDisabled}
          loading={isLoading}>
          {PAYMENT_CONSTANTS.PAY_NOW}
        </Button>
        <Button
          data-cy='cancel-btn'
          onClick={handleGoHome}
          disabled={isLoading}
          modifier={BUTTON_MODIFIER.DEFAULT}>
          {PAYMENT_CONSTANTS.RETURN}
        </Button>
      </div>
    </div>
  )
}
