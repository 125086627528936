import type { ChangeEvent, FC } from 'react'
import { memo, useCallback, useEffect, useState } from 'react'
import { useDebounce } from 'react-use'

import { Col, Row } from 'antd'

import searchIcon from 'assets/icons/search.svg'
import { ReactComponent as RemoveIcon } from 'assets/icons/x-remove.svg'

import { Button } from 'common/components/Button/Button'
import { Input } from 'common/components/Input/Input'
import { BUTTON_MODIFIER } from 'common/constants/buttonConstants'
import type { TableSearchAndFiltersProps } from './tableSearchAndFilters.types'

import styles from './tableSearchAndFilters.module.scss'

export const TableSearchAndFilters: FC<TableSearchAndFiltersProps> = memo(
  ({ setTableParams, inputHint, tableParams, setColumns }) => {
    const [searchValue, setSearchValue] = useState('')

    useDebounce(
      () => {
        setTableParams((prev) => ({ ...prev, current: 1, debouncedValue: searchValue }))
      },
      300,
      [searchValue],
    )

    const handleSearch = useCallback((event: ChangeEvent<HTMLInputElement>) => {
      setSearchValue(event.target.value)
    }, [])

    useEffect(() => {
      if (tableParams.sorters) {
        setColumns((prev) =>
          prev.map((column) => ({
            ...column,
            sortOrder: tableParams.sorters?.[column.key]?.order,
          })),
        )
      }
    }, [tableParams.sorters])

    const handleResetSorters = () => {
      setColumns((prev) => prev.map((column) => ({ ...column, sortOrder: null })))

      setTableParams((prev) => {
        return {
          ...prev,
          sorters: {},
          search: '',
        }
      })
      setSearchValue('')
    }

    return (
      <Row justify={{ md: 'space-between', xs: 'center' }} className={styles.searchContainer}>
        <Col xxl={6} xl={8} md={12} xs={24}>
          <Input
            value={searchValue}
            name='search'
            prefix={<img src={searchIcon} alt='Search' className={styles.searchImg} />}
            onChange={handleSearch}
            placeholder='Search'
          />

          <p className={styles.hint}>{inputHint}</p>
        </Col>
        <Col>
          <Button
            modifier={BUTTON_MODIFIER.SECONDARY}
            onClick={handleResetSorters}
            className={styles.resetFilter}>
            <>
              <RemoveIcon />
              Reset filters
            </>
          </Button>
        </Col>
      </Row>
    )
  },
)
