import type { FC } from 'react'
import { useId } from 'react'

import { InputNumber as AntInputNumber } from 'antd'
import type { InputNumberProps } from 'antd/lib/input-number'

import { Label } from 'common/components/Label/Label'
import { SvgService } from 'common/services/svgService'

import styles from './inputNumber.module.scss'

import './inputNumber.css'

interface IProps extends InputNumberProps {
  label?: string
  visible?: boolean
}

export const InputNumber: FC<IProps> = ({
  disabled,
  visible,
  label,
  onChange,
  value,
  max,
  ...rest
}) => {
  const id = useId()
  return (
    <div>
      <Label label={label} id={id} />
      <AntInputNumber
        min={1}
        id={id}
        max={max}
        value={value}
        controls={false}
        disabled={disabled}
        className={visible ? 'input-number-disabled' : ''}
        addonBefore={
          <button
            className={styles.parent}
            disabled={disabled || value === 1}
            onClick={() => {
              let newValue = +JSON.stringify(value)
              if (newValue > 1) onChange((newValue -= 1))
            }}>
            {SvgService.getDecrementIcon(disabled ? '#AEAEB2' : '#7D7B7A')}
          </button>
        }
        addonAfter={
          <button
            className={styles.parent}
            disabled={disabled || value === max}
            onClick={() => {
              let newValue = +JSON.stringify(value)
              onChange((newValue += 1))
            }}>
            {SvgService.getIncrementIcon(disabled ? '#AEAEB2' : '#7D7B7A')}
          </button>
        }
        onChange={onChange}
        {...rest}
      />
    </div>
  )
}
