import type { FC } from 'react'

import type { UploadFile } from 'antd'
import { ErrorMessage, Field, useField } from 'formik'

import { Upload } from 'common/components/Upload/Upload'
import { FORM_CONTROL_TYPE } from 'common/constants/formControlConstants'
import type { IUpload } from 'common/interfaces/IUpload'

import styles from './formUpload.module.scss'

export const FormUpload: FC<IUpload> = ({ name, ...rest }) => {
  const [field, _, helpers] = useField(name)

  return (
    <div className={styles.parent}>
      <Field
        id={name}
        {...rest}
        {...field}
        component={Upload}
        fileList={field.value}
        onChange={(uploadedFiles: UploadFile[]) => helpers.setValue(uploadedFiles)}
        as={FORM_CONTROL_TYPE.TEXTAREA}
        data-cy={`${name}-form-control`}
      />
      <ErrorMessage component='p' className={styles.parentErrorInform} name={name} />
    </div>
  )
}
