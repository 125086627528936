export enum ALT_CONSTANTS {
  SHOT_ITEM_ICON = 'Shop Item Icon',
  INFO_ICON = 'Info Icon',
  EMOJI_ICON = 'Emoji Icon',
  LOGO_ICON = 'Logo Icon',
  STAR_ICON = 'Star Icon',
  ROOM_PHOTO = 'Room Photo',
  ACTIVE_ROUTE_ICON = 'Active Route Icon',
  STATUS = 'Status',
  NOTIFICATION_ICON = 'Notification Icon',
  CART_ICON = 'Cart Icon',
  DROPDOWN_STATUS_ICON = 'Dropdown Icon',
  STATUS_ICON = 'Status Icon',
  ACTIVE_BOOKING_IMAGE = 'Active Booking Image',
  CONGRATULATION_ICON = 'Congratulation',
  LICENSE_ICON = 'License Icon',
  LOCATION_IMAGE = 'Location Image',
  LOCATION_PIN = 'Location Pin',
  ACTION_ICON = 'Action Icon',
  UPLOADED_IMAGE = 'Uploaded Image',
  PAYMENT_TYPE_ICON = 'Payment type Icon',
  PROFILE = 'Profile',
  COUNTRY_ICON = 'Country Icon',
  ADD_STAFF = 'Add Staff',
  APPLE_LOGO = 'Apple Logo',
  GOOGLE_CALENDAR_ICON = 'Google Calendar Icon',
}
