import { useNavigate, useParams } from 'react-router-dom'

import { compareAsc, isSameMinute, toDate } from 'date-fns'
import { nanoid } from 'nanoid'

import { useNotification } from 'app/providers'

import { ALERT_CONSTANTS } from 'common/constants/alertConstants'
import { RESPONSE_PROPERTY_CONSTANTS } from 'common/constants/reponsePropertyConstants'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { useApiResponse } from 'common/hooks/useApiResponse'
import type { ITriggerRequest } from 'common/interfaces/IRequestResponse'

import type { ROOM_STATUS } from 'features/Home/constants/infoConstants'
import {
  PATIENT_STATUS_NAMES,
  PATIENT_STATUSES,
  ROOM_STATUS_NAMES,
  SMS_STATUSES,
} from 'features/Home/constants/infoConstants'
import type { IPatientRoom } from 'features/Home/interfaces/IInfoPatient'
import type { IPatientSchedule, IPatientStatus } from 'features/Home/interfaces/IInfoSchedule'
import { InfoService } from 'features/Home/services/infoService'
import {
  useCreatePatientScheduleMutation,
  useDeletePatientScheduleMutation,
  useLazyFetchPatientActivityQuery,
  useNotifyPatientMutation,
  useSendPatientsNotificationMutation,
  useUpdatePatientScheduleMutation,
  useUpdateRoomStatusMutation,
} from 'features/Home/Book/state/api/bookApi'
import {
  INSERT_INFO_EVENTS_TO_SEND_NOTIFICATION,
  INSERT_INFO_SCHEDULE,
  REMOVE_EVENTS_TO_NOTIFY,
  REMOVE_INFO_SCHEDULE,
  SET_IS_UPDATING_ROOM_STATUS,
  UPDATE_INFO_DAY_INTERVAL,
  UPDATE_INFO_DISABLED_INTERVALS,
  UPDATE_INFO_DOCTORS_BUFFER_TIME,
  UPDATE_INFO_EVENTS_TO_SEND_NOTIFICATION,
  UPDATE_INFO_IS_FETCHING,
  UPDATE_INFO_IS_UPDATING,
  UPDATE_INFO_PATIENT_STATUSES,
  UPDATE_INFO_PATIENTS,
  UPDATE_INFO_PATIENTS_CONSULTATION_STAFFS,
  UPDATE_INFO_PATIENTS_LANGUAGES,
  UPDATE_INFO_PATIENTS_SELECTION,
  UPDATE_INFO_ROOM_STATUS,
  UPDATE_INFO_ROOM_STATUSES,
  UPDATE_INFO_ROOMS,
  UPDATE_INFO_SCHEDULE,
  UPDATE_INFO_SCHEDULE_LOCATION,
  UPDATE_INFO_SCHEDULE_STATUS,
  UPDATE_INFO_SCHEDULES,
  UPDATE_INFO_SEND_NOTIFICATIONS,
  UPDATE_INFO_SKIP_PUSHER_EVENTS,
  UPDATE_INFO_TIME_ZONE,
  UPDATE_STATUSES_TO_PENDING,
} from 'features/Home/Book/state/slice/bookSlice'
import { PATH_SEGMENT } from 'routes/pathSegments'

export const useInfoManager = (day: string) => {
  const { bookingId, siteId } = useParams()

  const leaseConnectionDetails = {
    bookingId: bookingId || '',
    siteId: siteId || '',
  }

  const navigate = useNavigate()
  const { processApiResponse } = useApiResponse()
  const [fetchActivity] = useLazyFetchPatientActivityQuery()
  const [createSchedule]: ITriggerRequest = useCreatePatientScheduleMutation()
  const [updateSchedule]: ITriggerRequest = useUpdatePatientScheduleMutation()
  const [deleteSchedule]: ITriggerRequest = useDeletePatientScheduleMutation()
  const [notifyPatient]: ITriggerRequest = useNotifyPatientMutation()
  const [sendPatientsNotification]: ITriggerRequest = useSendPatientsNotificationMutation()
  const [updateRoomStatus]: ITriggerRequest = useUpdateRoomStatusMutation()

  const dispatch = useAppDispatch()
  const { setNotification } = useNotification()
  const { rooms, eventsToSendNotification } = useAppSelector((state) => state.bookReducer)

  const updateInfoSchedules = (schedules: any) => {
    dispatch(UPDATE_INFO_SCHEDULES(schedules))
  }

  const updateInfoRooms = (rooms: any) => {
    dispatch(UPDATE_INFO_ROOMS(rooms))
  }

  const updateRoomStatuses = (room_statuses: any) => {
    dispatch(UPDATE_INFO_ROOM_STATUSES(room_statuses))
  }

  const updatePatientStatuses = (patient_statuses: any) => {
    dispatch(UPDATE_INFO_PATIENT_STATUSES(patient_statuses))
  }

  const updateInfoPatients = (patients: any) => {
    dispatch(UPDATE_INFO_PATIENTS(patients))
  }

  const updateInfoPatientsSelection = (payload: any) => {
    dispatch(UPDATE_INFO_PATIENTS_SELECTION(payload))
  }

  const updateInfoPatientsLanguages = (languages: any) => {
    dispatch(UPDATE_INFO_PATIENTS_LANGUAGES(languages))
  }

  const updateInfoPatientsConsultationStaffs = (consultation_staffs: any) => {
    dispatch(UPDATE_INFO_PATIENTS_CONSULTATION_STAFFS(consultation_staffs))
  }

  const updateInfoDoctorSBufferTime = (bufferTime: any) => {
    dispatch(UPDATE_INFO_DOCTORS_BUFFER_TIME(bufferTime))
  }

  const updateInfoTimeZone = (timeZone: string) => {
    dispatch(UPDATE_INFO_TIME_ZONE(timeZone))
  }

  const updateInfoDisabledIntervals = (
    receivedRooms: IPatientRoom[],
    timeInterval: { min: string; max: string },
  ) => {
    const { minHour, maxHour } = getMinMaxHour(timeInterval)
    const currentDate = new Date(`${day} ${minHour}`)

    const disabledIntervals = receivedRooms
      .map((room: IPatientRoom) => {
        const sortedSlots = InfoService.getSortedSlots(room.slots, day)

        const updatedSlots = InfoService.getDisabledSlotsBasedOnSortedSlots(sortedSlots)

        if (!updatedSlots.length)
          return [
            {
              id: nanoid(),
              name: room.label,
              start: toDate(currentDate),
              end: toDate(new Date(`${day} ${maxHour}`)),
              resourceId: `${room.label}/${room.value}`,
              status: {
                code: PATIENT_STATUSES.DISABLED,
                name: PATIENT_STATUS_NAMES[PATIENT_STATUSES.DISABLED],
              },
              isDraggable: false,
            },
          ]

        const getEndDate = (index: number) => {
          if (updatedSlots[index + 1]?.start_time) {
            return toDate(updatedSlots[index + 1]?.start_time)
          }

          return toDate(new Date(`${day} ${maxHour}`))
        }

        const slotsDisabledIntervals = updatedSlots.map((slot, index) => ({
          id: nanoid(),
          name: room.label,
          start: toDate(slot.end_time),
          end: getEndDate(index),
          resourceId: `${room.label}/${room.value}`,
          status: {
            code: PATIENT_STATUSES.DISABLED,
            name: PATIENT_STATUS_NAMES[PATIENT_STATUSES.DISABLED],
          },
          isDraggable: false,
        }))

        if (compareAsc(updatedSlots[0]?.start_time, currentDate) > 0) {
          slotsDisabledIntervals.unshift({
            id: nanoid(),
            name: room.label,
            start: toDate(currentDate),
            end: toDate(updatedSlots[0].start_time),
            resourceId: `${room.label}/${room.value}`,
            status: {
              code: PATIENT_STATUSES.DISABLED,
              name: PATIENT_STATUS_NAMES[PATIENT_STATUSES.DISABLED],
            },
            isDraggable: false,
          })
        }

        if (
          isSameMinute(
            slotsDisabledIntervals[slotsDisabledIntervals.length - 1].start,
            slotsDisabledIntervals[slotsDisabledIntervals.length - 1].end,
          )
        ) {
          slotsDisabledIntervals.pop()
        }

        return slotsDisabledIntervals
      })
      .flat()

    dispatch(UPDATE_INFO_DISABLED_INTERVALS(disabledIntervals))
  }

  const insertSchedule = (schedule: any) => {
    dispatch(INSERT_INFO_SCHEDULE(schedule))
  }

  const updateIsFetching = (isFetching: boolean) => {
    dispatch(UPDATE_INFO_IS_FETCHING(isFetching))
  }

  const updateIsUpdating = (isFetching: boolean) => {
    dispatch(UPDATE_INFO_IS_UPDATING(isFetching))
  }

  const removeSchedule = (id: number) => {
    dispatch(REMOVE_INFO_SCHEDULE(id))
  }

  const updateInfoSchedule = (schedule: any) => {
    dispatch(UPDATE_INFO_SCHEDULE(schedule))
  }

  const formatTime = (time: string) => {
    const [hours, minutes] = time.split(':').map((item) => parseInt(item, 10))
    const formattedHours = hours.toString().padStart(2, '0')
    const formattedMinutes = minutes.toString().padStart(2, '0')
    return `${formattedHours}:${formattedMinutes}`
  }

  const getMinMaxHour = (timeInterval: { min: string; max: string }) => {
    const { min, max } = timeInterval
    const minHour = formatTime(min)
    const maxHour = formatTime(max)
    return { minHour, maxHour }
  }

  const updateInfoTimeInterval = (timeInterval: any) => {
    const { minHour, maxHour } = getMinMaxHour(timeInterval)
    dispatch(UPDATE_INFO_DAY_INTERVAL({ min: minHour, max: maxHour }))
  }

  const updateSkipEvents = (skip: boolean) => {
    dispatch(UPDATE_INFO_SKIP_PUSHER_EVENTS(skip))
  }

  const updateScheduleLocation = (scheduleId: number, location: any) => {
    dispatch(UPDATE_INFO_SCHEDULE_LOCATION({ scheduleId, location }))
  }

  const updateScheduleStatus = (
    scheduleId: number,
    status: IPatientStatus,
    status_text: string,
    temperature: number,
  ) => {
    dispatch(UPDATE_INFO_SCHEDULE_STATUS({ scheduleId, status, status_text, temperature }))
  }

  const updateInfEventsToSendNotification = (appointmentIds: number[]) => {
    dispatch(UPDATE_INFO_EVENTS_TO_SEND_NOTIFICATION(appointmentIds))
  }

  const updateInfoScheduleRoomStatus = (roomId: number, status: any) => {
    dispatch(UPDATE_INFO_ROOM_STATUS({ roomId, status }))
  }

  const updateInfoSendNotifications = (isSending: boolean) => {
    dispatch(UPDATE_INFO_SEND_NOTIFICATIONS(isSending))
  }

  const insertInfoEventsToSendNotifications = (appointmentId: number) => {
    dispatch(INSERT_INFO_EVENTS_TO_SEND_NOTIFICATION(appointmentId))
  }

  const updateInfEventsSendNotificationsStatuses = (eventsToSendNotification: any[]) => {
    dispatch(UPDATE_STATUSES_TO_PENDING(eventsToSendNotification))
  }

  const removeInfoPatientToNotify = (appointmentId: number) => {
    dispatch(REMOVE_EVENTS_TO_NOTIFY(appointmentId))
  }

  const setIsUpdatingInfoRoomStatus = (isUpdating: boolean) => {
    dispatch(SET_IS_UPDATING_ROOM_STATUS(isUpdating))
  }

  const fetchInfoData = async (displayLoading?: boolean) => {
    if (!day) return
    updateIsFetching(displayLoading != null ? displayLoading : true)
    const response = await fetchActivity({
      ...leaseConnectionDetails,
      date: day,
    })
    if (!response.data) {
      navigate(PATH_SEGMENT.LOGIN)
      return
    }
    const {
      items = [],
      rooms,
      languages,
      consultation_staffs,
      doctor_buffer_time,
      room_statuses,
      patients,
      patient_statuses,
      time_interval,
    } = response?.data ?? {}
    const appointmentsToSendNotifications = items
      .filter(
        (appointment: IPatientSchedule) =>
          appointment?.sms_notification === SMS_STATUSES.UNCONFIRMED &&
          appointment?.status?.code === PATIENT_STATUSES.UNCONFIRMED,
      )
      .map((appointment: IPatientSchedule) => appointment.id)

    updateInfoSchedules(items)
    updateInfoRooms(rooms)
    updateInfoPatients(patients)
    updateInfoPatientsLanguages(languages)
    updateInfoPatientsConsultationStaffs(consultation_staffs)
    updateInfoDoctorSBufferTime(doctor_buffer_time)
    updateRoomStatuses(room_statuses)
    updatePatientStatuses(patient_statuses)
    updateInfoTimeZone(time_interval?.time_zone)

    if (rooms.length) {
      updateInfoTimeInterval(time_interval)
      updateInfoDisabledIntervals(rooms, time_interval)
      updateInfEventsToSendNotification(appointmentsToSendNotifications)
    }
    updateIsFetching(false)
  }

  const createPatientSchedule = async (data: any) => {
    updateIsUpdating(true)
    updateSkipEvents(true)
    const response = await createSchedule({
      ...leaseConnectionDetails,
      data,
      date: day,
    })
    processApiResponse(response, {
      error: 'Create schedule error',
    })
    if (response.hasOwnProperty(RESPONSE_PROPERTY_CONSTANTS.SUCCESS)) {
      const { data } = response
      const room = rooms.find((room: IPatientRoom) => room.value === data.room)

      insertSchedule({ ...data, room })
      if (data.status.code === PATIENT_STATUSES.UNCONFIRMED)
        insertInfoEventsToSendNotifications(data.id)
    }
    updateIsUpdating(false)
  }

  const updatePatientSchedule = async (data: any, id: number) => {
    updateIsUpdating(true)
    updateSkipEvents(true)
    const updatedData = { ...data }

    const response = await updateSchedule({
      ...leaseConnectionDetails,
      id,
      data: updatedData,
      date: day,
    })
    processApiResponse(response, {
      error: 'Update schedule error',
    })
    let responseSuccess = true
    if (response.hasOwnProperty(RESPONSE_PROPERTY_CONSTANTS.SUCCESS)) {
      const { data } = response
      const room = rooms.find((room: IPatientRoom) => room.value === data.room)

      updateInfoSchedule({ ...response.data, room })

      if (
        response.data.sms_notification === SMS_STATUSES.UNCONFIRMED &&
        response.data.status.code === PATIENT_STATUSES.UNCONFIRMED
      ) {
        insertInfoEventsToSendNotifications(data.id)
      }
    } else {
      responseSuccess = false
    }
    updateIsUpdating(false)
    return responseSuccess
  }

  const sendInfoPatientsNotification = async () => {
    updateInfoSendNotifications(true)
    const response = await sendPatientsNotification({
      ...leaseConnectionDetails,
      data: { appointment_ids: eventsToSendNotification },
    })

    const isSuccess = !!response.data

    setNotification({
      title: '',
      description: isSuccess ? 'Sending text messages ...' : String(response.error.data),
      type: isSuccess ? ALERT_CONSTANTS.SUCCESS : ALERT_CONSTANTS.ERROR,
    })

    if (response.hasOwnProperty(RESPONSE_PROPERTY_CONSTANTS.SUCCESS)) {
      updateInfEventsSendNotificationsStatuses(eventsToSendNotification)
      updateInfEventsToSendNotification([])
    }

    updateInfoSendNotifications(false)
  }

  const removePatientSchedule = async (id: number) => {
    updateIsUpdating(true)
    updateSkipEvents(true)

    const response = await deleteSchedule({
      ...leaseConnectionDetails,
      id,
      date: day,
    })
    processApiResponse(response, {
      error: 'Delete schedule error',
    })
    if (response.hasOwnProperty(RESPONSE_PROPERTY_CONSTANTS.SUCCESS)) {
      removeSchedule(id)
    }
    updateIsUpdating(false)
    removeInfoPatientToNotify(id)
  }

  const handleNotifyPatient = async (id: number) => {
    const response = await notifyPatient({
      ...leaseConnectionDetails,
      id,
    })
    processApiResponse(response, {
      error: 'Notification sending error',
      successCallback: () => {
        setNotification({
          title: 'Success',
          description: 'Patient will be notified via text message',
          type: ALERT_CONSTANTS.SUCCESS,
        })
      },
    })
  }

  const updateInfoRoomStatus = async (value: number, room: IPatientRoom) => {
    setIsUpdatingInfoRoomStatus(true)
    const updatedValue = {
      status_type_code: value,
      lease_id: leaseConnectionDetails.bookingId,
    }
    const response = await updateRoomStatus({
      ...leaseConnectionDetails,
      roomId: room.id,
      data: updatedValue,
    })
    if (response.hasOwnProperty(RESPONSE_PROPERTY_CONSTANTS.SUCCESS)) {
      updateInfoPatientsSelection({
        room,
        patients: response?.data?.patients || [],
        isPatientSelectionVisible: Boolean(response?.data?.patients?.length),
      })
      updateInfoScheduleRoomStatus(room.id, {
        code: value,
        name: ROOM_STATUS_NAMES[value as ROOM_STATUS],
      })
    } else {
      setNotification({
        title: 'Update room status error',
        description: response.error.data,
        type: ALERT_CONSTANTS.ERROR,
      })
    }
    setIsUpdatingInfoRoomStatus(false)
  }

  return {
    fetchInfoData,
    createPatientSchedule,
    updatePatientSchedule,
    removePatientSchedule,
    updateSkipEvents,
    updateInfoSchedule,
    updateInfoRoomStatus,
    insertSchedule,
    removeSchedule,
    updateScheduleLocation,
    updateScheduleStatus,
    updateInfoScheduleRoomStatus,
    sendInfoPatientsNotification,
    removeInfoPatientToNotify,
    handleNotifyPatient,
    updateInfoPatientsSelection,
  }
}
