import type { FC, JSX } from 'react'
import { memo } from 'react'

import { Slot } from 'features/Home/Book/Booking/BookingDetails/BookingDays/SlotList/Slot/Slot'
import type { IInfoSlot } from 'features/Home/interfaces/IInfo'

import styles from './slotList.module.scss'
import type { SlotListProps } from './slotList.types'
import { SlotSkeletonList } from 'features/Home/Book/Booking/BookingDetails/BookingDays/SlotSkeletonList/SlotSkeletonList'

export const SlotList: FC<SlotListProps> = memo(({ slots, isFetching, isLeaseActive }) => {
  if (isFetching)
    return (
      <div className={styles.parent}>
        <SlotSkeletonList />
      </div>
    )
  return (
    <div className={styles.parent}>
      {slots?.map(
        (slot: IInfoSlot, index: number): JSX.Element => (
          <Slot slot={slot} key={index} isLeaseActive={isLeaseActive} />
        ),
      )}
    </div>
  )
})
