import type { FC } from 'react'
import { useEffect, useState } from 'react'

import { Viewer, Worker } from '@react-pdf-viewer/core'

import { Modal } from 'common/components/Modal'
import { Show } from 'common/components/Show/Show'
import { ALT_CONSTANTS } from 'common/constants/altConstants'
import { UPLOAD_CONSTANTS } from 'common/constants/uploadConstants'

import './modalPreviewFile.scss'
import '@react-pdf-viewer/core/lib/styles/index.css'

interface IProps {
  onClose: () => void
  previewFile: any
}

const WORKER_URL = 'https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js'

const ModalPreviewFile: FC<IProps> = ({ previewFile, onClose }) => {
  const [file, setFile] = useState<{ type: string; src: string } | null>(null)

  useEffect(() => {
    switch (true) {
      case !previewFile:
        setFile(null)
        break
      case previewFile?.type === 'application/pdf':
        setFile({
          type: previewFile.type,
          src: URL.createObjectURL(previewFile.originFileObj || previewFile),
        })
        break
      case ['image/png', 'image/jpeg'].includes(previewFile?.type):
        let reader = new FileReader()
        reader.readAsDataURL(previewFile.originFileObj || previewFile)
        reader.onloadend = (e: ProgressEvent<FileReader>) =>
          setFile({
            type: previewFile.type,
            src: e?.target?.result as string,
          })
        break
      case previewFile.includes('pdf'):
        setFile({
          type: 'application/pdf',
          src: previewFile,
        })
        break
      case previewFile.includes('jpg') || previewFile.includes('png'):
        setFile({
          type: 'image/jpeg',
          src: previewFile,
        })
        break
    }
  }, [previewFile])

  return (
    <Modal keyboard open={!!file} onCancel={onClose} title={UPLOAD_CONSTANTS.PREVIEW}>
      <div>
        <Show
          when={file?.type === 'application/pdf'}
          fallback={
            <img src={file?.src} className='preview-img' alt={ALT_CONSTANTS.ACTION_ICON} />
          }>
          <div style={{ width: 700, height: 700 }}>
            <Worker workerUrl={WORKER_URL}>
              <Viewer fileUrl={file?.src} />
            </Worker>
          </div>
        </Show>
      </div>
    </Modal>
  )
}

export default ModalPreviewFile
