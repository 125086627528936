import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { usePrevious, useToggle } from 'react-use'

import type { UploadFile } from 'antd'
import { Col, Row, Skeleton } from 'antd'
import type { FormikValues } from 'formik'

import { useNotification } from 'app/providers'

import { ModalPreviewFile } from 'common/components/Modal'
import { ALERT_CONSTANTS } from 'common/constants/alertConstants'
import { RESPONSE_PROPERTY_CONSTANTS } from 'common/constants/reponsePropertyConstants'
import type { IUploadedFileSrc } from 'common/interfaces/IUploadedFileSrc'
import { UtilService } from 'common/services/utilService'

import { SupportInfo } from 'features/Support/components/SupportInfo/SupportInfo'
import { SupportMessageList } from 'features/Support/components/SupportMessageList/SupportMessageList'
import { SupportNewMessage } from 'features/Support/components/SupportNewMessage/SupportNewMessage'
import type { ITicketMessage } from 'features/Support/interfaces/ISupport'
import {
  useAddMessageOnTicketMutation,
  useLazyFetchTicketDetailsQuery,
} from 'features/Support/state/api/supportApi'

import styles from './supportTicketDetails.module.scss'

export const SupportTicketDetails = () => {
  const [previewFile, setPreviewFile] = useState<string>('')
  const [messages, setMessages] = useState<ITicketMessage[]>([])
  const [isAddMessage, toggleIsOpenAddMessage] = useToggle(true)
  const [uploadedFiles, setUploadedFiles] = useState<UploadFile[]>([])

  const navigate = useNavigate()

  const { ticketId, siteId } = useParams()
  const { setNotification } = useNotification()

  const [fetchTicketDetails, { data, error, isLoading }] = useLazyFetchTicketDetailsQuery()

  const [sendMessage, { isLoading: isCreatingMessage }] = useAddMessageOnTicketMutation()

  const prevTicketMessages = usePrevious(data?.messages)

  useEffect(() => {
    if (!ticketId || !siteId) {
      return
    }

    fetchTicketDetails({ id: ticketId, siteId })
  }, [siteId, ticketId])

  useEffect(() => {
    const isValidData = Array.isArray(data?.messages) && Array.isArray(prevTicketMessages)
    const hasNewMessage =
      isValidData && !UtilService.deepEqual(prevTicketMessages, data?.messages, ['path'])
    if (hasNewMessage && !data?.messages[0].author) {
      setNotification({
        title: 'New message',
        description: `You have received a new message from ${data?.messages[0].user.name}`,
        type: ALERT_CONSTANTS.SUCCESS,
      })
      const messageList: ITicketMessage[] = data?.messages.slice()
      messageList[0] = { ...data.messages[0], notified: true }
      setMessages(messageList)
    } else if (messages.length !== data?.messages.length && Array.isArray(data?.messages))
      setMessages(data?.messages)
  }, [data])

  const handlePreviewFile = useCallback((file: IUploadedFileSrc): void => {
    setPreviewFile(file.path)
  }, [])

  const handleSendMessage = async (values: FormikValues, resetForm: () => void): Promise<void> => {
    const attachments: IUploadedFileSrc[] = values.attachments.map(
      (file: UploadFile): IUploadedFileSrc => file.response.file,
    )
    const response = await sendMessage({
      id: ticketId,
      siteId,
      data: { ...values, attachments: attachments },
    })
    if (response.hasOwnProperty(RESPONSE_PROPERTY_CONSTANTS.SUCCESS)) {
      resetForm()
      setNotification({
        title: 'Message submitted',
        description: 'Your message has been sent',
        type: ALERT_CONSTANTS.SUCCESS,
      })
    }
  }

  if (error) {
    navigate(-1)

    return
  }

  return (
    <div className={styles.parent}>
      <ModalPreviewFile previewFile={previewFile} onClose={() => setPreviewFile(null)} />

      <Row>
        <Col lg={19} xs={24} className={styles.parentMessages}>
          {isLoading ? (
            <Skeleton paragraph={{ rows: 0 }} active className={styles.parentSkeletonTitle} />
          ) : (
            <>
              <h1 className={styles.parentTitle}>{data?.subject}</h1>
              <SupportNewMessage
                isAddMessage={isAddMessage}
                toggleIsOpenAddMessage={toggleIsOpenAddMessage}
                isCreatingMessage={isCreatingMessage}
                handleSendMessage={handleSendMessage}
                isClosed={data?.status.name === 'Closed'}
                uploadedFiles={uploadedFiles}
                setUploadedFiles={setUploadedFiles}
              />
            </>
          )}
          <SupportMessageList
            isAddMessage={isAddMessage}
            isFetching={isLoading}
            messageList={data?.messages as any[]}
            handlePreviewFile={handlePreviewFile}
            uploadedFiles={uploadedFiles}
          />
        </Col>
        <Col lg={5} xs={24}>
          <SupportInfo ticket={data} isLoading={isLoading} />
        </Col>
      </Row>
    </div>
  )
}
