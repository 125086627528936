import type { FormikValues } from 'formik'

import type { IUploadedValidationDocument } from 'features/Auth/interfaces/IAuthUploadedDocument'
import type { LICENSE_TYPE } from 'features/Auth/types/authLicenseType'

const getIsConfirmedDocument = (
  documents: IUploadedValidationDocument[],
  type: LICENSE_TYPE,
): number | null => documents && getSpecificDocument(documents, type)?.is_valid

const getSpecificDocument = (
  documents: IUploadedValidationDocument[],
  type: LICENSE_TYPE,
): IUploadedValidationDocument =>
  documents?.find((uploadedDocument: IUploadedValidationDocument): boolean =>
    uploadedDocument.document_type.name.toLowerCase().includes(type),
  )

const getUpdatedIdentity = (values: FormikValues) => {
  let updatedData = { ...values }
  const getUpdatedLicense = (license: string) =>
    (updatedData[license] = {
      ...updatedData[license],
      name: updatedData[license].path.at(0).name,
      path: updatedData[license].path.at(0).response.file.path,
    })

  if (!values.driver_license.path.length) updatedData = { medical_license: values.medical_license }
  if (!values.medical_license.path.length) updatedData = { driver_license: values.driver_license }

  if (updatedData.driver_license) updatedData.driver_license = getUpdatedLicense('driver_license')
  if (updatedData.medical_license)
    updatedData.medical_license = getUpdatedLicense('medical_license')

  updatedData.site_id = values.site
  updatedData.first_name = values.first_name
  updatedData.last_name = values.last_name
  updatedData.phone_number = values.phone_number

  return updatedData
}

export const AuthService = { getIsConfirmedDocument, getSpecificDocument, getUpdatedIdentity }
