import type { FC } from 'react'
import { memo, useMemo } from 'react'
import { Navigate } from 'react-router-dom'

import { PATH_SEGMENT } from 'routes/pathSegments'
import { generateRouter } from 'routes/routes.utils'

import { SupportContent } from 'features/Support/components/SupportContent/SupportContent'
import { SupportTicketDetails } from 'features/Support/components/SupportTicketDetails/SupportTicketDetails'

export const Support: FC = memo(() => {
  const routesConfig = useMemo(
    () => ({
      [PATH_SEGMENT.ROOT]: <SupportContent />,
      [`${PATH_SEGMENT.TICKET_DETAILS}/:ticketId`]: <SupportTicketDetails />,
      [PATH_SEGMENT.OTHER]: <Navigate to={PATH_SEGMENT.HOME} replace />,
    }),
    [],
  )

  return <>{generateRouter(routesConfig)}</>
})
