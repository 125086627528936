import { memo } from 'react'
import { Navigate } from 'react-router-dom'

import { MenuWidget } from './components/MenuWidget/MenuWidget'
import { MenuSelect } from './components/MenuSelect/MenuSelect'

import { Grid, Layout } from 'antd'
import { PATH_SEGMENT } from 'routes/pathSegments'
import { generateRouter } from 'routes/routes.utils'
import { ChangePassword } from './features/ChangePassword/ChangePassword'
import { Deposit } from './features/Deposit/Deposit'
import { Mailbox } from './features/Mailbox/Mailbox'
import { Notifications } from './features/Notifications/Notifications'
import { CreditCards } from './features/Payment/CreditCards'
import { PersonalProfile } from './features/PersonalProfile/PersonalProfile'

import styles from './profile.module.scss'
import { UserWidget } from './components/UserWidget/UserWidget'
import { BillingHistory } from './features/BillingHistory/BillingHistory'
import { PaymentHistory } from './features/PaymentsHistory/PaymentHistory'
import CompletedBookings from './features/CompletedBookings/CompletedBookings'
import { useAuth } from 'app/providers'

const { Sider, Content } = Layout
const { useBreakpoint } = Grid

export const Profile = memo(() => {
  const breakpoint = useBreakpoint()
  const { isStaff } = useAuth()

  const routesConfig = {
    ...(!isStaff
      ? {
          [PATH_SEGMENT.CREDIT_CARDS]: <CreditCards />,
          [PATH_SEGMENT.BILLING_HISTORY]: <BillingHistory />,
          [PATH_SEGMENT.MAILBOX_PROFILE]: <Mailbox />,
          [PATH_SEGMENT.NOTIFICATIONS]: <Notifications />,
          [PATH_SEGMENT.DEPOSIT]: <Deposit />,
          [PATH_SEGMENT.PAYMENTS_HISTORY]: <PaymentHistory />,
        }
      : {}),
    [PATH_SEGMENT.COMPLETED_BOOKINGS]: <CompletedBookings />,
    [PATH_SEGMENT.PERSONAL_PROFILE]: <PersonalProfile />,
    [PATH_SEGMENT.CHANGE_PASSWORD]: <ChangePassword />,
    [PATH_SEGMENT.OTHER]: <Navigate to={PATH_SEGMENT.PERSONAL_PROFILE} replace />,
  }

  const isTabletOrSmaller = !breakpoint.lg

  return (
    <>
      <div className={styles.header}>
        <UserWidget />
      </div>
      <Layout className={styles.layout}>
        {isTabletOrSmaller ? (
          <div className={styles.mobileSelect}>
            <MenuSelect isStaff={isStaff} />
          </div>
        ) : (
          <Sider width={266} className={styles.sider}>
            <MenuWidget isStaff={isStaff} />
          </Sider>
        )}
        <Content className={styles.container}>{generateRouter(routesConfig)}</Content>
      </Layout>
    </>
  )
})
