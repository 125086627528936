import type { FC } from 'react'
import React, { memo, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Select } from 'antd'
import { PATH_SEGMENT } from 'routes/pathSegments'

import type { MenuItemType } from 'antd/lib/menu/interface'

import styles from './menuSelect.module.scss'
import type { MenuSelectProps } from './menuSelect.types'
import { profileMenuConfig } from 'features/Profile/config'

const { Option, OptGroup } = Select

type MenuItem = MenuItemType & {
  children?: MenuItem[]
}

export const MenuSelect: FC<MenuSelectProps> = memo(({ isStaff }) => {
  const navigate = useNavigate()
  const location = useLocation()

  const handleChange = (value: string) => {
    navigate(value)
  }

  const currentPath = useMemo(() => {
    return location.pathname.split('/').at(-1) || PATH_SEGMENT.PERSONAL_PROFILE
  }, [location])

  const renderMenuItem = (item: MenuItem) => {
    if (item.children) {
      return (
        <OptGroup key={item.key} label={item.label}>
          {item.children.map(renderMenuItem)}
        </OptGroup>
      )
    } else {
      return (
        <Option key={item.key} value={item.key?.toString()}>
          <span>{item.label}</span>
        </Option>
      )
    }
  }

  return (
    <Select className={styles.menu} value={currentPath} onChange={handleChange}>
      {profileMenuConfig.getMenus(isStaff).map((item) => renderMenuItem(item as MenuItem))}
    </Select>
  )
})
