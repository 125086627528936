import type { BaseQueryFn } from '@reduxjs/toolkit/query'
import type { AxiosRequestConfig, AxiosResponse } from 'axios'
import axios from 'axios'

import { HTTP_RESPONSE_STATUS } from 'common/constants/httpMethodsConstants'
import { LOCALE_STORAGE_KEYS } from 'common/constants/localeStorageConstants'
import type { IUser } from 'common/interfaces/IUser'
import { PATH_SEGMENT } from 'routes/pathSegments'

export const baseQuery =
  (): BaseQueryFn<{
    url: string
    data?: AxiosRequestConfig['data']
    method: AxiosRequestConfig['method']
    headers?: AxiosRequestConfig['headers']
  }> =>
  async (param) => {
    try {
      const user: IUser | null = window.localStorage.getItem(LOCALE_STORAGE_KEYS.USER)
        ? JSON.parse(window.localStorage.getItem(LOCALE_STORAGE_KEYS.USER))
        : null

      const result: AxiosResponse = await axios({
        ...param,
        baseURL: process.env.REACT_APP_RESIDEN_API_BASE_URL,
        headers: {
          ...param?.headers,
          Authorization: user ? `Bearer ${user.access_token}` : null,
        },
      })
      return { data: result.data }
    } catch (err) {
      if (
        err.response?.status === HTTP_RESPONSE_STATUS.UNAUTHORIZED ||
        err.response?.status === HTTP_RESPONSE_STATUS.CONNECTION_TIMED_OUT
      ) {
        const storageUser = window.localStorage.getItem(LOCALE_STORAGE_KEYS.USER)
        const user: IUser | null = storageUser ? JSON.parse(storageUser) : null

        if (user?.old_access_token) {
          const newUser = {
            ...user,
            access_token: user.old_access_token,
            is_super_doctor: true,
          }
          delete newUser.old_access_token
          window.localStorage.setItem(LOCALE_STORAGE_KEYS.USER, JSON.stringify(newUser))
          window.location.assign(PATH_SEGMENT.ROOT)
        } else {
          window.localStorage.clear()
          window.location.reload()
        }
      }

      const errors = err.response?.data?.['error' as keyof object]
      return {
        error: {
          status: err.response?.status,
          data:
            typeof errors === 'object'
              ? Object.values(errors).flat()
              : [err.response.data?.['message' as keyof object]],
        },
      }
    }
  }
