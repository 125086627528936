import type { FC, JSX } from 'react'

import ThumbnailsSkeleton from 'common/components/ThumbnailsSkeleton/ThumbnailsSkeleton'

import { ShopCard } from 'features/Home/Book/Booking/Shop/ShopCardList/ShopCard/ShopCard'
import type { IInfoShopItem } from 'features/Home/interfaces/IInfoShop'

import styles from './shopCardList.module.scss'
import type { ShopCardListProps } from './shopCardList.types'

export const ShopCardList: FC<ShopCardListProps> = ({
  shopItems,
  isLoading,
  handleSelectShopItem,
}) => {
  if (isLoading)
    return (
      <div className={styles.parent}>
        <ThumbnailsSkeleton
          rows={3}
          align='center'
          maxWidth={266}
          minWidth={266}
          border={false}
          imgHeight={173}
        />
      </div>
    )
  return (
    <div className={styles.parent}>
      {shopItems.map((shopItem: IInfoShopItem, index: number): JSX.Element => {
        return (
          <ShopCard
            key={index}
            shopItem={shopItem}
            handleSelectShopItem={() => handleSelectShopItem(shopItem)}
          />
        )
      })}
    </div>
  )
}
