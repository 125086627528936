import { type JSX } from 'react'
import { shallowEqual } from 'react-redux'

import { Col, Row, Upload } from 'antd'
import ImgCrop from 'antd-img-crop'
import type { FormikErrors, FormikValues } from 'formik'
import { Form, Formik } from 'formik'

import { useAuth, useNotification } from 'app/providers'

import { Button } from 'common/components/Button/Button'
import { FormControl } from 'common/components/FormItems/FormControl/FormControl'
import { Show } from 'common/components/Show/Show'
import { ALERT_CONSTANTS } from 'common/constants/alertConstants'
import {
  BUTTON_CONSTANTS,
  BUTTON_MODIFIER,
  BUTTON_PROPORTION,
  BUTTON_SEVERITY,
} from 'common/constants/buttonConstants'
import { FORM_CONTROL_TYPE } from 'common/constants/formControlConstants'
import { INPUT_TYPES } from 'common/constants/inputConstants'
import { UPLOAD_CONSTANTS } from 'common/constants/uploadConstants'
import { UtilService } from 'common/services/utilService'
import { useFetchCountriesQuery, useFetchStatesQuery } from 'common/store/api/rootApi'

import { USA_COUNTRY_ID } from 'features/SettingsOld/constants/settingsAddCard'
import { SETTINGS_PROFILE_KEYS } from 'features/SettingsOld/constants/settingsProfileKeys'
import { SETTINGS_PROFILE_LABELS } from 'features/SettingsOld/constants/settingsProfileLabels'
import { SETTINGS_PROFILE_DOCTOR_SCHEMA } from 'features/SettingsOld/schemas/settingsProfileDoctorSchema'
import { SETTINGS_PROFILE_STAFF_SCHEMA } from 'features/SettingsOld/schemas/settingsProfileStaffSchema'
import {
  useFetchProfileQuery,
  useUpdateImageMutation,
  useUpdateProfileMutation,
  useUploadImageMutation,
} from 'features/SettingsOld/state/api/settingsApi'
import { WidgetPhoto } from 'features/Widget/components/WidgetPhoto/WidgetPhoto'

import styles from './ProfileForm.module.scss'
import { useApiResponse } from 'common/hooks/useApiResponse'
import { SaveButton } from 'features/Profile/components/SaveButton/SaveButton'

const { clearObjectByEmptyValues, getInitials } = UtilService
const { fileSizeInMB } = UtilService

type Props = {
  onDeleteAvatar: () => void
}

export const ProfileForm = ({ onDeleteAvatar }: Props) => {
  const { setNotification } = useNotification()
  const { isDoctor } = useAuth()
  const { processApiResponse } = useApiResponse()

  const { data: states } = useFetchStatesQuery()
  const { data: countries } = useFetchCountriesQuery()

  const { data, isFetching: isFetchingProfile } = useFetchProfileQuery()
  const [updateProfile, { isLoading: isUpdatingProfile }] = useUpdateProfileMutation()

  const [uploadImage] = useUploadImageMutation()
  const [updateImage] = useUpdateImageMutation()

  const imageUrl = data?.profile?.image?.url

  const handleSubmitProfileForm = async (profileData: FormikValues): Promise<void> => {
    const response = await updateProfile({
      ...clearObjectByEmptyValues(profileData),
    })

    processApiResponse(response, {
      error: 'Update profile error',
      success: 'Update profile success',
    })
  }

  const onUpdateAvatar = async ({ file }: { file: File }): Promise<void> => {
    if (!file.type.includes('image')) {
      setNotification({
        type: ALERT_CONSTANTS.ERROR,
        title: UPLOAD_CONSTANTS.TYPE_ERROR_TITLE,
        description: UPLOAD_CONSTANTS.TYPE_ERROR_DESCRIPTION,
      })
      return
    }
    if (fileSizeInMB(file.size) >= 10) {
      setNotification({
        type: ALERT_CONSTANTS.ERROR,
        title: UPLOAD_CONSTANTS.SIZE_ERROR_TITLE,
        description: UPLOAD_CONSTANTS.SIZE_ERROR_DESCRIPTION,
      })
      return
    }
    const { data } = await uploadImage(file)

    const response = await updateImage(data.file)
    processApiResponse(response, {
      title: UPLOAD_CONSTANTS.SIZE_ERROR_TITLE,
      error: UPLOAD_CONSTANTS.SIZE_ERROR_DESCRIPTION,
      success: 'Update avatar success',
    })
  }

  const initials = getInitials(data?.profile?.first_name, data?.profile?.last_name)
  const isFetching = isFetchingProfile || isUpdatingProfile

  return (
    <div className={styles.parent}>
      <Formik
        enableReinitialize
        validateOnChange={false}
        onSubmit={(values: FormikValues) => handleSubmitProfileForm(values)}
        initialValues={data?.profile || {}}
        validationSchema={
          isDoctor ? SETTINGS_PROFILE_DOCTOR_SCHEMA : SETTINGS_PROFILE_STAFF_SCHEMA
        }>
        {({
          values,
          setErrors,
        }: {
          values: FormikValues
          setErrors: (errors: FormikErrors<FormikValues>) => void
        }): JSX.Element => {
          if (shallowEqual(values, data?.profile)) {
            setErrors({})
          }
          return (
            <Form>
              <div className={styles.parentFormItems}>
                <div>
                  <h2 className={styles.parentFormDescription}>
                    {SETTINGS_PROFILE_LABELS.PROFILE_PHOTO}
                  </h2>
                  <div className={styles.parentFormImageItems}>
                    <WidgetPhoto initials={initials} hasShadow={false} src={imageUrl} />
                    <ImgCrop aspect={1} minZoom={0} maxZoom={5} cropShape='round'>
                      <Upload
                        showUploadList={false}
                        customRequest={onUpdateAvatar as any}
                        accept='image/png, image/jpeg'>
                        <Button
                          modifier={BUTTON_MODIFIER.SECONDARY}
                          proportion={BUTTON_PROPORTION.SMALL}>
                          {imageUrl ? BUTTON_CONSTANTS.CHANGE : BUTTON_CONSTANTS.UPLOAD}
                        </Button>
                      </Upload>
                    </ImgCrop>
                    <Show when={imageUrl}>
                      <Button
                        onClick={onDeleteAvatar}
                        modifier={BUTTON_MODIFIER.DEFAULT}
                        className={styles.parentFormRemoveButton}
                        proportion={BUTTON_PROPORTION.SMALL}
                        severity={BUTTON_SEVERITY.DANGER_OUTLINE}>
                        {BUTTON_CONSTANTS.REMOVE}
                      </Button>
                    </Show>
                  </div>
                </div>
                <div>
                  <h2 className={styles.parentFormDescription}>
                    {SETTINGS_PROFILE_LABELS.USER_DETAILS}
                  </h2>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} md={12} lg={6}>
                      <FormControl
                        required
                        isLoading={isFetching}
                        type={INPUT_TYPES.TEXT}
                        control={FORM_CONTROL_TYPE.INPUT}
                        name={SETTINGS_PROFILE_KEYS.FIRST_NAME}
                        label={SETTINGS_PROFILE_LABELS.FIRST_NAME}
                      />
                    </Col>
                    <Col xs={24} md={12} lg={6}>
                      <FormControl
                        required
                        isLoading={isFetching}
                        type={INPUT_TYPES.TEXT}
                        control={FORM_CONTROL_TYPE.INPUT}
                        name={SETTINGS_PROFILE_KEYS.LAST_NAME}
                        label={SETTINGS_PROFILE_LABELS.LAST_NAME}
                      />
                    </Col>
                    <Col xs={24} md={12} lg={6}>
                      <FormControl
                        required
                        type={INPUT_TYPES.EMAIL}
                        isLoading={isFetching}
                        control={FORM_CONTROL_TYPE.INPUT}
                        name={SETTINGS_PROFILE_KEYS.EMAIL}
                        label={SETTINGS_PROFILE_LABELS.EMAIL}
                      />
                    </Col>
                    <Col xs={24} md={12} lg={6}>
                      <FormControl
                        required
                        type={INPUT_TYPES.TEXT}
                        isLoading={isFetching}
                        control={FORM_CONTROL_TYPE.PHONE}
                        name={SETTINGS_PROFILE_KEYS.PHONE}
                        label={SETTINGS_PROFILE_LABELS.PHONE}
                      />
                    </Col>
                  </Row>
                </div>
                <Show when={isDoctor}>
                  <div>
                    <h2 className={styles.parentFormDescription}>
                      {SETTINGS_PROFILE_LABELS.MAILING_ADDRESS}
                    </h2>
                    <Row gutter={[16, 16]}>
                      <Col xs={24} md={12}>
                        <FormControl
                          type={INPUT_TYPES.TEXT}
                          isLoading={isFetching}
                          control={FORM_CONTROL_TYPE.INPUT}
                          name={SETTINGS_PROFILE_KEYS.MAILING_LINE1}
                          label={SETTINGS_PROFILE_LABELS.MAILING_LINE1}
                        />
                      </Col>
                      <Col xs={24} md={12}>
                        <FormControl
                          type={INPUT_TYPES.TEXT}
                          isLoading={isFetching}
                          control={FORM_CONTROL_TYPE.INPUT}
                          name={SETTINGS_PROFILE_KEYS.MAILING_LINE2}
                          label={SETTINGS_PROFILE_LABELS.MAILING_LINE2}
                        />
                      </Col>
                      <Col xs={24} md={12}>
                        <FormControl
                          options={states || []}
                          type={INPUT_TYPES.TEXT}
                          isLoading={isFetching}
                          control={FORM_CONTROL_TYPE.SELECT}
                          name={SETTINGS_PROFILE_KEYS.MAILING_STATE}
                          label={SETTINGS_PROFILE_LABELS.STATE}
                        />
                      </Col>
                      <Col xs={24} md={6}>
                        <FormControl
                          type={INPUT_TYPES.TEXT}
                          isLoading={isFetching}
                          control={FORM_CONTROL_TYPE.INPUT}
                          name={SETTINGS_PROFILE_KEYS.MAILING_CITY}
                          label={SETTINGS_PROFILE_LABELS.CITY}
                        />
                      </Col>
                      <Col xs={24} md={6}>
                        <FormControl
                          type={INPUT_TYPES.TEXT}
                          isLoading={isFetching}
                          control={FORM_CONTROL_TYPE.INPUT}
                          name={SETTINGS_PROFILE_KEYS.MAILING_ZIPCODE}
                          label={SETTINGS_PROFILE_LABELS.ZIP_CODE}
                        />
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <h2 className={styles.parentFormDescription}>
                      {SETTINGS_PROFILE_LABELS.PROFESSIONAL_DETAILS}
                    </h2>
                    <Row gutter={[16, 16]}>
                      <Col xs={24} md={12} lg={6}>
                        <FormControl
                          required
                          type={INPUT_TYPES.TEXT}
                          isLoading={isFetching}
                          control={FORM_CONTROL_TYPE.INPUT}
                          name={SETTINGS_PROFILE_KEYS.SPECIALITY}
                          label={SETTINGS_PROFILE_LABELS.SPECIALITY}
                        />
                      </Col>
                      <Col xs={24} md={12} lg={6}>
                        <FormControl
                          required
                          type={INPUT_TYPES.TEXT}
                          isLoading={isFetching}
                          control={FORM_CONTROL_TYPE.INPUT}
                          name={SETTINGS_PROFILE_KEYS.MEDICAL_LICENSE}
                          label={SETTINGS_PROFILE_LABELS.MEDICAL_LICENSE_NUMBER}
                        />
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <h2 className={styles.parentFormDescription}>
                      {SETTINGS_PROFILE_LABELS.MEDICAL_LICENSE}
                    </h2>
                    <Row gutter={[16, 16]}>
                      <Col xs={24} md={12} lg={6}>
                        <FormControl
                          required
                          type={INPUT_TYPES.TEXT}
                          isLoading={isFetching}
                          control={FORM_CONTROL_TYPE.INPUT}
                          name={SETTINGS_PROFILE_KEYS.MEDICAL_LICENSE}
                          label={SETTINGS_PROFILE_LABELS.MEDICAL_LICENSE_NUMBER}
                        />
                      </Col>
                      <Col xs={24}>
                        <Row gutter={[16, 16]}>
                          <Col xs={24} md={12} lg={6}>
                            <FormControl
                              options={countries}
                              isLoading={isFetching}
                              type={INPUT_TYPES.TEXT}
                              control={FORM_CONTROL_TYPE.SELECT}
                              name={SETTINGS_PROFILE_KEYS.COUNTRY}
                              label={SETTINGS_PROFILE_LABELS.COUNTRY}
                            />
                          </Col>
                          <Show when={values?.country_id === USA_COUNTRY_ID}>
                            <Col xs={24} md={12} lg={6}>
                              <FormControl
                                options={states || []}
                                type={INPUT_TYPES.TEXT}
                                isLoading={isFetching}
                                control={FORM_CONTROL_TYPE.SELECT}
                                name={SETTINGS_PROFILE_KEYS.STATE}
                                label={SETTINGS_PROFILE_LABELS.STATE}
                              />
                            </Col>
                          </Show>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <h2 className={styles.parentFormDescription}>
                      {SETTINGS_PROFILE_LABELS.DEA_LICENSE}
                    </h2>
                    <Row gutter={[16, 16]}>
                      <Col xs={24} md={12} lg={6}>
                        <FormControl
                          type={INPUT_TYPES.TEXT}
                          isLoading={isFetching}
                          control={FORM_CONTROL_TYPE.INPUT}
                          name={SETTINGS_PROFILE_KEYS.DEA_LICENSE}
                          label={SETTINGS_PROFILE_LABELS.DEA_LICENSE_NUMBER}
                        />
                      </Col>
                    </Row>
                  </div>
                </Show>
              </div>
              <div className={styles.parentFormSubmit}>
                <SaveButton loading={isUpdatingProfile || isFetching} />
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}
