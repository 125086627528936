import { createApi } from '@reduxjs/toolkit/query/react'
import type { BaseQueryFn } from '@reduxjs/toolkit/src/query/baseQueryTypes'
import type { EndpointBuilder } from '@reduxjs/toolkit/src/query/endpointDefinitions'

import { baseQuery } from 'common/store/api/fetchBaseQuery'
import { REDUCERS } from 'common/store/reducerConstants'

export const providerApi = createApi({
  reducerPath: REDUCERS.PROVIDER,
  baseQuery: baseQuery(),
  endpoints: (build: EndpointBuilder<BaseQueryFn, string, string>) => ({
    fetchDictionary: build.query({
      query: () => ({
        url: '/dictionary',
      }),
    }),
    fetchConditions: build.query({
      query: () => ({
        url: '/terms-conditions',
      }),
    }),
  }),
})

export const { useFetchConditionsQuery, useFetchDictionaryQuery }: any = providerApi
