export enum AUTH_SCHEMA_KEYS {
  EMAIL = 'email',
  PASSWORD = 'password',
  CONFIRM_PASSWORD_REG = 'confirm_password',
  RECAPTCHA = 'turnstile_id',
  CONFIRM_PASSWORD = 'confirmPassword',
}

export const INITIAL_NEW_PASSWORD_DATA = {
  password: '',
  confirmPassword: '',
}

export const INITIAL_RESET_PASSWORD_DATA = {
  email: '',
}

export const INITIAL_LOGIN_DATA = {
  email: '',
  password: '',
  turnstile_id: '',
}

export const INITIAL_REGISTER_DATA = {
  email: '',
  password: '',
  recaptcha_id: '',
  confirm_password: '',
}

export const INITIAL_VALIDATE_IDENTITY_DATA = {
  first_name: '',
  last_name: '',
  site: '',
  phone_number: '',
  medical_license: {
    state: '',
    number: '',
    path: [] as [],
  },
  driver_license: {
    state: '',
    number: '',
    path: [] as [],
  },
}

export enum AUTH_VALIDATE_IDENTITY_KEYS {
  FIRST_NAME = 'first_name',
  LAST_NAME = 'last_name',
  SITE_ID = 'site',
  DRIVER_FILE = 'driver_license.path',
  DRIVER_STATE = 'driver_license.state',
  DRIVER_NUMBER = 'driver_license.number',
  MEDICAL_FILE = 'medical_license.path',
  MEDICAL_STATE = 'medical_license.state',
  MEDICAL_NUMBER = 'medical_license.number',
  PHONE_NUMBER = 'phone_number',
}
