import { UtilService } from 'common/services/utilService'

import type { IStaffLease } from 'features/Staff/interfaces/IStaff'

export const INFO_STAFF_TABLE_COLUMNS = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    sorter: (a: IStaffLease, b: IStaffLease) => a.name?.localeCompare(b.name),
  },
  {
    title: 'Phone',
    dataIndex: 'phone_number',
    key: 'phone_number',

    sorter: (a: IStaffLease, b: IStaffLease) => a.phone_number?.localeCompare(b.phone_number),
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    sorter: (a: IStaffLease, b: IStaffLease) => a.email?.localeCompare(b.email),
  },
  {
    title: 'Qualification',
    dataIndex: 'qualification',
    key: 'qualification',
    sorter: (a: IStaffLease, b: IStaffLease) => a.qualification?.localeCompare(b.qualification),
  },
  {
    title: 'Access Code',
    dataIndex: 'access_code',
    key: 'access_code',

    sorter: (a: IStaffLease, b: IStaffLease) => a.access_code - b.access_code,
  },
  {
    title: 'Last Active',
    dataIndex: 'last_active',
    key: 'last_active',
    sorter: (a: IStaffLease, b: IStaffLease) =>
      new Date(a.last_active).valueOf() - new Date(b.last_active).valueOf(),
    render: (last_active: string) => UtilService.formatTableDate(last_active),
  },
  {
    title: 'Last Notify',
    dataIndex: 'last_notify',
    render: (last_notify: string) => UtilService.formatTableDate(last_notify),
    sorter: (a: IStaffLease, b: IStaffLease) =>
      new Date(a.last_notify).valueOf() - new Date(b.last_notify).valueOf(),
  },
]
