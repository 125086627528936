import { type FC, memo, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'

import { Calendar } from 'common/components/Calendar/Calendar'
import { UtilService } from 'common/services/utilService'

import { useGetPromotionCalendarQuery } from 'features/Home/Promotion/state/promotionsApi'
import type { MonthViewProps } from './monthView.types'

dayjs.extend(isBetween)

export const MonthView: FC<MonthViewProps> = memo(
  ({ currentDate, startDate, endDate, handleChangeDate }) => {
    const [selectedMonth, setSelectedMonth] = useState(currentDate.format('YYYY-MM'))

    const { bookingId, siteId } = useParams()

    const { data, isLoading, isFetching } = useGetPromotionCalendarQuery({
      siteId,
      bookingId,
      date: selectedMonth,
    })

    const handleChangeMonth = (_: number, newMonth: string) => {
      const newMonthDayjs = dayjs(newMonth, 'YYYY-MM')
      const startDayjs = dayjs(startDate)
      const endDayjs = dayjs(endDate)

      if (newMonthDayjs.isBetween(startDayjs, endDayjs, 'month', '[]')) {
        setSelectedMonth(newMonth)
      }
    }

    useEffect(() => {
      setSelectedMonth(currentDate.format('YYYY-MM'))
    }, [currentDate])

    const days = UtilService.generateDatesWithValues(startDate, endDate, 1, false)

    const selectedDays = Object.keys(data ?? {}).filter((key) => data[key])

    return (
      <Calendar
        isStrictDisable
        days={days}
        withFakeRerender
        showCurrentDay={false}
        isLoading={isLoading || isFetching}
        handleChangeDate={handleChangeDate}
        handleChangeMonth={handleChangeMonth}
        selectedDays={selectedDays}
        currentDay={currentDate.format('YYYY-MM-DD')}
      />
    )
  },
)
