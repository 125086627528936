import type { FC } from 'react'
import { useEffect } from 'react'

import { Field, useField } from 'formik'

import { AutoComplete } from 'common/components/AutoComplete/AutoComplete'
import { Label } from 'common/components/Label/Label'
import { Show } from 'common/components/Show/Show'
import { FORM_CONTROL_TYPE } from 'common/constants/formControlConstants'
import type { ISelect } from 'common/interfaces/ISelect'

import styles from './formAutocomplete.module.scss'

export const FormAutocomplete: FC<ISelect> = ({
  required,
  name,
  mode,
  value,
  label,
  options,
  onChange,
  ...rest
}) => {
  const [field, meta, helpers] = useField(name)
  useEffect(() => {
    if (value && value !== field.value) helpers.setValue(value)
  }, [value])

  return (
    <div className={styles.parent}>
      <Label id={name} label={label} required={required} />
      <Field
        mode={mode}
        id={name}
        name={name}
        showSearch
        label={label}
        component={AutoComplete}
        className={styles.parentField}
        bordered={false}
        options={options}
        data-cy={`${name}-form-control`}
        placeholder='Type here'
        as={FORM_CONTROL_TYPE.AUTOCOMPLETE}
        value={field?.value?.split('/')?.at(0) || null}
        onChange={(value: any, option: any) => {
          helpers.setValue(value)
          if (onChange) onChange(value, option)
        }}
        onBlur={() => field.value && helpers.setValue(field.value.trim().replace(/\s\s+/g, ' '))}
        {...rest}
      />
      <Show when={meta.error}>
        <p className={styles.parentErrorInform}>{meta.error}</p>
      </Show>
    </div>
  )
}
