import type { FC } from 'react'
import { memo } from 'react'

import { ReactComponent as ContractGuy } from 'assets/icons/contract-guy.svg'

import { INFO_CONSTANTS } from 'features/Home/constants/infoConstants'

import styles from './emptyRoomsList.module.scss'
import type { EmptyRoomsListProps } from './emptyRoomsList.types'

export const EmptyRoomsList: FC<EmptyRoomsListProps> = memo(
  ({ title = INFO_CONSTANTS.NO_ACTIVE_LEASES_TODAY, content }) => {
    return (
      <div className={styles.container}>
        <ContractGuy />
        <h3 className={styles.containerMessage}>{title}</h3>
        {content}
      </div>
    )
  },
)
