/* eslint-disable jsx-a11y/anchor-is-valid */

import type { FC, Key } from 'react'
import { useEffect, useMemo, useState } from 'react'
import { usePrevious } from 'react-use'

import type { TableProps } from 'antd'
import { Form, Select, Table as AntTable, Upload } from 'antd'

import actionAccept from 'assets/icons/action-accept.svg'
import actionDecline from 'assets/icons/action-decline.svg'

import { Button } from 'common/components/Button/Button'
import { EditableCell } from 'common/components/Table/EditableCell'
import { ALT_CONSTANTS } from 'common/constants/altConstants'
import {
  BUTTON_CONSTANTS,
  BUTTON_MODIFIER,
  BUTTON_PROPORTION,
} from 'common/constants/buttonConstants'
import { APP_CONSTANTS } from 'common/constants/constants'
import { TABLE_ACTIONS, TABLE_COLUMN_TYPES } from 'common/constants/tableConstants'
import type { IEditableCell, ITable } from 'common/interfaces/ITable'
import { TableService } from 'common/services/tableService'

import styles from './table.module.scss'
import { formatSorters } from './table.util'

export const Table: FC<ITable> = ({
  columns,
  itemKey,
  dataSource,
  defaultValue,
  handleUpload,
  isLoading,
  actions = [],
  inTableEdit = false,
  haveSelection,
  haveActions = true,
  handleCreateRow,
  handleSendCode = () => undefined,
  handleResetCode = () => undefined,
  handleRemoveRow = () => undefined,
  handleUpdateRow = () => undefined,
  onFieldChange = () => undefined,
  tableParams,
  setTableParams,
  handleDownloadContract = () => undefined,
  handleLoginAs = () => undefined,
  createFieldDisabled,
  customLabels,
}) => {
  const [form] = Form.useForm()
  const [selectedRows] = useState<Key[]>([])
  const [editingKey, setEditingKey] = useState<number | null>(null)
  const [tableData, setTableData] = useState<any>([])
  const [isAdding, setIsAdding] = useState(false)
  const prevDataSource = usePrevious(dataSource)

  useEffect(() => {
    if (JSON.stringify(dataSource) !== JSON.stringify(prevDataSource)) setTableData(dataSource)
  }, [dataSource])

  useEffect(() => {
    if (!tableData?.length && tableParams?.current !== 1) {
      setTableParams &&
        setTableParams({ ...tableParams, current: Number(tableParams?.current) - 1 })
    }
  }, [tableData])

  const isEditing = (record: any) => record[itemKey] === editingKey

  const handleRowSelect = {
    ...selectedRows,
    onChange: () => {},
  }

  const edit = (record: any & { key: Key }) => {
    form.setFieldsValue({ ...TableService.fieldsValue(columns), ...record })
    setEditingKey(record[itemKey])
  }

  const cancel = () => {
    setIsAdding(false)
    setEditingKey(undefined)

    let newTableData = [...(tableData || [])]
    newTableData = newTableData.filter((item) => item[itemKey] !== 0)

    setTableData([...newTableData])
  }

  const save = async (record: any) => {
    try {
      const values = await form.validateFields()

      if (record.code > 0 || record.id > 0) {
        if (isUpdatedRowValues(values, record)) handleUpdateRow(values, record)
      } else {
        const newTableData = [...(tableData || [])]
        newTableData.shift()

        setTableData([...newTableData])
        handleCreateRow(values)
      }

      setIsAdding(false)
      setEditingKey(undefined)
    } catch (errInfo) {
      console.error('Validate Failed:', errInfo)
    }
  }

  const isUpdatedRowValues = (initial: object, updated: object): boolean => {
    for (const startKey in initial) {
      if (
        updated.hasOwnProperty(startKey) &&
        updated[startKey as keyof object] !== initial[startKey as keyof object]
      )
        return true
    }
    return false
  }

  const addNewTableRow = (): void => {
    const newObj = {} as any
    mergedColumns.forEach((column) => {
      if (column.inputType) {
        newObj[column.dataIndex] = undefined
      }
    })

    const newTableData = [...(tableData || [])]
    newTableData.unshift({ ...newObj, [itemKey]: 0 })

    setIsAdding(true)
    setTableData([...newTableData])
    form.resetFields()

    setEditingKey(newTableData[0][itemKey])
  }

  const isDisableAddNewRow: boolean = useMemo(
    (): boolean =>
      Array.isArray(tableData)
        ? tableData?.some(
            (row: any): boolean =>
              row.id === 0 && !!Object.values(row).filter((value: any): boolean => !value).length,
          )
        : true,

    [tableData],
  )

  const tableColumns = [
    ...columns,
    ...(haveActions
      ? [
          {
            width: 85,
            title: 'Actions',
            fixed: 'right',
            render: (item: any, record: any) => {
              const editable = isEditing(record)
              return editable ? (
                <div className={styles.parentRowManager}>
                  <button
                    className='reset-button'
                    onClick={() => {
                      save(record)
                    }}>
                    <img src={actionAccept} alt={ALT_CONSTANTS.ACTION_ICON} />
                  </button>
                  <button className='reset-button' onClick={cancel}>
                    <img src={actionDecline} alt={ALT_CONSTANTS.ACTION_ICON} />
                  </button>
                </div>
              ) : (
                <Select
                  style={{ width: 150 }}
                  value='Select'
                  className='table-select'
                  popupClassName='table-select-content'
                  options={[
                    ...(actions.includes(TABLE_ACTIONS.EDIT)
                      ? [{ value: APP_CONSTANTS.EDIT, label: APP_CONSTANTS.EDIT }]
                      : []),

                    ...(actions.includes(TABLE_ACTIONS.LOGIN_AS)
                      ? [{ value: APP_CONSTANTS.LOGIN_AS, label: APP_CONSTANTS.LOGIN_AS }]
                      : []),
                    ...(actions.includes(TABLE_ACTIONS.SEND_CODE)
                      ? [{ value: APP_CONSTANTS.SEND_CODE, label: APP_CONSTANTS.SEND_CODE }]
                      : []),
                    ...(actions.includes(TABLE_ACTIONS.RESET_CODE)
                      ? [{ value: APP_CONSTANTS.RESET_CODE, label: APP_CONSTANTS.RESET_CODE }]
                      : []),
                    ...(actions.includes(TABLE_ACTIONS.DOWNLOAD_CONTRACT)
                      ? [
                          {
                            value: APP_CONSTANTS.DOWNLOAD_CONTRACT,
                            label: APP_CONSTANTS.DOWNLOAD_CONTRACT,
                          },
                        ]
                      : []),
                    ...(actions.includes(TABLE_ACTIONS.DELETE)
                      ? [
                          {
                            value: APP_CONSTANTS.DELETE,
                            label: customLabels?.[TABLE_ACTIONS.DELETE] || APP_CONSTANTS.DELETE,
                          },
                        ]
                      : []),
                  ]}
                  onChange={(e) => {
                    if (typeof editingKey === 'number' && editingKey === 0) {
                      setTableData(tableData.slice(1))
                      setEditingKey(null)
                    }
                    switch (e) {
                      case APP_CONSTANTS.EDIT:
                        if (inTableEdit) edit(record)
                        else handleUpdateRow(record)
                        break
                      case APP_CONSTANTS.DELETE:
                        handleRemoveRow(record)
                        break
                      case APP_CONSTANTS.SEND_CODE:
                        handleSendCode(item)
                        break
                      case APP_CONSTANTS.RESET_CODE:
                        handleResetCode(item)
                        break
                      case APP_CONSTANTS.LOGIN_AS:
                        handleLoginAs(record)
                        break
                      case APP_CONSTANTS.DOWNLOAD_CONTRACT:
                        handleDownloadContract(record)
                        break
                      default:
                        break
                    }
                  }}
                />
              )
            },
          },
        ]
      : []),
  ]

  const mergedColumns = tableColumns?.map((col: any) => {
    if (!col.editable) {
      return { ...col, sorter: !isAdding ? col.sorter : undefined }
    }

    return {
      ...col,
      sorter: !isAdding ? col.sorter : undefined,
      onCell: (record: any) => ({
        record,
        inputType: col.inputType,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        isValidField: col.isValidField,
        options: col.options || [],
        errorMessage: col.errorMessage,
      }),
    }
  })

  const setFormFieldValue = (field: string, value: any): void => {
    form.setFieldsValue({
      [field]: value,
    })
  }

  const handleTableChange: TableProps<any>['onChange'] = (pagination, _, sorter) => {
    const params = isAdding
      ? { ...pagination, pageSize: Number(pagination.pageSize) - 1 }
      : pagination
    setTableParams && setTableParams({ ...params, sorters: formatSorters(sorter) })

    cancel()
  }

  return (
    <Form form={form} component={false}>
      <AntTable
        tableLayout='auto'
        // sticky
        bordered
        loading={isLoading}
        rowKey={(row) => row[itemKey]}
        components={{
          body: {
            cell: (props: IEditableCell) => {
              const cellIndex = props.dataIndex
              const disabled = createFieldDisabled
                ? createFieldDisabled(cellIndex, form.getFieldsValue())
                : false
              if (
                props.inputType === TABLE_COLUMN_TYPES.CHECKBOX &&
                typeof form.getFieldValue(props.dataIndex) === 'undefined'
              )
                setFormFieldValue(props.dataIndex, false)
              return (
                <EditableCell
                  {...props}
                  disabled={disabled}
                  onFieldChange={onFieldChange}
                  defaultValue={defaultValue}
                  setFormFieldValue={setFormFieldValue}
                />
              )
            },
          },
        }}
        size='small'
        columns={mergedColumns}
        dataSource={tableData}
        className={styles.parent}
        pagination={
          tableParams
            ? {
                ...(tableParams
                  ? isAdding
                    ? { ...tableParams, pageSize: Number(tableParams?.pageSize) + 1 }
                    : tableParams
                  : {}),
                showSizeChanger: true,
                pageSizeOptions: [5, 10, 25, 50, 100],
                itemRender(page, type, element) {
                  if (type === 'prev') {
                    return <a>Prev</a>
                  }
                  if (type === 'next') {
                    return <a>Next</a>
                  }
                  return element
                },
              }
            : false
        }
        {...(tableParams && { onChange: handleTableChange })}
        {...(haveSelection && { rowSelection: handleRowSelect })}
        {...((handleCreateRow || handleUpload) && {
          summary: () => (
            <AntTable.Summary fixed='top'>
              <AntTable.Summary.Row>
                <AntTable.Summary.Cell index={0} colSpan={mergedColumns?.length + 1}>
                  {handleUpload ? (
                    <Upload customRequest={handleUpload as any} multiple showUploadList={false}>
                      <Button
                        modifier={BUTTON_MODIFIER.DEFAULT}
                        proportion={BUTTON_PROPORTION.SMALL}
                        className='reset-button'>
                        {BUTTON_CONSTANTS.ADD_NEW_PLUS}
                      </Button>
                    </Upload>
                  ) : (
                    <Button
                      className='add-button'
                      onClick={addNewTableRow}
                      disabled={isDisableAddNewRow}
                      modifier={BUTTON_MODIFIER.DEFAULT}
                      proportion={BUTTON_PROPORTION.SMALL}>
                      {BUTTON_CONSTANTS.ADD_NEW_PLUS}
                    </Button>
                  )}
                </AntTable.Summary.Cell>
              </AntTable.Summary.Row>
            </AntTable.Summary>
          ),
        })}
      />
    </Form>
  )
}
