import { UtilService } from 'common/services/utilService'

import type {
  IRoomOptionProduct,
  IRoomSelectedDay,
  IRoomSelectedTimeSlot,
  IRoomTimeSlot,
} from 'features/Booking/interfaces/ILeaseInfoRoom'

const { structuredClone } = UtilService

const getSlotsSubsequenceBySlot = (slots: IRoomSelectedTimeSlot[], slot: IRoomSelectedTimeSlot) => {
  const updatedSlots: IRoomSelectedTimeSlot[] = structuredClone<IRoomSelectedTimeSlot[]>([
    ...slots,
    { ...slot, selectedOptions: slot?.selectedOptions || [] },
  ])
  const sortedSlots: IRoomSelectedTimeSlot[] = updatedSlots.sort(
    (a: IRoomSelectedTimeSlot, b: IRoomSelectedTimeSlot) => a.id - b.id,
  )
  const subsequences: IRoomSelectedTimeSlot[][] = getSlotsSubsequences(sortedSlots)

  return subsequences
}

const getSlotSubsequence = (
  subsequences: IRoomSelectedTimeSlot[][],
  slotId: number,
): IRoomSelectedTimeSlot[] => {
  const subsequence: IRoomSelectedTimeSlot[] = subsequences.find(
    (sub: IRoomSelectedTimeSlot[]): boolean =>
      sub.some(
        ({ id }: IRoomSelectedTimeSlot): boolean =>
          id === slotId || id === slotId - 1 || id === slotId + 1,
      ),
  )
  if (!subsequence) return []
  return subsequence
}

const updateRoomOptions = (
  option: IRoomOptionProduct,
  options: IRoomOptionProduct[],
): IRoomOptionProduct[] => {
  let updatedOptions: IRoomOptionProduct[] = structuredClone<IRoomOptionProduct[]>(options)

  const optionIndex: number = options.findIndex(
    ({ id }: IRoomOptionProduct): boolean => id === option.id,
  )

  if (optionIndex === -1) updatedOptions.push(option)
  else updatedOptions.splice(optionIndex, 1)

  return updatedOptions
}

const updateDaySlots = (day: IRoomSelectedDay): IRoomSelectedDay => {
  const { selectedSlots: slots, selectedSlot: slot } = day
  const updatedSlots: IRoomSelectedTimeSlot[] = structuredClone<IRoomSelectedTimeSlot[]>(slots)

  const slotIndex: number = slots.findIndex(
    ({ id }: IRoomSelectedTimeSlot): boolean => id === slot.id,
  )
  if (slotIndex === -1) updatedSlots.push(slot)
  else updatedSlots.splice(slotIndex, 1, slot)

  updatedSlots.sort((a: IRoomSelectedTimeSlot, b: IRoomSelectedTimeSlot) => a.id - b.id)

  return {
    ...day,
    selectedSlots: updatedSlots,
    selectedSlot: null,
  }
}

const filterOptionsByTypeService = (options: IRoomOptionProduct[]): IRoomOptionProduct[] =>
  options.filter(({ type }: IRoomOptionProduct): boolean => type === 'service')

const getSlotsSubsequences = (slots: IRoomSelectedTimeSlot[]) => {
  let subsequences: IRoomSelectedTimeSlot[][] = []

  let uniqueSlots: IRoomSelectedTimeSlot[] = structuredClone<IRoomSelectedTimeSlot[]>(slots)
  uniqueSlots = [
    ...new Map(uniqueSlots.map((item: IRoomSelectedTimeSlot) => [item.id, item])).values(),
  ]

  uniqueSlots.forEach((slot: IRoomSelectedTimeSlot): void => {
    if (!!subsequences.length) {
      const lastSubsequence: IRoomSelectedTimeSlot[] = subsequences.at(-1)
      const lastSlotId: number = lastSubsequence.at(-1).id
      if (slot.id - lastSlotId === 1 || slot.id - lastSlotId === -1) {
        lastSubsequence.push(slot)
        subsequences.splice(-1, 1, lastSubsequence)
      } else subsequences.push([slot])
    } else subsequences.push([slot])
  })

  return subsequences
}

const updateServicesVisibility = (
  services: IRoomOptionProduct[],
  servicesIds: number[],
): IRoomOptionProduct[] => {
  const updatedOptions: IRoomOptionProduct[] = services.map(
    (service: IRoomOptionProduct): IRoomOptionProduct => ({
      ...service,
      is_checked: servicesIds.includes(service.id) ? 1 : 0,
      is_visible: servicesIds.includes(service.id) ? 0 : 1,
    }),
  )
  return updatedOptions
}

const filterOptionsByServices = (
  options: IRoomOptionProduct[],
  servicesMap: Map<number, number>,
): IRoomOptionProduct[] => {
  const updatedOptions: IRoomOptionProduct[] = options.filter(
    (option: IRoomOptionProduct): boolean => {
      const serviceCount: number = servicesMap.get(option.id) || 0
      return serviceCount <= 1
    },
  )
  return updatedOptions
}

const updateSubsequenceSlot = (
  slot: IRoomSelectedTimeSlot,
  lastSlot: IRoomSelectedTimeSlot,
  servicesMap: Map<number, number>,
): IRoomSelectedTimeSlot => {
  if (slot.id < lastSlot.id) {
    const servicesIds: number[] = lastSlot.selectedOptions.map(
      ({ id }: IRoomOptionProduct): number => id,
    )

    const updatedServices: IRoomOptionProduct[] = updateServicesVisibility(
      slot.options.services,
      servicesIds,
    )

    return {
      ...slot,
      options: { ...slot.options, services: updatedServices },
      selectedOptions: filterOptionsByServices(slot.selectedOptions, servicesMap),
    }
  }
  return {
    ...lastSlot,
    selectedOptions: filterOptionsByServices(lastSlot.selectedOptions, servicesMap),
  }
}

const updateSubsequenceVisibility = (
  subsequence: IRoomSelectedTimeSlot[],
  servicesMap: Map<number, number>,
  lastSlot: IRoomSelectedTimeSlot,
): IRoomSelectedTimeSlot[] => {
  const updatedSubsequence: IRoomSelectedTimeSlot[] = subsequence.map(
    (slot: IRoomSelectedTimeSlot): IRoomSelectedTimeSlot => {
      const slotServices: IRoomOptionProduct[] = filterOptionsByTypeService(slot.selectedOptions)
      slotServices.forEach((option: IRoomOptionProduct): void => {
        if (servicesMap.has(option.id)) servicesMap.set(option.id, servicesMap.get(option.id) + 1)
        else servicesMap.set(option.id, 1)
      })

      return updateSubsequenceSlot(slot, lastSlot, servicesMap)
    },
  )

  return updatedSubsequence
}

const updateRoomServicePlacement = (day: IRoomSelectedDay): IRoomSelectedDay => {
  const { selectedSlots: slots } = day

  const copySlots: IRoomSelectedTimeSlot[] = structuredClone<IRoomSelectedTimeSlot[]>(slots)

  let subsequences: IRoomSelectedTimeSlot[][] = getSlotsSubsequences(copySlots)
  subsequences = subsequences.map((subsequence: IRoomSelectedTimeSlot[]) => {
    const servicesMap: Map<number, number> = new Map()
    const lastSlot: IRoomSelectedTimeSlot = structuredClone<IRoomSelectedTimeSlot>(
      subsequence.at(-1),
    )

    return updateSubsequenceVisibility(subsequence, servicesMap, lastSlot)
  })

  return { ...day, selectedSlots: subsequences.flat() }
}

const updateAvailabilitySlotServices = (day: IRoomSelectedDay, slot: IRoomTimeSlot) => {
  const { selectedSlots: slots } = day
  const updatedSlot: IRoomTimeSlot = structuredClone<IRoomTimeSlot>(slot)
  const subsequences: IRoomSelectedTimeSlot[][] = getSlotsSubsequenceBySlot(
    slots,
    slot as IRoomSelectedTimeSlot,
  )

  let slotSubsequence: IRoomSelectedTimeSlot[] = getSlotSubsequence(subsequences, slot.id)

  if (!!slotSubsequence.length) {
    const subsequenceServiceIds: number[] = slotSubsequence
      .map(({ selectedOptions }: IRoomSelectedTimeSlot): number[] =>
        filterOptionsByTypeService(selectedOptions).map(({ id }: IRoomOptionProduct) => id),
      )
      .flat()
    updatedSlot.options.services = updatedSlot.options.services.map(
      (service: IRoomOptionProduct): IRoomOptionProduct => {
        if (subsequenceServiceIds.includes(service.id))
          return { ...service, is_visible: 0, is_checked: 1 }
        return service
      },
    )
  }
  return updatedSlot
}

export const LeaseBackToBackService = {
  updateDaySlots,
  updateRoomOptions,
  updateRoomServicePlacement,
  updateAvailabilitySlotServices,
}
