import type { FC, JSX } from 'react'
import { useCallback } from 'react'

import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import type { ISiteRoom } from 'common/interfaces/ISiteRoom'
import { UtilService } from 'common/services/utilService'

import { CartCollapse } from 'features/Cart/components/CartCollapse/CartCollapse'
import { CartItem } from 'features/Cart/components/CartItem/CartItem'
import { CART_CONSTANTS } from 'features/Cart/constants/cartConstants'
import type {
  IOptionShopCartItem,
  IOptionShopCartSlotExtension,
  ISlotExtensionRoom,
} from 'features/Option'
import { OptionSlotExtensionService } from 'features/Option'
import { useShopCartManager } from 'features/Option/hooks/useShopCartManager'
import { UPDATE_OPTION_SHOP_ITEMS } from 'features/Option/state/slices/optionSlice'

import styles from './cartShopExtension.module.scss'

const { numberToDollar } = UtilService

const { removeRoomExtension } = OptionSlotExtensionService

interface IProps {
  bookingId: string
  handleEdit: (option: ISiteRoom) => void
  slotExtension: IOptionShopCartSlotExtension | null
}

export const CartShopExtension: FC<IProps> = ({ handleEdit, slotExtension, bookingId }) => {
  const dispatch = useAppDispatch()
  const { updateShopCartContent } = useShopCartManager()

  const { optionShopItems } = useAppSelector((state) => state.optionReducer)

  const getExtensionDetails = (name: string): { name: string; slot: string } => {
    const wordList: string[] = name.split('|')
    const slot: string = wordList.at(1).split('$').at(0)
    return { name: wordList.at(0), slot }
  }

  const handleRemoveSlot = useCallback(
    (roomCode: number): void => {
      const updatedShopItems: IOptionShopCartItem[] = removeRoomExtension(
        bookingId,
        roomCode,
        optionShopItems,
      )
      dispatch(UPDATE_OPTION_SHOP_ITEMS(updatedShopItems))

      updateShopCartContent(updatedShopItems)
    },
    [optionShopItems],
  )

  if (!slotExtension) return null

  return (
    <>
      <CartItem
        price={slotExtension.amount}
        key={slotExtension.option.id}
        name={slotExtension.option.name}
        img={slotExtension.option.images.at(0)}
        handleEdit={() => handleEdit(slotExtension.option)}>
        {slotExtension.rooms.map((room: ISlotExtensionRoom, index: number): JSX.Element => {
          const { name, slot } = getExtensionDetails(room.name)
          return (
            <CartCollapse
              key={index}
              title={name}
              error={room?.error_message}
              additionalActiveTitle={slot}
              handleRemoveSlot={() => handleRemoveSlot(room.code)}>
              <div className={styles.parent}>
                {CART_CONSTANTS.QUANTITY} 1 x {numberToDollar(room.price)}
                <span>{numberToDollar(room.price)}</span>
              </div>
            </CartCollapse>
          )
        })}
      </CartItem>
      <hr />
    </>
  )
}
