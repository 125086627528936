import addPatientIcon from 'assets/icons/add-patient.svg'

import { ALT_CONSTANTS } from 'common/constants/altConstants'

import styles from './addWalkInPatientButton.module.scss'
import type { FC } from 'react'
import { memo } from 'react'
import type { AddWalkInPatientButtonProps } from './addWalkInPatientButton.types'
import { Show } from 'common/components/Show/Show'

export const AddWalkInPatientButton: FC<AddWalkInPatientButtonProps> = memo(
  ({ label, isDisabled, handleClick }) => {
    return (
      <button
        data-cy='add-patient-btn'
        className={styles.newPatient}
        onClick={handleClick}
        disabled={isDisabled}>
        <img src={addPatientIcon} alt={ALT_CONSTANTS.ACTION_ICON} />
        <Show when={label}>
          <span className={styles.newPatientLabel}>{label}</span>
        </Show>
      </button>
    )
  },
)
