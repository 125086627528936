import type { FC, JSX } from 'react'

import { RoomThumbnail } from 'common/components/Thumbnail/RoomThumbnail/RoomThumbnail'
import ThumbnailsSkeleton from 'common/components/ThumbnailsSkeleton/ThumbnailsSkeleton'
import type { ISiteRoom } from 'common/interfaces/ISiteRoom'

import styles from './roomThumbnails.module.scss'

interface IProps {
  rooms: ISiteRoom[]
  disabled?: boolean
  isLoading?: boolean
  isFetching: boolean
  skeletonItems?: number
  handleClick?: (room: ISiteRoom) => void
  isLoadingRooms?: { [key: string]: boolean }
}

export const RoomThumbnails: FC<IProps> = ({
  rooms = [],
  isFetching,
  handleClick,
  isLoadingRooms = {},
  disabled = false,
  skeletonItems = 1,
}) => {
  if (isFetching)
    return (
      <div className={styles.parent}>
        <ThumbnailsSkeleton imgHeight={218} rows={4} total={skeletonItems} />
      </div>
    )

  return (
    <div className={styles.parent}>
      {rooms?.map(
        (room: ISiteRoom): JSX.Element => (
          <RoomThumbnail
            key={room.id}
            roomInfo={room}
            disabled={disabled}
            isLoading={isLoadingRooms[room.id]}
            handleClick={handleClick}
          />
        ),
      )}
    </div>
  )
}
