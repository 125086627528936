import type { FC } from 'react'
import { memo, useCallback, useEffect, useMemo } from 'react'
import { TabPanel } from 'common/components/TabPanel/TabPanel'
import { SlotList } from 'features/Home/Book/Booking/BookingDetails/BookingDays/SlotList/SlotList'
import { LongTermList } from 'features/Home/Book/Booking/BookingDetails/BookingDays/LongTermList/LongTermList'
import type { BookingSlotsProps } from './bookingDays.types'
import { compareAsc, format, parseISO } from 'date-fns'
import type { ITriggerRequest } from 'common/interfaces/IRequestResponse'
import type { IInfoDetails } from 'features/Home/interfaces/IInfo'
import { useLazyFetchLeaseSlotsQuery } from 'features/Home/Book/state/api/bookApi'
import { DateService } from 'common/services/dateService'
import { useAppSelector } from 'common/hooks/redux'
import { useNavigate, useParams } from 'react-router-dom'
import { UtilService } from 'common/services/utilService'
import { PathUtils } from 'routes/routes.utils'

export const BookingDays: FC<BookingSlotsProps> = memo(({ booking, isFetchingBooking }) => {
  const navigate = useNavigate()
  const { bookingId, siteId, day } = useParams()

  const { timeZone } = useAppSelector((state) => state.bookReducer)

  const [
    fetchInfoDetails,
    { data: slots, isFetching: isFetchingSlots },
  ]: ITriggerRequest<IInfoDetails> = useLazyFetchLeaseSlotsQuery()

  const isBookingActive = useMemo(() => booking?.status === 'Active', [booking])

  const bookingDays = useMemo(() => {
    if (!booking) {
      return []
    }

    return UtilService.optionsToNavigationTabs(
      [...(booking.lease_days || [])].sort((a, b) =>
        compareAsc(parseISO(a.code as string), parseISO(b.code as string)),
      ),
    )
  }, [booking])

  const fetchDaySlots = useCallback(
    (date: string): void => {
      fetchInfoDetails({ bookingId, siteId, date })
    },
    [bookingId, siteId, fetchInfoDetails],
  )

  const defaultActiveCurrentDay = useMemo(() => {
    const currentDay = format(DateService.getNowToServerZone(timeZone), 'yyyy-MM-dd')
    return bookingDays.find((day) => day.path >= currentDay)?.path || bookingDays[0]?.path
  }, [bookingDays, timeZone])

  const slotsList = useMemo(() => {
    return (
      <>
        <SlotList
          slots={slots?.time_slots}
          isLeaseActive={isBookingActive}
          isFetching={isFetchingSlots || isFetchingBooking}
        />
        <LongTermList
          options={slots?.long_term_options}
          isFetching={isFetchingSlots || isFetchingBooking}
        />
      </>
    )
  }, [slots, isBookingActive, isFetchingSlots, isFetchingBooking])

  useEffect(() => {
    if (!bookingDays.length) return

    if (!day) {
      return navigate(defaultActiveCurrentDay)
    }
    const dayExists = bookingDays.some((bookingDay) => bookingDay.path === day)
    if (!dayExists) {
      navigate(`${PathUtils.getBookingDetails(siteId, bookingId)}/${defaultActiveCurrentDay}`)
    }
  }, [bookingDays, day, defaultActiveCurrentDay, navigate])

  useEffect(() => {
    if (!day) return
    fetchDaySlots(day)
  }, [day, fetchDaySlots])

  return (
    <>
      <TabPanel polkaDotCurrentDay tabs={bookingDays} onChangeCallback={fetchDaySlots} />
      {slotsList}
    </>
  )
})
