import type { UploadFile } from 'antd'

import uploadIcon from 'assets/icons/upload.svg'

import { FORM_CONTROL_TYPE } from 'common/constants/formControlConstants'
import { INPUT_TYPES } from 'common/constants/inputConstants'
import type { IFormControl } from 'common/interfaces/IFormItem'

export enum SUPPORT_FORM_KEYS {
  CATEGORY = 'category',
  SITE = 'site',
  SUBJECT = 'subject',
  MESSAGE = 'message',
  IMAGES = 'attachments',
}

export const NEW_TICKET_FORM_ITEMS: IFormControl[] = [
  {
    type: INPUT_TYPES.TEXT,
    control: FORM_CONTROL_TYPE.RADIO,
    name: SUPPORT_FORM_KEYS.CATEGORY,
    options: [],
  },
  {
    type: INPUT_TYPES.TEXT,
    control: FORM_CONTROL_TYPE.INPUT,
    name: SUPPORT_FORM_KEYS.SUBJECT,
    label: SUPPORT_FORM_KEYS.SUBJECT,
  },
  {
    control: FORM_CONTROL_TYPE.TEXTAREA,
    name: SUPPORT_FORM_KEYS.MESSAGE,
    label: SUPPORT_FORM_KEYS.MESSAGE,
  },
  {
    additional: uploadIcon,
    control: FORM_CONTROL_TYPE.UPLOAD,
    name: SUPPORT_FORM_KEYS.IMAGES,
    maxCount: 10,
    label: 'Attach images',
  },
]

export const INITIAL_TICKET_DATA = {
  site: '',
  category: '',
  subject: '',
  message: '',
  attachments: [] as UploadFile[],
}
