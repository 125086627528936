import { memo, useRef } from 'react'
import { useToggle } from 'react-use'
import type { FormikProps } from 'formik'
import { Formik, Form } from 'formik'
import { Col, Row } from 'antd'

import { FormControl } from 'common/components/FormItems/FormControl/FormControl'
import { FORM_CONTROL_TYPE } from 'common/constants/formControlConstants'
import { INPUT_TYPES } from 'common/constants/inputConstants'
import { SETTINGS_PROFILE_KEYS } from 'features/SettingsOld/constants/settingsProfileKeys'
import { SETTINGS_PROFILE_LABELS } from 'features/SettingsOld/constants/settingsProfileLabels'
import { SaveButton } from 'features/Profile/components/SaveButton/SaveButton'
import { changePasswordSchema } from './password.schema'

import styles from './ChangePassword.module.scss'
import { useUpdatePasswordMutation } from 'features/SettingsOld/state/api/settingsApi'
import type { Credentials } from 'features/SettingsOld/interfaces/ISettings'
import { useApiResponse } from 'common/hooks/useApiResponse'

export const ChangePassword = memo(() => {
  const [readOnlyInput, toggleReadOnlyInput] = useToggle(true)
  const { processApiResponse } = useApiResponse()

  const formRef = useRef<FormikProps<Credentials>>(null)

  const [updatePassword] = useUpdatePasswordMutation()

  const handleSubmitForm = async (values: Credentials) => {
    const response = await updatePassword(values)
    processApiResponse(response, {
      error: 'Update password error',
      success: 'Update password success',
      successCallback: () => {
        formRef.current?.resetForm()
      },
    })
  }

  return (
    <div className={styles.changePasswordContainer}>
      <h2 className={styles.formTitle}>{SETTINGS_PROFILE_LABELS.CHANGE_PASSWORD}</h2>
      <Formik
        innerRef={formRef}
        initialValues={{
          [SETTINGS_PROFILE_KEYS.CURRENT_PASSWORD]: '',
          [SETTINGS_PROFILE_KEYS.NEW_PASSWORD]: '',
          [SETTINGS_PROFILE_KEYS.NEW_CONFIRM_PASSWORD]: '',
        }}
        validationSchema={changePasswordSchema}
        onSubmit={handleSubmitForm}>
        {({ isSubmitting, dirty, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Row gutter={[32, 16]}>
              <Col xs={24} md={12} lg={6}>
                <FormControl
                  readOnly={readOnlyInput}
                  onFocus={() => toggleReadOnlyInput(false)}
                  onBlur={() => toggleReadOnlyInput(true)}
                  type={INPUT_TYPES.PASSWORD}
                  control={FORM_CONTROL_TYPE.INPUT}
                  name={SETTINGS_PROFILE_KEYS.CURRENT_PASSWORD}
                  label={SETTINGS_PROFILE_LABELS.CURRENT_PASSWORD}
                />
              </Col>
              <Col xs={24}>
                <Row gutter={[32, 16]}>
                  <Col xs={24} md={12} lg={6}>
                    <FormControl
                      readOnly={readOnlyInput}
                      onFocus={() => toggleReadOnlyInput(false)}
                      onBlur={() => toggleReadOnlyInput(true)}
                      type={INPUT_TYPES.PASSWORD}
                      control={FORM_CONTROL_TYPE.INPUT}
                      name={SETTINGS_PROFILE_KEYS.NEW_PASSWORD}
                      label={SETTINGS_PROFILE_LABELS.NEW_PASSWORD}
                    />
                  </Col>
                  <Col xs={24} md={12} lg={6}>
                    <FormControl
                      readOnly={readOnlyInput}
                      onFocus={() => toggleReadOnlyInput(false)}
                      onBlur={() => toggleReadOnlyInput(true)}
                      type={INPUT_TYPES.PASSWORD}
                      control={FORM_CONTROL_TYPE.INPUT}
                      name={SETTINGS_PROFILE_KEYS.NEW_CONFIRM_PASSWORD}
                      label={SETTINGS_PROFILE_LABELS.CONFIRM_PASSWORD}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <SaveButton disabled={!dirty} className={styles.formSubmit} loading={isSubmitting} />
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  )
})
