import './promotionContainer.scss'
import type { FC } from 'react'
import { memo, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { useNotification } from 'app/providers'

import { Show } from 'common/components/Show/Show'
import { ALERT_CONSTANTS } from 'common/constants/alertConstants'
import { RESPONSE_PROPERTY_CONSTANTS } from 'common/constants/reponsePropertyConstants'
import type { IRequestResponse, ITriggerRequest } from 'common/interfaces/IRequestResponse'

import { GoodToKnow } from 'features/Home/Book/Booking/BookingDetails/GoodToKnow/GoodToKnow'
import { ScreenList } from 'features/Home/Book/Booking/BookingDetails/ScreenList/ScreenList'
import type { BookingType } from 'features/Home/interfaces/IInfo'
import {
  useFetchLeaseInfoQuery,
  useRemoveLeaseMediaMutation,
  useUpdateLeaseMediaMutation,
  useUpdateLeaseMusicMutation,
} from 'features/Home/Book/state/api/bookApi'
import type { PromotionContainerProps } from './promotionContainer.types'
import { PromotionHeader } from 'features/Home/Promotion/PromotionDetails/PromotionHeader/PromotionHeader'

export const PromotionContainer: FC<PromotionContainerProps> = memo(
  ({
    displayCol,
    promotion,
    isLoading,
    children,
    showMedia,
    showAdditionInfo,
    isAdditionInfoFull,
  }) => {
    const { bookingId, siteId } = useParams()
    const { setNotification } = useNotification()

    const { data: booking, isFetching: isFetchingLeaseInfo }: IRequestResponse<BookingType> =
      useFetchLeaseInfoQuery({ bookingId, siteId })

    const [updateLeaseMedia, { error: errorUpdateMedia }]: ITriggerRequest =
      useUpdateLeaseMediaMutation()

    const [removeLeaseMedia, { isLoading: isRemovingMedia }]: ITriggerRequest =
      useRemoveLeaseMediaMutation()

    const [updateLeaseMusic]: ITriggerRequest = useUpdateLeaseMusicMutation()

    const isActiveLease = useMemo(() => booking?.status === 'Active', [booking?.status])

    const uploadError = useMemo(() => {
      if (!errorUpdateMedia) {
        return
      }

      return (errorUpdateMedia as any).data?.[0]
    }, [errorUpdateMedia])

    const handleAddMedia = (screen: number, file: File): void => {
      updateLeaseMedia({
        screen,
        file,
        bookingId,
        siteId,
      })
    }

    const handleRemoveMedia = (mediaId: string): void => {
      removeLeaseMedia({
        mediaId,
        bookingId,
        siteId,
      })
    }

    const handleUpdateAmbientMusic = async (ambient_music_id: number): Promise<void> => {
      const response = await updateLeaseMusic({ ambient_music_id, bookingId, siteId })
      if (response.hasOwnProperty(RESPONSE_PROPERTY_CONSTANTS.SUCCESS)) {
        setNotification({
          title: 'Ambient music updated',
          description: response.data.message,
          type: ALERT_CONSTANTS.SUCCESS,
        })
      }
    }

    useEffect(() => {
      if (uploadError) {
        setNotification({
          type: ALERT_CONSTANTS.ERROR,
          title: 'Error',
          description: uploadError,
        })
      }
    }, [uploadError])

    return (
      <div className='promotion-details-container' data-col={displayCol}>
        <div className='wrapper'>
          <PromotionHeader isLoading={isLoading} promotion={promotion} />

          {children}
        </div>

        <Show when={showAdditionInfo}>
          <GoodToKnow
            fullWidth={isAdditionInfoFull}
            isFetching={isLoading}
            isBookingActive={false}
            handleUpdateAmbientMusic={handleUpdateAmbientMusic}
            booking={{
              ...promotion?.good_to_know,
              ambient_music: booking?.good_to_know?.ambient_music,
              start_date: promotion?.start_date,
              end_date: promotion?.end_date,
              isPromotion: promotion?.is_promotion,
            }}
          />
        </Show>
        <Show when={showMedia}>
          <ScreenList
            booking={booking}
            isDisabled={!isActiveLease}
            handleAddMedia={handleAddMedia}
            isFetching={isFetchingLeaseInfo}
            isRemovingMedia={isRemovingMedia}
            handleRemoveMedia={handleRemoveMedia}
          />
        </Show>
      </div>
    )
  },
)
