import type { FC } from 'react'
import { NavLink, useParams } from 'react-router-dom'

import { useAuth } from 'app/providers'

import { Show } from 'common/components/Show/Show'

import { PAYMENT_CONSTANTS } from 'features/Payment/constants/paymentConstants'

import styles from './paymentSettingsNavigate.module.scss'
import { PATH_SEGMENT } from 'routes/pathSegments'

interface IProps {
  description: string
}

export const PaymentSettingsNavigate: FC<IProps> = ({ description }) => {
  const { siteId } = useParams()
  const { isDoctor } = useAuth()
  return (
    <Show when={isDoctor}>
      <p className={styles.parent}>
        {description}
        <NavLink
          className='navigation-link'
          to={`/${siteId}/${PATH_SEGMENT.PROFILE}/${PATH_SEGMENT.CREDIT_CARDS}`}>
          {PAYMENT_CONSTANTS.PAYMENT}
        </NavLink>
        {PAYMENT_CONSTANTS.PAGE}
      </p>
    </Show>
  )
}
