import type { FC } from 'react'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import { Select } from 'common/components/Select/Select'

import { ReactComponent as NavigationIcon } from 'assets/icons/navigation-arrow.svg'

import { Show } from 'common/components/Show/Show'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import type { IOption } from 'common/interfaces/IOption'
import type { IRequestResponse } from 'common/interfaces/IRequestResponse'
import { DateService } from 'common/services/dateService'
import { UtilService } from 'common/services/utilService'
import {
  INFO_CONSTANTS,
  PATIENT_SCHEDULE_SCROLL_HEIGHT_OFFSET,
} from 'features/Home/constants/infoConstants'
import { useInfoManager } from 'features/Home/hooks/useInfoManager'
import { useInfoPusher } from 'features/Home/hooks/useInfoPusher'
import type { IPatientRoom } from 'features/Home/interfaces/IInfoPatient'
import type { AppointmentDate, IPatientSchedule } from 'features/Home/interfaces/IInfoSchedule'
import { useFetchPatientActivityDaysQuery } from 'features/Home/Book/state/api/bookApi'
import { CLEANUP_INFO_STATE } from 'features/Home/Book/state/slice/bookSlice'

import styles from './patientSchedule.module.scss'
import type { PatientScheduleProps } from './patientSchedule.types'
import { PatientScheduleSkeleton } from './PatientScheduleSkeleton/PatientScheduleSkeleton'
import { EmptyRoomsList } from './EmptyRoomsList/EmptyRoomsList'
import { Calendar } from './Calendar/Calendar'
import { AddEditPatient } from './AddEditPatient/AddEditPatient'
import { DocumentResourceHeader } from './DocumentResourceHeader/DocumentResourceHeader'
import { PATH_SEGMENT } from 'routes/pathSegments'
import { Skeleton } from 'antd'
import { AddWalkInPatientButton } from './AddWalkInPatientButton/AddWalkInPatientButton'
import { SELECT_CONSTANTS } from 'common/constants/selectConstants'
import { PATIENT_ACTIVITY_INTERVAL } from 'features/Home/constants/infoActivityTime'
export const PatientSchedule: FC<PatientScheduleProps> = memo(() => {
  const [addPatient, setAddPatient] = useState<boolean>(false)

  const [editedPatient, setEditedPatient] = useState<IPatientSchedule | null>(null)
  const [prePopulateSchedule, setPrePopulateSchedule] = useState<number | null>(null)
  const [hasFinishedFirstLoad, setHasFinishedFirstLoad] = useState<boolean>(false)
  const [customAppointmentData, setCustomAppointmentData] = useState<{
    appointment: AppointmentDate
    room: string
  } | null>(null)

  useInfoPusher()
  const location = useLocation()
  const dispatch = useAppDispatch()

  const { bookingId, siteId, day } = useParams()
  const { fetchInfoData } = useInfoManager(day)

  const { rooms, schedules, isFetching, timeZone, dayInterval } = useAppSelector(
    (state) => state.bookReducer,
  )

  const isPromotion = useMemo(
    () => location.pathname.includes(PATH_SEGMENT.PROMOTION),
    [location.pathname],
  )

  const isCurrentDaySelected = useMemo(() => {
    if (!day) {
      return
    }

    return DateService.getIsValidAndCurrentDay(day, timeZone)
  }, [day, timeZone])

  const isTheSelectedDayBeforeCurrentDay = useMemo(() => {
    if (!day) {
      return
    }

    return DateService.getIsDateBeforeCurrentDate(day, timeZone)
  }, [day, timeZone])

  const isSelectedDayAfterCurrentDay = useMemo(
    () => !isCurrentDaySelected && !isTheSelectedDayBeforeCurrentDay,
    [day, timeZone],
  )

  const {
    data: leaseDays,
    isFetching: isFetchingLeaseDays,
    isSuccess: isSuccessFetchDays,
  }: IRequestResponse<IOption[]> = useFetchPatientActivityDaysQuery({
    bookingId: bookingId,
    siteId: siteId,
  })

  const emptyPromotion = useMemo(
    () => isPromotion && isSuccessFetchDays && leaseDays.length === 0,
    [isPromotion, isSuccessFetchDays, leaseDays],
  )

  const adjustedTabs = useMemo(() => UtilService.optionsToNavigationTabs(leaseDays), [leaseDays])

  const roomsHeader = useMemo(
    () =>
      rooms?.map((room: IPatientRoom) => ({
        resourceId: `${room.label}/${room.value}`,
        resourceTitle: (
          <DocumentResourceHeader room={room} hasRoomStatus={false} timeZone={timeZone} />
        ),
      })),
    [rooms],
  )

  const handleDisplayEventDetails = (data: any): void => {
    const clickedPatient = schedules.find((patient: IPatientSchedule) => patient.id === data.id)
    setEditedPatient(clickedPatient)
  }

  const handleCloseModal = () => {
    setAddPatient(false)
    setEditedPatient(null)
    setCustomAppointmentData(null)
  }

  const createScheduleWithCustomAppointment = useCallback(
    (start: Date, end: Date, resourceId: string): void => {
      setCustomAppointmentData({ appointment: [start, end], room: resourceId })
      setAddPatient(true)
    },
    [],
  )

  useEffect(() => {
    fetchInfoData().then(() => {
      setHasFinishedFirstLoad(true)
    })

    return () => {
      dispatch(CLEANUP_INFO_STATE())
    }
  }, [day, leaseDays])

  useEffect(() => {
    const handleScroll = () => {
      const headerAll = document.querySelectorAll('.rbc-time-header')
      if (window.scrollY > PATIENT_SCHEDULE_SCROLL_HEIGHT_OFFSET && headerAll.length > 0) {
        Array.from(headerAll).map((header) => header.classList.add(styles.parentSticky))
      } else {
        Array.from(headerAll).map((header) => header.classList.remove(styles.parentSticky))
      }
    }
    document.addEventListener('scroll', handleScroll)

    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const headerSection = useMemo(() => {
    if (isFetchingLeaseDays || isFetching) {
      return <Skeleton.Node active className={styles.parentSkeleton} />
    }

    return (
      <>
        {isSelectedDayAfterCurrentDay && (
          <div className={styles.parentActionContainer}>
            <AddWalkInPatientButton
              handleClick={() => setAddPatient(true)}
              isDisabled={isFetchingLeaseDays}
              label={INFO_CONSTANTS.ADD_PATIENT}
            />
            <div className={styles.parentHeadContentSelect}>
              <span className={styles.parentHeadInfo}>{INFO_CONSTANTS.PRE_POPULATE_USING}</span>
              <div className={styles.parentHeadSelect}>
                <Select
                  onSelect={setPrePopulateSchedule}
                  popupClassName='table-select-content'
                  proportion={SELECT_CONSTANTS.SMALL}
                  listOptions={PATIENT_ACTIVITY_INTERVAL}
                  value={prePopulateSchedule}
                />
              </div>
              <span className={styles.parentHeadInfo}>{INFO_CONSTANTS.TIME_SLOTS}</span>
            </div>
          </div>
        )}
      </>
    )
  }, [
    isFetchingLeaseDays,
    isFetching,
    leaseDays,
    timeZone,
    adjustedTabs,
    isSelectedDayAfterCurrentDay,
    prePopulateSchedule,
  ])

  return (
    <div className={styles.parent}>
      <Show when={!emptyPromotion}>{headerSection}</Show>
      <Show
        when={
          isFetching ||
          isFetchingLeaseDays ||
          !hasFinishedFirstLoad ||
          typeof dayInterval.min === 'number'
        }
        fallback={
          <Calendar
            roomsHeader={roomsHeader}
            handleDisplayEventDetails={handleDisplayEventDetails}
            addPreComplete={isSelectedDayAfterCurrentDay}
            createCustomAppointment={createScheduleWithCustomAppointment}
            disabledEverything={!isSelectedDayAfterCurrentDay}
          />
        }>
        <Show when={emptyPromotion} fallback={<PatientScheduleSkeleton />}>
          <div className={styles.parentEmptyContainer}>
            <EmptyRoomsList
              title='You need to book days before you can add any patients.'
              content={
                <Link className={styles.parentEmptyLink} to='../details'>
                  Book now <NavigationIcon width={32} height={32} />
                </Link>
              }
            />
          </div>
        </Show>
      </Show>

      <AddEditPatient
        date={day}
        open={addPatient || !!editedPatient}
        isEditing={!!editedPatient}
        editedPatientData={editedPatient}
        prePopulateSchedule={prePopulateSchedule}
        setOpen={handleCloseModal}
        disabledEverything={!isSelectedDayAfterCurrentDay}
        title={!!editedPatient ? INFO_CONSTANTS.EDIT_PATIENT : INFO_CONSTANTS.ADD_PATIENT}
        customAppointment={customAppointmentData}
      />
    </div>
  )
})
