import { isValidPhoneNumber } from 'react-phone-number-input'

import * as Yup from 'yup'

import { RegexService } from 'common/services/regexService'

const LICENSE_SCHEMA = Yup.object().shape({
  path: Yup.array().min(1, 'License file is required'),
  state: Yup.string().required('License state is required'),
  number: Yup.string().required('License number is required'),
})

export const AUTH_MEDICAL_LICENSE_SCHEMA = Yup.object().shape({
  medical_license: LICENSE_SCHEMA,
})

export const AUTH_DRIVER_LICENSE_SCHEMA = Yup.object().shape({
  driver_license: LICENSE_SCHEMA,
})

export const AUTH_VALIDATE_IDENTITY_SCHEMA = Yup.object().shape({
  medical_license: LICENSE_SCHEMA,
  driver_license: LICENSE_SCHEMA,
  first_name: Yup.string()
    .required('Please enter your first name')
    .min(2, 'First name must be at least 2 characters')
    .matches(RegexService.onlyLatin(), 'The input is not valid first name!')
    .nullable(),
  last_name: Yup.string()
    .required('Please enter your last name')
    .min(2, 'Last name must be at least 2 characters')
    .matches(RegexService.onlyLatin(), 'The input is not valid last name!')
    .nullable(),
  site: Yup.number().required('Please select location'),
  phone_number: Yup.string()
    .nullable()
    .min(1, 'Please enter a valid mobile phone.')
    .test('is-valid-phone', 'Please enter a valid mobile phone', function (value) {
      return value && isValidPhoneNumber(value)
    })
    .required('Please enter the phone')
    .nullable(),
})
