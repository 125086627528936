import type { FC } from 'react'
import { useMemo } from 'react'

import { useAuth } from 'app/providers'

import { AddToCartButton } from 'common/components/AddToCartButton/AddToCartButton'
import { Button } from 'common/components/Button/Button'
import {
  BUTTON_CONSTANTS,
  BUTTON_MODIFIER,
  BUTTON_PROPORTION,
  BUTTON_SEVERITY,
} from 'common/constants/buttonConstants'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { UtilService } from 'common/services/utilService'

import type { IBookingInfoRoom, IRoomSelectedDay } from 'features/Booking/interfaces/ILeaseInfoRoom'
import { LeaseBackToBackService } from 'features/Booking/services/leaseBackToBackService'
import {
  UPDATE_NEW_LEASE_ROOM_DAYS,
  UPDATE_NEW_LEASE_SELECTED_DAY,
} from 'features/Booking/state/slices/leaseSlice'
import { useCartManager } from 'features/Cart'
import useUnconfirmedPopup from 'features/Home/hooks/useUnconfirmedPopup'

interface IProps {
  price: number
  handleCreateLeaseAndClose: () => void
  handleCreateLeaseAndCheckout: () => void
}

const { deepEqual, arrDeepEqual } = UtilService

const { updateRoomServicePlacement, updateDaySlots } = LeaseBackToBackService

export const LeaseSubmit: FC<IProps> = ({
  handleCreateLeaseAndCheckout,
  handleCreateLeaseAndClose,
  price,
}) => {
  const dispatch = useAppDispatch()
  const { user, isDoctor, hasCompletedDocuments } = useAuth()
  const { showPopup } = useUnconfirmedPopup()

  const { bookingCart } = useCartManager()

  const { newLeaseInfo: newRoom } = useAppSelector((state) => state.leaseReducer)

  const { selectedDay, selectedDays = [] } = newRoom
  const { rooms: reservedRooms = [] } = bookingCart?.data ?? {}

  const handleSaveSelectedTimeSlot = (): void => {
    const isUnconfirmedDoctor = isDoctor && !hasCompletedDocuments
    if (!user || isUnconfirmedDoctor) {
      return showPopup()
    }

    let updatedDay: IRoomSelectedDay = updateDaySlots(selectedDay)
    updatedDay = updateRoomServicePlacement(updatedDay)
    dispatch(UPDATE_NEW_LEASE_SELECTED_DAY(updatedDay))
    dispatch(UPDATE_NEW_LEASE_ROOM_DAYS())
  }

  const currentRoom: IBookingInfoRoom = useMemo((): IBookingInfoRoom => {
    return reservedRooms.find(({ room }: IBookingInfoRoom): boolean => room.id === newRoom.room.id)
  }, [reservedRooms, newRoom])

  const isCreatingDisabled: boolean = !currentRoom && !selectedDays.length
  const isUpdatingDisabled: boolean =
    currentRoom && arrDeepEqual(selectedDays, currentRoom.selectedDays)

  const isDisabledAddToCart: boolean =
    (currentRoom &&
      deepEqual(currentRoom, newRoom, [
        'slots',
        'free_seat',
        'selectedDay',
        'description',
        'room_counter',
      ])) ||
    isCreatingDisabled ||
    isUpdatingDisabled

  return (
    <>
      <AddToCartButton
        price={price}
        data-cy='apply-changes-btn'
        onClick={handleSaveSelectedTimeSlot}
        disabled={!selectedDay?.selectedSlot}
      />
      {user && (
        <>
          <Button
            onClick={handleCreateLeaseAndClose}
            data-cy='add-to-cart-btn'
            disabled={isDisabledAddToCart}
            modifier={BUTTON_MODIFIER.TERTIARY80}
            proportion={BUTTON_PROPORTION.LARGE}>
            {BUTTON_CONSTANTS.CONTINUE}
          </Button>

          <Button
            onClick={handleCreateLeaseAndCheckout}
            data-cy='checkout-btn'
            disabled={isDisabledAddToCart}
            modifier={BUTTON_MODIFIER.PRIMARY}
            severity={BUTTON_SEVERITY.SUCCESS_FILLED}
            proportion={BUTTON_PROPORTION.LARGE}>
            {BUTTON_CONSTANTS.CHECKOUT}
          </Button>
        </>
      )}
    </>
  )
}
