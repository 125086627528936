import styles from './header-links.module.scss'

import { Link } from 'react-router-dom'

import { ConfigProvider, Menu, type MenuProps } from 'antd'
import { PathUtils } from 'routes/routes.utils'

import { useAuth } from 'app/providers'

const HeaderThemeProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Menu: {
            itemBg: 'transparent',
            // itemColor: 'var(--white)',
            itemHoverColor: 'var(--primary-100)',
            itemSelectedColor: 'var(--primary-100)',
            // Submenu items
            subMenuItemBg: 'var(--white)',
            itemActiveBg: 'var(--primary-20)',
            itemHoverBg: 'var(--primary-20)',
            itemSelectedBg: 'var(--primary-20)',

            groupTitleColor: 'var(--black)',
            colorItemText: 'var(--black)',
            colorItemTextHover: 'var(--primary-100)',
            colorItemTextSelected: 'var(--primary-100)',

            horizontalItemSelectedColor: 'var(--primary-100)',
            horizontalItemBorderRadius: 0,
          },
        },
      }}>
      {children}
    </ConfigProvider>
  )
}

const HeaderLinks = () => {
  const { user } = useAuth()

  const menuItems = [...items, ...(!user ? auth_items : [])]

  return (
    <HeaderThemeProvider>
      <Menu
        overflowedIndicatorPopupClassName={styles.overflowedIndicatorPopupClassName}
        mode='horizontal'
        items={menuItems}
        rootClassName={styles.headerLinksContainer}
      />
    </HeaderThemeProvider>
  )
}

export default HeaderLinks

const items: MenuProps['items'] = [
  {
    label: (
      <a href='https://www.residen.com/how-it-works' target='_blank' rel='noopener noreferrer'>
        How It Works
      </a>
    ),
    key: 'how-it-works',
  },
  {
    label: (
      <a href='https://www.residen.com/who-its-for' target='_blank' rel='noopener noreferrer'>
        Who It's For
      </a>
    ),
    key: 'who-its-for',
  },
  {
    label: (
      <a
        href='https://www.residen.com/medical-spaces-available'
        target='_blank'
        rel='noopener noreferrer'>
        Medical Spaces
      </a>
    ),
    key: 'medical-spaces',
  },
  {
    label: 'Locations',
    key: 'locations',
    children: [
      {
        type: 'group',
        label: (
          <a
            href='https://www.residen.com/location/beverly-hills-ca'
            target='_blank'
            rel='noopener noreferrer'>
            Beverly Hills, CA
          </a>
        ),
      },
      {
        type: 'group',
        label: (
          <a
            href='https://www.residen.com/location/brentwood-ca'
            target='_blank'
            rel='noopener noreferrer'>
            Brentwood, CA
          </a>
        ),
      },
    ],
  },
  {
    label: (
      <a href='https://www.residen.com/the-den' target='_blank' rel='noopener noreferrer'>
        The Den
      </a>
    ),
    key: 'the-den',
  },
  {
    label: 'About Us',
    key: 'about-us',
    children: [
      {
        type: 'group',
        label: (
          <a href='https://www.residen.com/about-us' target='_blank' rel='noopener noreferrer'>
            About Us
          </a>
        ),
      },
      {
        type: 'group',
        label: (
          <a
            href='https://www.residen.com/doctors-in-residen'
            target='_blank'
            rel='noopener noreferrer'>
            Doctors in Residen
          </a>
        ),
      },
    ],
  },

  {
    label: (
      <a href='https://www.residen.com/contact' target='_blank' rel='noopener noreferrer'>
        Contact
      </a>
    ),
    key: 'contact',
  },
]

const auth_items = [
  {
    label: <Link to={PathUtils.getRegister()}>Sign Up</Link>,
    key: 'register',
  },
]
