import { Skeleton } from 'antd'

import { ArrayElements } from 'common/components/ArrayElements/ArrayElements'

import styles from './slotSkeletonList.module.scss'
import type { FC } from 'react'
import { memo } from 'react'

export const SlotSkeletonList: FC = memo(() => {
  return (
    <ArrayElements total={3}>
      <div className={styles.parent}>
        <Skeleton active paragraph={{ rows: 0 }} />
      </div>
    </ArrayElements>
  )
})
