import type { FC } from 'react'
import type { ChangeEvent } from 'react'

import { FormControl } from 'common/components/FormItems/FormControl/FormControl'
import { FORM_CONTROL_TYPE } from 'common/constants/formControlConstants'
import { INPUT_TYPES } from 'common/constants/inputConstants'

import { PaymentCard } from 'features/Profile/features/Payment/components/PaymentCard/PaymentCard'
import { SETTINGS_ADD_CARD_CONSTANTS } from 'features/Profile/features/Payment/constants/constants'
import { SETTINGS_ADD_CARD_KEYS } from 'features/SettingsOld/constants/settingsAddCard'
import type { ISettingPaymentCard } from 'features/SettingsOld/interfaces/ISettingPaymentCard'
import { SettingsService } from 'features/SettingsOld/services/settingsService'

import styles from './settingsCardInfo.module.scss'

interface IProps {
  isLoading: boolean
  editCard: ISettingPaymentCard
  setFieldValue: (field: string, message: string) => void
}

const { formatNumber, formatExpiryDate, formatCVC } = SettingsService

export const SettingsCardInfo: FC<IProps> = ({ editCard, isLoading, setFieldValue }) => {
  if (editCard) return <PaymentCard card={editCard} withMargin={false} />

  return (
    <div className={styles.parent}>
      <FormControl
        required
        data-cy='card-number'
        type={INPUT_TYPES.TEXT}
        isLoading={isLoading}
        control={FORM_CONTROL_TYPE.INPUT}
        name={SETTINGS_ADD_CARD_KEYS.NUMBER}
        onChange={(event: ChangeEvent<HTMLInputElement>): void => {
          setFieldValue(SETTINGS_ADD_CARD_KEYS.NUMBER, formatNumber(event.target.value))
        }}
        label={SETTINGS_ADD_CARD_CONSTANTS.NUMBER}
      />
      <div className={styles.parentFormItem}>
        <FormControl
          required
          data-cy='card-expiry-date'
          type={INPUT_TYPES.TEXT}
          isLoading={isLoading}
          control={FORM_CONTROL_TYPE.INPUT}
          name={SETTINGS_ADD_CARD_KEYS.EXPIRY_DATE}
          onChange={(event: ChangeEvent<HTMLInputElement>): void => {
            setFieldValue(SETTINGS_ADD_CARD_KEYS.EXPIRY_DATE, formatExpiryDate(event.target.value))
          }}
          label={SETTINGS_ADD_CARD_CONSTANTS.EXPIRY_DATE}
        />
        <FormControl
          required
          data-cy='card-cvc'
          type={INPUT_TYPES.TEXT}
          isLoading={isLoading}
          control={FORM_CONTROL_TYPE.INPUT}
          name={SETTINGS_ADD_CARD_KEYS.CVC}
          onChange={(event: ChangeEvent<HTMLInputElement>): void => {
            setFieldValue(SETTINGS_ADD_CARD_KEYS.CVC, formatCVC(event.target.value))
          }}
          label={SETTINGS_ADD_CARD_CONSTANTS.CVC}
        />
      </div>
    </div>
  )
}
