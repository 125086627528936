import { type FC, type JSX, memo, useState } from 'react'

import classNames from 'classnames'

import ModalPreviewFile from 'common/components/Modal/ModalPreviewFile/ModalPreviewFile'
import { Show } from 'common/components/Show/Show'

import { INFO_CONSTANTS } from 'features/Home/constants/infoConstants'

import styles from './slot.module.scss'
import type { SlotProps } from './slot.types'

export const Slot: FC<SlotProps> = memo(({ isLeaseActive, slot }) => {
  const [previewFile, setPreviewFile] = useState<string | null>(null)

  const handlePreviewFile = (fileUrl: string) => {
    setPreviewFile(fileUrl)
  }
  return (
    <details className={styles.parent}>
      <ModalPreviewFile onClose={() => setPreviewFile(null)} previewFile={previewFile} />
      <summary className={styles.parentSummary}>Time slot: {slot.time_slot}</summary>
      <div className={styles.parentContent}>
        {slot.rooms.map(
          (
            { room, options, images_before_lease_start, images_after_lease_start },
            index: number,
          ) => (
            <div className={styles.parentRoom} key={index}>
              <div className={classNames(styles.parentOption)}>
                <span className={styles.parentRoomName}>{room}</span>
              </div>
              <hr />
              <Show when={options}>
                <div className={classNames(styles.parentOption)}>
                  <span className={styles.parentOptionLabel}>{INFO_CONSTANTS.OPTIONS}</span>
                  <span className={styles.parentOptions}>{options}</span>
                </div>
                <hr />
              </Show>
              <div className={classNames(styles.parentOption)}>
                <span className={styles.parentOptionLabel}>{INFO_CONSTANTS.BEFORE_LEASE}</span>
                <Show
                  when={!!images_before_lease_start.length}
                  fallback={<div className={styles.parentStillActive}>N/A</div>}>
                  <div className={styles.parentRoomPhotos}>
                    {images_before_lease_start.map(
                      (leasePhoto: string, index: number): JSX.Element => (
                        <div
                          key={index}
                          className={styles.parentRoomPhoto}
                          onClick={() => handlePreviewFile(leasePhoto)}
                          style={{ backgroundImage: `url('${leasePhoto}')` }}>
                          <span className={styles.parentRoomLabel}>#{index + 1}</span>
                        </div>
                      ),
                    )}
                  </div>
                </Show>
              </div>
              <hr />
              <div className={classNames(styles.parentOption)}>
                <span className={styles.parentOptionLabel}>{INFO_CONSTANTS.AFTER_LEASE}</span>
                <Show
                  when={!!images_after_lease_start.length}
                  fallback={
                    <div className={styles.parentStillActive}>
                      {isLeaseActive ? INFO_CONSTANTS.STILL_ACTIVE : 'N/A'}
                    </div>
                  }>
                  <div className={styles.parentRoomPhotos}>
                    {images_after_lease_start.map(
                      (leasePhoto: string, index: number): JSX.Element => (
                        <div
                          key={index}
                          className={styles.parentRoomPhoto}
                          onClick={() => handlePreviewFile(leasePhoto)}
                          style={{ backgroundImage: `url('${leasePhoto}')` }}>
                          <span className={styles.parentRoomLabel}>#{index + 1}</span>
                        </div>
                      ),
                    )}
                  </div>
                </Show>
              </div>
              <hr />
            </div>
          ),
        )}
      </div>
    </details>
  )
})
