import { useCallback } from 'react'
import type { LinkWrapperProps } from './linkWrapper.types'

const LinkWrapper = ({ text }: LinkWrapperProps) => {
  const isUrl = useCallback((str: string) => {
    const urlPattern = /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/i
    return urlPattern.test(str)
  }, [])

  if (!text) return null

  if (typeof text === 'string' && isUrl(text)) {
    return (
      <a
        href={text.startsWith('http') ? text : `https://${text}`}
        target='_blank'
        rel='noopener noreferrer'>
        {text}
      </a>
    )
  }

  return <span>{text}</span>
}

export default LinkWrapper
