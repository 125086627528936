import { isValidPhoneNumber } from 'react-phone-number-input'

import { differenceInSeconds, isBefore } from 'date-fns'
import * as Yup from 'yup'

import { RegexService } from 'common/services/regexService'

import { INFO_CONSTANTS, MIN_SCHEDULE_TIME_IN_MINUTES } from 'features/Home/constants/infoConstants'
import type { AppointmentDate } from 'features/Home/interfaces/IInfoSchedule'

const checkIsValidAppointment = (appointment: AppointmentDate) => {
  const isValidAppt = appointment?.length !== 2
  if (isValidAppt) {
    return true
  }

  if (!appointment[0] && appointment[1]) {
    throw new Yup.ValidationError(INFO_CONSTANTS.PLEASE_SELECT_START_TIME, null, 'appointment')
  }

  if (appointment[0] && !appointment[1]) {
    throw new Yup.ValidationError(INFO_CONSTANTS.PLEASE_SELECT_END_TIME, null, 'appointment')
  }

  const [startTime, endTime] = appointment
  const start = new Date(startTime)
  const end = new Date(endTime)

  if (!isBefore(start, end)) {
    throw new Yup.ValidationError(INFO_CONSTANTS.EARLY_END_TIME_ERROR_MESSAGE, null, 'appointment')
  }

  const timeGap = Math.round(differenceInSeconds(end, start) / 60)

  if (timeGap < MIN_SCHEDULE_TIME_IN_MINUTES) {
    throw new Yup.ValidationError(
      INFO_CONSTANTS.EARLY_END_TIME_FIVE_MINUTES_GAP_ERROR,
      null,
      'appointment',
    )
  }

  return true
}

export const NEW_PATIENT_SCHEMA = Yup.object().shape({
  first_name: Yup.string()
    .required('Please enter the first name')
    .min(2, 'First name must contain at least 2 characters')
    .test('latin-letters-only', 'First name can only contain Latin letters', function (value) {
      const nameToCheck = value?.split('/').at(0)
      return RegexService.onlyLatin().test(nameToCheck)
    }),
  last_name: Yup.string()
    .required('Please enter the last name')
    .min(2, 'Last name must contain at least 2 characters')
    .test(function (value) {
      const { createError } = this
      const nameToCheck = value?.split('/').at(0)
      if (RegexService.onlyLatin().test(nameToCheck)) return true
      return createError({ message: 'Last name can only contain Latin letters' })
    }),
  phone: Yup.string()
    .nullable()
    .required('Please enter the mobile phone')
    .min(1, 'Please enter a valid mobile phone.')
    .test('is-valid-phone', 'Please enter a valid mobile phone', function (value) {
      return value && isValidPhoneNumber(value)
    }),
  language: Yup.string().required('Please select the language'),
  room: Yup.string().required('Please select the room'),
  appointment: Yup.array()
    .required('Please select the appointment time')
    .test({
      name: 'is-valid-appointment',
      message: 'Invalid appointment time',
      test: (value) => checkIsValidAppointment(value as AppointmentDate),
    }),
})
