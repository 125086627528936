import type { FC } from 'react'

import { Overlay } from 'common/components/Overlay'

import { LeaseContent } from 'features/Booking/components/LeaseContent/LeaseContent'
import type { IBookingOverlayProps } from 'features/Booking/interfaces/ILeaseOverlayProps'

import styles from './leaseOverlay.module.scss'

export const LeaseOverlay: FC<IBookingOverlayProps> = ({
  title,
  handleCreateLeaseAndClose,
  isCreateLease,
  handleCloseOverlay,
  fetchRoomCalendar,
  handleCreateLeaseAndCheckout,
}) => {
  return (
    <Overlay title={title} isOpen={isCreateLease} onClose={handleCloseOverlay}>
      <div className={styles.parent}>
        <LeaseContent
          handleCreateLeaseAndCheckout={handleCreateLeaseAndCheckout}
          handleCreateLeaseAndClose={handleCreateLeaseAndClose}
          fetchRoomCalendar={fetchRoomCalendar}
        />
      </div>
    </Overlay>
  )
}
