import type { FC } from 'react'
import { memo } from 'react'

import { BookingUpload } from 'features/Home/Book/Booking/BookingDetails/ScreenList/BookingUpload/BookingUpload'
import { INFO_CONSTANTS } from 'features/Home/constants/infoConstants'

import styles from './screenList.module.scss'
import type { ScreenListProps } from './screenList.types'

export const ScreenList: FC<ScreenListProps> = memo(
  ({
    booking,
    isDisabled,
    handleAddMedia,
    isFetching,
    isRemovingMedia,
    isUploading = false,
    handleRemoveMedia,
  }) => {
    const { display_1 = [], display_2 = [] } = booking?.media ?? {}
    return (
      <section className={styles.parent}>
        <h3 className={styles.parentTitle}>{INFO_CONSTANTS.MEDIA}</h3>
        <p className={styles.parentDescription}>{INFO_CONSTANTS.MEDIA_DESCRIPTION}</p>
        <div className={styles.parentDocuments}>
          <BookingUpload
            screen={1}
            isDisabled={isDisabled}
            isFetching={isFetching}
            uploadedFiles={display_1}
            handleAddMedia={handleAddMedia}
            isRemovingMedia={isRemovingMedia}
            handleRemoveMedia={handleRemoveMedia}
            isUploading={isUploading}
          />
          <BookingUpload
            screen={2}
            isDisabled={isDisabled}
            isFetching={isFetching}
            uploadedFiles={display_2}
            handleAddMedia={handleAddMedia}
            isRemovingMedia={isRemovingMedia}
            handleRemoveMedia={handleRemoveMedia}
            isUploading={isUploading}
          />
        </div>
      </section>
    )
  },
)
