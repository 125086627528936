import Yup from 'yup/yup-extensions'

import { RegexService } from 'common/services/regexService'

import { SettingsMailboxLabels } from 'features/Profile/features/Payment/constants/constants'

export const mailboxSchema = Yup.object().shape({
  // applicant
  applicant_initials: Yup.string()
    .required()
    .max(3)
    .label(SettingsMailboxLabels.initials)
    .matches(RegexService.onlyLatin(), 'Please enter valid initials'),
  applicant_home_address: Yup.string().required().max(40).label(SettingsMailboxLabels.address),
  applicant_home_city: Yup.string()
    .required()
    .max(40)
    .matches(RegexService.onlyLatin(), 'Please enter a valid city')
    .label(SettingsMailboxLabels.city),
  applicant_home_state: Yup.string().nullable().required().label(SettingsMailboxLabels.state),
  applicant_home_zip: Yup.string()
    .required()
    .matches(RegexService.postalCode(), 'Please enter valid postal code')
    .label(SettingsMailboxLabels.postalCode),
  applicant_home_phone_number: Yup.string()
    .nullable()
    .required()
    .phone()
    .label(SettingsMailboxLabels.homePhone),

  // business
  business_name: Yup.string().required().max(40).label(SettingsMailboxLabels.firmName),
  business_address: Yup.string().required().max(40).label(SettingsMailboxLabels.address),
  business_city: Yup.string()
    .required()
    .max(40)
    .matches(RegexService.onlyLatin(), 'Please enter a valid city')
    .label(SettingsMailboxLabels.city),
  business_state: Yup.string().nullable().required().label(SettingsMailboxLabels.state),
  business_zip: Yup.string()
    .required()
    .matches(RegexService.postalCode(), 'Please enter valid postal code')
    .label(SettingsMailboxLabels.postalCode),
  business_phone_number: Yup.string()
    .nullable()
    .required()
    .phone()
    .label(SettingsMailboxLabels.businessPhone),
  business_type: Yup.string()
    .nullable()
    .required()
    .max(40)
    .label(SettingsMailboxLabels.businessType),
  // forwarding
  is_forward_mailbox: Yup.boolean(),

  forwarding_address: Yup.string().when('is_forward_mailbox', {
    is: true,
    then: Yup.string().nullable().required().max(40).label(SettingsMailboxLabels.address),
    otherwise: Yup.string().nullable(),
  }),
  forwarding_city: Yup.string().when('is_forward_mailbox', {
    is: true,
    then: Yup.string()
      .nullable()
      .required()
      .max(40)
      .matches(RegexService.onlyLatin(), 'Please enter a valid city')
      .label(SettingsMailboxLabels.city),
    otherwise: Yup.string().nullable(),
  }),
  forwarding_state: Yup.string().when('is_forward_mailbox', {
    is: true,
    then: Yup.string().nullable().required().label(SettingsMailboxLabels.state),
    otherwise: Yup.string().nullable(),
  }),
  forwarding_zip: Yup.string().when('is_forward_mailbox', {
    is: true,
    then: Yup.string()
      .nullable()
      .required()
      .matches(RegexService.postalCode(), 'Please enter valid postal code')
      .label(SettingsMailboxLabels.postalCode),
    otherwise: Yup.string().nullable(),
  }),
  forwarding_phone_number: Yup.string()
    .nullable()
    .when('is_forward_mailbox', {
      is: true,
      then: Yup.string().nullable().required().phone().label(SettingsMailboxLabels.forwardingPhone),
    }),
})
