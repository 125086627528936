import type { FC, ReactElement } from 'react'
import { memo } from 'react'

import { Tooltip } from 'antd'

import { useScreenWidth } from 'app/hooks/useScreenWidth'

import { SectionItem } from 'features/Home/Book/Booking/BookingDetails/GoodToKnow/SectionItem/SectionItem'
import type { IEhrDetail } from 'features/Home/interfaces/IInfoGoodToKnow'

import styles from './ehrDetails.module.scss'
import type { EhrDetailsProps } from './ehrDetails.types'

export const EhrDetails: FC<EhrDetailsProps> = memo(({ details }) => {
  const { isMobile } = useScreenWidth()

  return (
    <>
      {details.map(({ lc_key, lc_value, name, privacy }: IEhrDetail): ReactElement => {
        const nameTooltip = name.length >= (isMobile ? 15 : 25) ? name : null

        return (
          <SectionItem
            className={styles.parent}
            key={lc_key}
            value={lc_value}
            {...(!!privacy && { type: 'secret' })}
            field={
              <Tooltip title={nameTooltip}>
                <span className={styles.parentName}>{name}</span>
              </Tooltip>
            }
          />
        )
      })}
    </>
  )
})
