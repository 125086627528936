import React from 'react'
import { Navigate } from 'react-router-dom'

import { useAuth } from 'app/providers'
import { PathUtils } from 'routes/routes.utils'

export const ProtectedRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user } = useAuth()

  if (!user) {
    return <Navigate to={PathUtils.getAuth()} replace />
  }

  return <>{children}</>
}
