import { useParams } from 'react-router-dom'

import { Empty } from 'antd'

import Show from 'common/components/Show2/Show'

import { BookingThumbnails } from 'features/Home/Dashboard/UserDashboard/ActiveBookings/BookingThumbnails/BookingThumbnails'
import { useLazyFetchPromotionRecurringQuery } from 'features/Home/Promotion/state/promotionsApi'
import type { FC } from 'react'
import { memo, useEffect } from 'react'

export const Others: FC = memo(() => {
  const { bookingId, siteId } = useParams()

  const [fetchPromotionRecurring, { data, isLoading, isSuccess }] =
    useLazyFetchPromotionRecurringQuery()

  useEffect(() => {
    if (!bookingId || !siteId) {
      return
    }

    fetchPromotionRecurring({ bookingId, siteId })
  }, [bookingId, siteId])
  return (
    <>
      <Show>
        <Show.When isVisible={!data?.length && isSuccess}>
          <Empty />
        </Show.When>
        <Show.Else>
          <BookingThumbnails bookings={data} isLoading={isLoading} />
        </Show.Else>
      </Show>
    </>
  )
})
