import type { FC } from 'react'
import { memo } from 'react'

import { Tooltip } from 'antd'

import { useScreenWidth } from 'app/hooks/useScreenWidth'

import { Show } from 'common/components/Show/Show'

import { SectionItem } from 'features/Home/Book/Booking/BookingDetails/GoodToKnow/SectionItem/SectionItem'
import type { IAccessCode } from 'features/Home/interfaces/IInfoGoodToKnow'

import styles from './accessCodes.module.scss'
import type { AccessCodesProps } from './accessCodes.types'

export const AccessCodes: FC<AccessCodesProps> = memo(
  ({ isBookingActive, codes, isDoctor, resendCode, resetCode }) => {
    const { isMobile } = useScreenWidth()

    return (
      <>
        {codes.map(({ id, name, code, is_doctor }: IAccessCode): JSX.Element => {
          const nameTooltip = name.length >= (isMobile ? 15 : 25) ? name : null
          return (
            <SectionItem
              key={id}
              isBookingActive={isBookingActive}
              value={code}
              type='secret'
              field={
                <>
                  <Tooltip title={nameTooltip}>
                    <span className={styles.parentName}>{name}</span>
                  </Tooltip>
                  <Show when={!is_doctor}>
                    <span className={styles.parentStaff}>(Staff)</span>
                  </Show>
                </>
              }
              handleReset={isDoctor ? () => resetCode(id) : undefined}
              handleResend={isDoctor ? () => resendCode(id) : undefined}
            />
          )
        })}
      </>
    )
  },
)
