import { createApi } from '@reduxjs/toolkit/query/react'
import type { BaseQueryFn } from '@reduxjs/toolkit/src/query/baseQueryTypes'
import type { EndpointBuilder } from '@reduxjs/toolkit/src/query/endpointDefinitions'

import { HTTP_METHODS } from 'common/constants/httpMethodsConstants'
import type { IOption } from 'common/interfaces/IOption'
import type { IRequestData } from 'common/interfaces/IRequestResponse'
import { UtilService } from 'common/services/utilService'
import { baseQuery } from 'common/store/api/fetchBaseQuery'
import { REDUCERS } from 'common/store/reducerConstants'

import type { BookingType, IBookingNotification } from 'features/Home/interfaces/IInfo'
import type {
  IInfoPatientSchedule,
  IPatientRoom,
  IPatientSlot,
} from 'features/Home/interfaces/IInfoPatient'
import type { IPatientSchedule, IPatientStatus } from 'features/Home/interfaces/IInfoSchedule'
import type { IInfoShopItem } from 'features/Home/interfaces/IInfoShop'
import type { INFO_SHOP_ITEM_TYPE } from 'features/Home/types/infoShopType'
import type { TableParamsType } from 'common/interfaces/ITable'

const { withPaginationParams } = UtilService

export enum INFO_TAGS {
  LEASE_INFO = 'LEASE_INFO',
  PATIENT_SCHEDULE = 'PATIENT_SCHEDULE',
  STAFF = 'STAFF',
}

export const bookApi = createApi({
  reducerPath: REDUCERS.INFO,
  baseQuery: baseQuery(),
  tagTypes: Object.values(INFO_TAGS),
  endpoints: (build: EndpointBuilder<BaseQueryFn, string, string>) => ({
    fetchLeaseStatus: build.query({
      query: ({ siteId, bookingId }: { siteId: string; bookingId: string }) => ({
        url: `/leases/${bookingId}/status`,
        headers: { 'Database-Connection': siteId },
      }),
      transformResponse: ({ status }: { status: string }): string =>
        UtilService.capitalizeFirstLetter(status),
    }),
    fetchLeaseInfo: build.query({
      query: ({ siteId, bookingId }: { siteId: string; bookingId: string }) => ({
        url: `/leases/${bookingId}`,
        headers: { 'Database-Connection': siteId },
      }),
      transformResponse: ({ item }: Record<string, any>): BookingType => {
        return {
          ...item,
          status: UtilService.capitalizeFirstLetter(item.status),
          ...(item.lease_days && { lease_days: UtilService.sortTabPanelDays(item.lease_days) }),
        }
      },
      providesTags: [INFO_TAGS.LEASE_INFO, INFO_TAGS.PATIENT_SCHEDULE],
    }),
    fetchLeaseDocuments: build.query({
      query: ({ siteId, bookingId }: { siteId: string; bookingId: string }) => ({
        url: `/leases/${bookingId}/docs`,
        headers: { 'Database-Connection': siteId },
      }),
    }),

    fetchLeaseShop: build.query({
      query: ({ siteId, bookingId }: { siteId: string; bookingId: string }) => ({
        url: `/leases/${bookingId}/shop`,
        headers: { 'Database-Connection': siteId },
      }),
      transformResponse: (response: IInfoShopItem[]): IInfoShopItem[] =>
        response.map((item: IInfoShopItem) => ({
          ...item,
          type: UtilService.capitalizeFirstLetter(item.type) as INFO_SHOP_ITEM_TYPE,
        })),
    }),

    fetchLeaseStaff: build.query({
      query: ({
        siteId,
        bookingId,
        tableParams,
      }: {
        siteId: string
        bookingId: string
        tableParams: TableParamsType
      }) => ({
        url: withPaginationParams(`/leases/${bookingId}/staff`, tableParams),
        headers: { 'Database-Connection': siteId },
      }),
      providesTags: [INFO_TAGS.STAFF],
    }),

    fetchLeaseStaffOnAdd: build.query({
      query: ({ siteId, bookingId }: { siteId: string; bookingId: string }) => ({
        url: `/leases/${bookingId}/staff/create`,
        headers: { 'Database-Connection': siteId },
      }),
    }),

    fetchLeaseSlots: build.query({
      query: ({
        siteId,
        bookingId,
        date,
      }: {
        siteId: string
        bookingId: string
        date: string
      }) => ({
        url: `/leases/${bookingId}/day/${date}`,
        headers: { 'Database-Connection': siteId },
      }),
    }),
    updateLeaseMedia: build.mutation({
      query: ({ screen, file, bookingId, siteId }) => ({
        url: `/leases/${bookingId}/media`,
        data: {
          file,
          display_number: screen,
        },
        method: HTTP_METHODS.POST,
        headers: { 'Database-Connection': siteId, 'Content-Type': 'multipart/form-data' },
      }),
      invalidatesTags: [INFO_TAGS.LEASE_INFO],
    }),
    updateLeaseMusic: build.mutation({
      query: ({ ambient_music_id, bookingId, siteId }) => ({
        url: `/leases/${bookingId}/ambient-music`,
        data: { ambient_music_id },
        method: HTTP_METHODS.POST,
        headers: { 'Database-Connection': siteId, 'Content-Type': 'multipart/form-data' },
      }),
    }),
    removeLeaseMedia: build.mutation({
      query: ({ mediaId, bookingId, siteId }) => ({
        url: `/leases/${bookingId}/media/${mediaId}`,
        method: HTTP_METHODS.DELETE,
        headers: { 'Database-Connection': siteId },
      }),
      invalidatesTags: [INFO_TAGS.LEASE_INFO],
    }),

    fetchPatientActivityDays: build.query({
      query: ({ bookingId, siteId }: { siteId: string; bookingId: string }) => ({
        url: `/leases/${bookingId}/days`,
        headers: { 'Database-Connection': siteId },
      }),
      transformResponse: ({ days }: { days: IOption[] }): IOption[] => days,
    }),

    fetchPatientActivity: build.query({
      query: ({
        date,
        siteId,
        bookingId,
      }: {
        date: string
        bookingId: string
        siteId: string
      }) => ({
        url: `/leases/${bookingId}/day/${date}/patients`,
        headers: { 'Database-Connection': siteId },
      }),
      providesTags: ['PatientSchedule'],
      transformResponse: (response: any): IInfoPatientSchedule => {
        const getAdjustedRooms = (
          rooms: {
            code: string
            name: string
            time_slots: IPatientSlot[]
            max_patients: number
            status: IPatientStatus
            id?: number
            is_waiting_room: boolean | undefined
          }[],
        ): IPatientRoom[] =>
          rooms.map(
            (option: {
              code: string
              name: string
              time_slots: IPatientSlot[]
              max_patients: number
              status: IPatientStatus
              id?: number
              is_waiting_room: boolean
            }) => ({
              value: option.code,
              label: option.name,
              slots: option.time_slots,
              max_patients: option.max_patients,
              status: option.status,
              id: option.id,
              is_waiting_room: option.name === 'Waiting Room',
            }),
          )

        return {
          ...response,
          rooms: getAdjustedRooms(response.rooms),
          items: response.items.map((schedule: any): IPatientSchedule => {
            const rooms_list = getAdjustedRooms(response.rooms)
            const room: IPatientRoom | undefined = rooms_list.find(
              (room: IPatientRoom): boolean => room.value === schedule.room,
            )
            return {
              ...schedule,
              room,
            }
          }),
        }
      },
    }),

    createPatientSchedule: build.mutation({
      query: ({ date, siteId, bookingId, data }) => ({
        data,
        url: `/leases/${bookingId}/day/${date}/appointment`,
        method: HTTP_METHODS.POST,
        headers: { 'Database-Connection': siteId },
      }),
      invalidatesTags: ['PatientSchedule'],
    }),

    deletePatientSchedule: build.mutation({
      query: ({ date, siteId, bookingId, id }) => ({
        url: `/leases/${bookingId}/day/${date}/appointment/${id}`,
        method: HTTP_METHODS.DELETE,
        headers: { 'Database-Connection': siteId },
      }),
    }),

    notifyPatient: build.mutation({
      query: ({ siteId, bookingId, id }) => ({
        url: `/leases/${bookingId}/notify-patient/${id}`,
        method: HTTP_METHODS.POST,
        headers: { 'Database-Connection': siteId },
      }),
    }),

    updatePatientSchedule: build.mutation({
      query: ({ date, siteId, bookingId, id, data }) => ({
        data,
        url: `/leases/${bookingId}/day/${date}/appointment/${id}`,
        method: HTTP_METHODS.PUT,
        headers: { 'Database-Connection': siteId },
      }),
    }),
    fetchProcedures: build.query({
      query: ({ search, siteId }: { search: string; siteId: string }) => ({
        url: `leases/appointment/procedures/list?search=${search}`,
        headers: { 'Database-Connection': siteId },
      }),
      transformResponse: ({ items }: { items: string }): string => items,
    }),

    sendPatientsNotification: build.mutation({
      query: ({ siteId, bookingId, data }) => ({
        data,
        url: `/leases/${bookingId}/send-notification`,
        method: HTTP_METHODS.POST,
        headers: { 'Database-Connection': siteId },
      }),
    }),

    updateRoomStatus: build.mutation({
      query: ({ siteId, roomId, data }) => ({
        data,
        url: `/room/${roomId}/status`,
        method: HTTP_METHODS.PUT,
        headers: { 'Database-Connection': siteId },
      }),
    }),

    updateSelectPatients: build.mutation({
      query: ({ siteId, roomId, data }) => ({
        data,
        url: `/room/${roomId}/appointments`,
        method: HTTP_METHODS.POST,
        headers: { 'Database-Connection': siteId },
      }),
    }),

    addLeaseStaff: build.mutation({
      query: ({ siteId, bookingId, id }) => ({
        url: `/leases/${bookingId}/staff`,
        method: HTTP_METHODS.POST,
        headers: { 'Database-Connection': siteId },
        data: { id },
      }),
    }),

    deleteLeaseStaff: build.mutation({
      query: ({ siteId, bookingId, id }) => ({
        url: `/leases/${bookingId}/staff/${id}`,
        method: HTTP_METHODS.DELETE,
        headers: { 'Database-Connection': siteId },
        data: { id },
      }),
    }),
    resetCode: build.query({
      query: ({ siteId, bookingId, id }) => ({
        url: `/leases/${bookingId}/reset-code/${id}`,
        headers: { 'Database-Connection': siteId },
      }),
    }),

    resendCode: build.query({
      query: ({ siteId, bookingId, id }) => ({
        url: `/leases/${bookingId}/send-code/${id}`,
        headers: { 'Database-Connection': siteId },
      }),
    }),

    downloadContract: build.query({
      query: ({ siteId, bookingId, type = 'contract' }) => ({
        url: `leases/${bookingId}/download/${type}`,
        responseType: 'arraybuffer',
        headers: {
          Accept: 'application/pdf',
          'Database-Connection': siteId,
          'Content-Type': 'application/pdf',
        },
      }),
    }),

    terminateLease: build.mutation({
      query: ({ id, siteId }) => ({
        url: `/leases/${id}/terminate-lease`,
        method: HTTP_METHODS.POST,
        headers: { 'Database-Connection': siteId },
      }),
      invalidatesTags: [INFO_TAGS.LEASE_INFO],
    }),

    fetchStorageLeaseNotifications: build.query({
      query: ({ bookingId, siteId, params }) => ({
        url: withPaginationParams(`/leases/${bookingId}/notifications`, params),
        headers: { 'Database-Connection': siteId },
      }),
      transformResponse: (
        response: IRequestData<IBookingNotification[]>,
      ): IRequestData<IBookingNotification[]> => ({
        ...response,
      }),
    }),
  }),
})

export const {
  useLazyResetCodeQuery,
  useLazyResendCodeQuery,
  useLazyFetchLeaseShopQuery,
  useFetchLeaseStaffQuery,
  useLazyFetchLeaseStaffQuery,
  useLazyFetchLeaseStaffOnAddQuery,
  useFetchLeaseInfoQuery,
  useLazyFetchLeaseInfoQuery,
  useFetchLeaseStatusQuery,
  useLazyFetchLeaseStatusQuery,
  useFetchLeaseDocumentsQuery,
  useLazyFetchLeaseDocumentsQuery,
  useLazyFetchLeaseSlotsQuery,
  useUpdateLeaseMediaMutation,
  useRemoveLeaseMediaMutation,
  useUpdateLeaseMusicMutation,
  useLazyDownloadContractQuery,
  useLazyFetchPatientActivityQuery,
  useFetchPatientActivityDaysQuery,
  useLazyFetchProceduresQuery,
  useCreatePatientScheduleMutation,
  useDeletePatientScheduleMutation,
  useNotifyPatientMutation,
  useUpdatePatientScheduleMutation,
  useSendPatientsNotificationMutation,
  useUpdateRoomStatusMutation,
  useUpdateSelectPatientsMutation,
  useDeleteLeaseStaffMutation,
  useAddLeaseStaffMutation,
  useTerminateLeaseMutation,
  useLazyFetchStorageLeaseNotificationsQuery,
}: any = bookApi
