import type { FC } from 'react'
import { memo, useMemo } from 'react'
import { Navigate } from 'react-router-dom'

import { PATH_SEGMENT } from 'routes/pathSegments'
import { generateRouter } from 'routes/routes.utils'

import { TabPanel } from 'common/components/TabPanel/TabPanel'

import { ProviderConditions } from 'features/Provider/components/ProviderConditions'
import { ProviderDictionary } from 'features/Provider/components/ProviderDictionary'

import styles from './provider.module.scss'

export const Provider: FC = memo(() => {
  const routesConfig = useMemo(
    () => ({
      [PATH_SEGMENT.TERMS_AND_CONDITIONS]: <ProviderConditions />,
      [PATH_SEGMENT.DICTIONARY]: <ProviderDictionary />,

      [PATH_SEGMENT.OTHER]: <Navigate to={PATH_SEGMENT.TERMS_AND_CONDITIONS} replace />,
    }),
    [],
  )

  return (
    <div className={styles.parent}>
      <TabPanel
        tabs={[
          {
            path: 'terms-and-conditions',
            title: 'Terms & Conditions',
          },
          {
            path: 'dictionary',
            title: 'Dictionary',
          },
        ]}
      />

      {generateRouter(routesConfig)}
    </div>
  )
})
