import type { FC } from 'react'
import { memo, useCallback } from 'react'

import { Result } from 'antd'

import { Button } from 'common/components/Button/Button'

import './errorPage.scss'
import { useNavigate, useParams } from 'react-router-dom'
import { PATH_SEGMENT } from 'routes/pathSegments'
import type { ResultStatusType } from 'antd/es/result'

export const ErrorPage: FC = memo(() => {
  const { code } = useParams()

  const navigate = useNavigate()

  const navigateToRoot = useCallback(() => {
    navigate(PATH_SEGMENT.ROOT)
  }, [])

  return (
    <Result
      status={(code || 500) as ResultStatusType}
      title='Error'
      subTitle='Sorry, something went wrong please reload page and try again'
      extra={<Button onClick={navigateToRoot}>Reload</Button>}
    />
  )
})
