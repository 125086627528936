export enum SETTINGS_PROFILE_LABELS {
  MY_PROFILE = 'My Profile',
  PROFILE_PHOTO = 'Profile photo',
  USER_DETAILS = 'User details',
  FIRST_NAME = 'First Name',
  LAST_NAME = 'Last Name',
  EMAIL = 'Email',
  MAILING_LINE1 = 'Address line 1',
  MAILING_LINE2 = 'Address line 2',
  ZIP_CODE = 'Zipcode',
  CITY = 'City',
  PHONE = 'Mobile Phone',
  COUNTRY = 'Country',
  SPECIALITY = 'Specialty',
  MEDICAL_LICENSE = 'Medical license',
  MEDICAL_LICENSE_NUMBER = 'Medical license number',
  DEA_LICENSE = 'DEA license',
  DEA_LICENSE_NUMBER = 'DEA license number',
  ADDITIONAL_LICENSE = 'Additional license',
  STATE = 'State',
  CHANGE_PASSWORD = 'Change password',
  CURRENT_PASSWORD = 'Current password',
  NEW_PASSWORD = 'New password',
  CONFIRM_PASSWORD = 'Confirm password',
  BOOKING_NOTIFICATIONS = 'Booking',
  PROFESSIONAL_DETAILS = 'Professional details',
  RELATED_NOTIFICATIONS = 'Booking related notifications',
  STATUS_NOTIFICATIONS = 'Support ticket notifications',
  REMOVE_IMAGE = 'Remove image',
  CONFIRM_DELETE_AVATAR = 'Are your sure you want to delete avatar image?',
  MAILING_ADDRESS = 'Mailing address',
  EMAIL_NOTIFICATIONS_LABEL = 'The following will be emailed to you:',
  SCHEDULE_NOTIFICATIONS = 'Schedule',
  SCHEDULE_NOTIFICATIONS_LABEL = 'The following will be texted to you (data rates may apply):',
  PATIENT_CONFIRMATION = 'Patient Confirmation',
  PATIENT_CANCELATION = 'Patient Cancelation',
  PATIENT_RESCHEDULE = 'Patient Reschedule',
  END_OF_BOOKING = 'End of Booking',
}
