export enum NEW_PATIENT_FORM_KEYS {
  ID = 'id',
  NAME = 'name',
  FIRST_NAME = 'first_name',
  LAST_NAME = 'last_name',
  ROOM = 'room',
  APPOINTMENT = 'appointment',
  PHONE = 'phone',
  LANGUAGE = 'language',
  LANGUAGE_TYPE_CODE = 'language_type_code',
  CONSULTATION_STAFF = 'consultation_staff',
  TEMPERATURE_BYPASS = 'temperature_bypass',
  PROCEDURE = 'procedure',
}

export const INITIAL_PATIENT_DATA = {
  id: '',
  name: '',
  room: '',
  appointment: '',
  phone: '',
  language: '',
  language_type_code: 1,
  consultation_staff: '',
  temperature_bypass: 0,
  procedure: {
    code: '',
    description: '',
  },
}
