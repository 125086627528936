export enum HOME_CONSTANTS {
  STEP1 = 'STEP 1 / 3 (required):',
  STEP2 = 'STEP 2 / 3 (optional):',
  STEP3 = 'STEP 3 / 3 (optional):',
  SELECT_ROOM_AND_DATE = 'Select room for pricing',
  SELECT_EXTRAS = 'Select extras',
  NEW_LEASE = 'New Booking',
  CHANGE_LOCATION = 'Change Location',
  MAIN_LEASES = 'Main Bookings',
  OTHERS = 'Others',
  ROOMS = 'Rooms',
  PROMOTIONS = 'Promotions',
  MAILBOX_LEASES = 'Mailbox Bookings',
  SELECT_ROOM_FOR_PRICING = 'Select room for pricing',
  SHORT_TERM_BOOKING = 'Short Term Booking',
  MEDIUM_TERM_BOOKING = 'Medium Term Booking',
  LONG_TERM_BOOKING = 'Long Term Booking',
}

export enum NewLeasesTypes {
  RoomLease = 'Room',
  // MailboxLease = 'Mailbox',
  // StorageLease = 'Storage',
  // LockerLease = 'Locker lease',
  // MedicalLease = 'Medical lease',
}
