import type { FC, JSX } from 'react'
import { memo } from 'react'

import { Show } from 'common/components/Show/Show'

import { HOME_CONSTANTS } from 'features/Home/constants/homeConstants'
import { LongTermOption } from 'features/Home/Book/Booking/BookingDetails/BookingDays/LongTermList/LongTermOption/LongTermOption'

import styles from './longTermList.module.scss'
import type { LongTermListProps } from './longTermList.types'
import { SlotSkeletonList } from 'features/Home/Book/Booking/BookingDetails/BookingDays/SlotSkeletonList/SlotSkeletonList'

export const LongTermList: FC<LongTermListProps> = memo(({ options, isFetching }) => {
  if (isFetching)
    return (
      <div className={styles.parent}>
        <SlotSkeletonList />
      </div>
    )
  return (
    <div className={styles.parent}>
      <Show when={!!options?.length}>
        <h1>{HOME_CONSTANTS.MEDIUM_TERM_BOOKING}</h1>
      </Show>

      {options?.map(
        (option, index): JSX.Element => (
          <LongTermOption option={option} key={index} />
        ),
      )}
    </div>
  )
})
