import type { FC } from 'react'

import { Skeleton } from 'antd'
import classNames from 'classnames'

import { ArrayElements } from 'common/components/ArrayElements/ArrayElements'

import styles from './thumbnailsSkeleton.module.scss'

interface IProps {
  rows: number
  total?: number
  border?: boolean
  maxWidth?: number
  minWidth?: number
  imgHeight: number
  align?: 'start' | 'center'
}

const ThumbnailsSkeleton: FC<IProps> = ({
  rows,
  imgHeight,
  maxWidth,
  minWidth,
  border = true,
  total = 3,
  align = 'start',
}) => (
  <ArrayElements total={total}>
    <div
      className={classNames(styles.parent, { [styles.parentBorder]: border })}
      style={{ maxWidth: maxWidth, minWidth: minWidth }}>
      <Skeleton.Image active className={styles.parentImg} style={{ minHeight: imgHeight }} />
      <div className={styles.parentContent}>
        <Skeleton
          className={classNames({
            [styles.parentSkeletonCenter]: align === 'center',
          })}
          paragraph={{ rows }}
          active
        />
      </div>
    </div>
  </ArrayElements>
)

export default ThumbnailsSkeleton
