import Show from 'common/components/Show2'
import type { IBookingNotification } from 'features/Home/interfaces/IInfo'
import { BookingNotifications } from 'features/Home/Book/Booking/BookingDetails/BookingNotifications/BookingNotifications'
import { Empty, Pagination, type PaginationProps, Spin, type TablePaginationConfig } from 'antd'
import type { FC } from 'react'
import { memo, useCallback, useEffect, useState } from 'react'
import type { NotificationsProps } from './notifications.types'
import { DEFAULT_TABLE_PARAMS } from 'common/constants/defaultTableParamsConstants'
import { useLazyFetchPromotionNotificationsQuery } from 'features/Home/Promotion/state/promotionsApi'

import './notifications.scss'
import { useParams } from 'react-router-dom'
import { PromotionContainer } from 'features/Home/Promotion/PromotionDetails/PromotionContainer/PromotionContainer'

const itemRender: PaginationProps['itemRender'] = (_, type, originalElement) => {
  if (type === 'prev') {
    return <a>Previous</a>
  }
  if (type === 'next') {
    return <a> Next</a>
  }
  return originalElement
}

export const Notifications: FC<NotificationsProps> = memo(({ promotion, isLoading }) => {
  const [tableParams, setTableParams] = useState<TablePaginationConfig>({
    ...DEFAULT_TABLE_PARAMS,
  })

  const { bookingId, siteId } = useParams()

  const [
    fetchStorageLeaseNotifications,
    { data: notifications, isFetching: isFetchingNotifications },
  ] = useLazyFetchPromotionNotificationsQuery()

  const onNotificationsPageChange = useCallback(
    (page: number) => {
      setTableParams({ ...tableParams, current: page })
    },
    [tableParams],
  )

  useEffect(() => {
    if (!bookingId || !siteId) {
      return
    }

    fetchStorageLeaseNotifications({ bookingId, siteId, params: { ...tableParams } })
  }, [tableParams.pageSize, tableParams.current, bookingId, siteId])

  return (
    <PromotionContainer
      displayCol
      promotion={promotion}
      isLoading={isLoading}
      isAdditionInfoFull
      showAdditionInfo
      showMedia>
      <Spin spinning={isFetchingNotifications}>
        <div className='promotion-notification-container'>
          <h1>Notifications</h1>
          <Show>
            <Show.When isVisible={!!notifications?.items?.length}>
              {notifications?.items?.map((notification: IBookingNotification, index: number) => (
                <BookingNotifications notification={notification} index={index} />
              ))}
              <Pagination
                showSizeChanger={false}
                className='notification-paginator'
                total={notifications?.total_items}
                itemRender={itemRender}
                onChange={onNotificationsPageChange}
              />
            </Show.When>
            <Show.Else>
              <Empty />
            </Show.Else>
          </Show>
        </div>
      </Spin>
    </PromotionContainer>
  )
})
