import { type FC, type JSX } from 'react'

import { ErrorMessage, Field, useField } from 'formik'

import { Label } from 'common/components/Label/Label'
import { TimePickerBySlots } from 'common/components/TimePickerBySlots/TimePickerBySlots'
import { FORM_CONTROL_TYPE } from 'common/constants/formControlConstants'

import styles from './formTimePickerSlots.module.scss'

interface IProps {
  name: string
  options: any[]
  label?: string | JSX.Element
  onChange?: (value: any) => void
  required?: boolean
}

export const FormTimePickerSlots: FC<IProps> = ({
  name,
  label,
  options,
  onChange,
  required,
  ...rest
}) => {
  const [field, _, helpers] = useField(name)

  return (
    <div className={styles.parent}>
      <Label required={required} label={label} />
      <Field
        id={name}
        {...rest}
        {...field}
        onUpdateField={(value: any) => {
          helpers.setTouched(true, false)
          helpers.setValue(value)
          if (onChange) onChange(value)
        }}
        component={TimePickerBySlots}
        appointment={field.value}
        options={options}
        fieldName={name}
        style={{ height: '40px' }}
        data-cy={`${name}-form-control`}
        as={FORM_CONTROL_TYPE.TIME_PICKER_SLOTS}
      />
      <ErrorMessage
        name={name}
        className={styles.parentErrorInform}
        render={(msg) => <p className={styles.parentErrorInform}>{msg}</p>}
      />
    </div>
  )
}
