import amexIcon from 'features/SettingsOld/assets/amex.svg'
import discoverIcon from 'features/SettingsOld/assets/discover.svg'
import jcbIcon from 'features/SettingsOld/assets/jcb.svg'
import mastercardIcon from 'features/SettingsOld/assets/master.svg'
import unionIcon from 'features/SettingsOld/assets/union.svg'
import visaIcon from 'features/SettingsOld/assets/visa.svg'

import type { IOption } from 'common/interfaces/IOption'
import { UtilService } from 'common/services/utilService'

import {
  CVC_ERROR_LIST,
  EXPIRY_MONTH_ERROR_LIST,
  EXPIRY_YEAR_ERROR_LIST,
  NUMBER_ERROR_LIST,
  SETTINGS_ADD_CARD_KEYS,
  SETTINGS_CARD_CONSTANTS,
} from 'features/SettingsOld/constants/settingsAddCard'
import type { ISettingBillingWithId } from 'features/SettingsOld/interfaces/ISettingBilling'
import type { ISettingsStripeError } from 'features/SettingsOld/interfaces/ISettingsStripeError'
import type { SETTINGS_CARD_TYPE } from 'features/SettingsOld/types/settingsCardType'

const { structuredClone } = UtilService

const getFieldHaveError = (
  code: 'number' | 'expiry' | 'cvc',
  error: ISettingsStripeError | null,
): boolean => {
  switch (true) {
    case !error:
      return false
    case code === 'number' && NUMBER_ERROR_LIST.includes(error.code):
      return true
    case code === 'expiry' && EXPIRY_YEAR_ERROR_LIST.includes(error.code):
      return true
    case code === 'cvc' && CVC_ERROR_LIST.includes(error.code):
      return true
    default:
      return false
  }
}

const getCardFieldError = (code: string): string => {
  switch (true) {
    case EXPIRY_MONTH_ERROR_LIST.includes(code):
    case EXPIRY_YEAR_ERROR_LIST.includes(code):
      return SETTINGS_ADD_CARD_KEYS.EXPIRY_DATE
    case CVC_ERROR_LIST.includes(code):
      return SETTINGS_ADD_CARD_KEYS.CVC
    default:
      return SETTINGS_ADD_CARD_KEYS.NUMBER
  }
}

const getPaymentCardIconSrc = (type: SETTINGS_CARD_TYPE): string => {
  switch (type) {
    case SETTINGS_CARD_CONSTANTS.JCB:
      return jcbIcon
    case SETTINGS_CARD_CONSTANTS.AMEX:
      return amexIcon
    case SETTINGS_CARD_CONSTANTS.VISA:
      return visaIcon
    case SETTINGS_CARD_CONSTANTS.UNION:
      return unionIcon
    case SETTINGS_CARD_CONSTANTS.DISCOVER:
      return discoverIcon
    case SETTINGS_CARD_CONSTANTS.MASTERCARD:
      return mastercardIcon
  }
  return type
}

const getStateFromList = (byKey: 'code' | 'name', key: number | string, list: IOption[]) =>
  list.find((item: IOption): boolean => item[byKey] === key)

const getUpdatedCardData = (
  data: ISettingBillingWithId<string | number>,
  states: IOption[],
  countries: IOption[],
  isUpdate?: boolean,
) => {
  const cloneData: ISettingBillingWithId<number | string> = structuredClone(data)

  const { address_state, address_country } = cloneData

  if (typeof address_state === 'string' && isUpdate) {
    const state: IOption = getStateFromList('name', address_state, states)
    cloneData.address_state = state?.code
  } else if (typeof address_state === 'number') {
    const state: IOption = getStateFromList('code', address_state, states)
    cloneData.address_state = state?.name
  }

  if (typeof address_country === 'string' && isUpdate) {
    const country: IOption = getStateFromList('name', address_country, countries)
    cloneData.address_country = country.code
  } else if (typeof address_country === 'number') {
    const country: IOption = getStateFromList('code', address_country, countries)
    cloneData.address_country = country.name
  }

  if (cloneData.exp_date) {
    const [month, year] = cloneData.exp_date.split('/')
    cloneData.exp_month = month
    cloneData.exp_year = year

    delete cloneData.exp_date
  }

  // THIS IS FOR SQUARE PAYMENT
  // delete cloneData.cvc
  // delete cloneData.number
  // delete cloneData.exp_date

  return cloneData
}

const formatExpiryDate = (date: string): string => {
  let filteredDate: string = date.replace(/\D/g, '')
  if (filteredDate.length > 4) filteredDate = filteredDate.slice(0, 4)

  if (filteredDate.length > 2) {
    let month: string = filteredDate.slice(0, Math.round(filteredDate.length / 2))
    const year: string = filteredDate.slice(Math.round(filteredDate.length / 2))
    if (Number(month) > 12) month = '12'
    filteredDate = `${month}/${year}`
  }

  if (filteredDate.length === 1 && !['1', '0'].includes(filteredDate))
    filteredDate = '0' + filteredDate

  return filteredDate
}

const formatCVC = (cvc: string): string => {
  let filteredCVC: string = cvc.replace(/\D/g, '')
  return filteredCVC.length > 4 ? filteredCVC.slice(0, 4) : filteredCVC
}

const formatNumber = (number: string): string => {
  let filteredNumber: string = number.replace(/\D/g, '')
  if (filteredNumber.length > 16) filteredNumber = filteredNumber.slice(0, 16)

  filteredNumber = filteredNumber.replace(/(\d{4})/g, '$1 ').trim()
  return filteredNumber
}

export const SettingsService = {
  formatCVC,
  formatNumber,
  formatExpiryDate,
  getFieldHaveError,
  getCardFieldError,
  getPaymentCardIconSrc,
  getUpdatedCardData,
}
