import type { FC, ReactElement } from 'react'
import React, { Suspense, useMemo } from 'react'

import { Spinner } from 'common/components/Spinner'

import { ErrorBoundary } from './ErrorBoundary/ErrorBoundary'
import type { FallbackComponentProps } from './fallbackComponent.types'

const loaderJSX = (
  <div className='whole-page-wrapper'>
    <Spinner isLoading={true} />
  </div>
)

const FallbackComponent: FC<FallbackComponentProps> = ({ children, hasErrorBoundary }) => {
  const innerJSX: ReactElement = useMemo(
    () => <Suspense fallback={loaderJSX}>{children}</Suspense>,
    [children],
  )

  return hasErrorBoundary ? <ErrorBoundary>{innerJSX}</ErrorBoundary> : innerJSX
}

export default FallbackComponent
