import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

import type { ISiteRoom } from 'common/interfaces/ISiteRoom'
import { REDUCERS } from 'common/store/reducerConstants'

import type { IRoomTimeSlot } from 'features/Booking/interfaces/ILeaseInfoRoom'
import type {
  IOptionSelectFixed,
  IOptionSlot,
  IOptionSlotDay,
} from 'features/Option/interfaces/IOption'
import type { ISlotExtensionRoom } from 'features/Option/interfaces/IOptionShop'
import type { IOptionShopCartItem } from 'features/Option/interfaces/IOptionShopCartItem'
import { OptionService } from 'features/Option/services/optionService'
import type {
  IOptionShopItem,
  IOptionSlice,
  IOptionTypeSlot,
} from 'features/Option/state/interfaces/IOptionSlice'

const { getFilteredDaysByDate } = OptionService

const initialState: IOptionSlice = {
  optionStaffList: [],
  optionTypeSlot: {
    price: 0,
    selectedDay: null,
    selectedDays: [],
  },
  optionShopItem: {
    quantity: 0,
    reservedSlot: null,
    selectedSlot: null,
    selectedRoom: null,
  },
  optionTypeSlots: [],
  optionTypeFixed: [],
  optionShopItems: [],
  optionExtensionRooms: [],
  optionSelectFixed: [],
  overlayOption: null,
  isOpenOptionOverlay: false,
}

export const optionSlice = createSlice({
  name: REDUCERS.OPTION,
  initialState: initialState,
  reducers: {
    SET_OVERLAY_OPTION(state, action: PayloadAction<null | ISiteRoom>) {
      state.overlayOption = action.payload
    },

    SET_OPTION_STAFF_LIST(state, action: PayloadAction<number[]>) {
      state.optionStaffList = action.payload
    },

    UPDATE_OPTION_SHOP_ITEM(state, action: PayloadAction<IOptionShopItem>) {
      state.optionShopItem = { ...state.optionShopItem, ...action.payload }
    },

    UPDATE_OPTION_SHOP_ITEMS(state, action: PayloadAction<IOptionShopCartItem[]>) {
      state.optionShopItems = action.payload
    },

    TOGGLE_IS_OPEN_OPTION_OVERLAY(state) {
      return { ...state, isOpenOptionOverlay: !state.isOpenOptionOverlay }
    },

    SET_OPTION_TYPE_SLOT_DAYS(state, action: PayloadAction<IOptionSlotDay[]>) {
      state.optionTypeSlot.selectedDays = action.payload
    },

    SET_OPTION_TYPE_SLOT(state, action: PayloadAction<IOptionTypeSlot>) {
      state.optionTypeSlot = action.payload
    },

    SET_OPTION_TYPE_SLOTS(state, action: PayloadAction<IOptionSlot[]>) {
      state.optionTypeSlots = action.payload
    },

    SET_OPTION_TYPE_SELECT_FIXED(state, action: PayloadAction<IOptionSelectFixed[]>) {
      state.optionSelectFixed = action.payload
    },

    RESET_OPTION_TYPE_SLOT(state) {
      state.optionTypeSlot = {
        price: 0,
        selectedDay: null,
        selectedDays: [],
      }
    },

    UPDATE_OPTION_EXTENSION_ROOMS(state, action: PayloadAction<ISlotExtensionRoom[]>) {
      state.optionExtensionRooms = action.payload
    },

    UPDATE_SELECTED_OPTION_ROOM(
      state,
      action: PayloadAction<{ bookingId: string; optionId: number }>,
    ) {
      const { optionShopItems } = state
      if (!!optionShopItems.length) {
        const reservedLease = optionShopItems.find(
          (rLease): boolean => rLease.bookingId === action.payload.bookingId,
        )
        if (reservedLease?.options) {
          const reservedRoom = reservedLease.options.find(
            (rOption): boolean => rOption.option.id === action.payload.optionId,
          )
          if (reservedRoom) state.optionShopItem.selectedRoom = reservedRoom.rooms.at(0)
        }
      }
    },

    UPDATE_OPTION_DAY_SLOTS(state, action: PayloadAction<IRoomTimeSlot[]>) {
      if (state.optionTypeSlot?.selectedDay) state.optionTypeSlot.selectedDay.slots = action.payload
    },

    SET_OPTION_TYPE_SELECTED_DAY(state, action: PayloadAction<IOptionSlotDay>) {
      const { selectedDays } = state.optionTypeSlot
      const reservedDay: IOptionSlotDay | undefined = selectedDays.find(
        (day: IOptionSlotDay): boolean => day.date === action.payload.date,
      )
      if (reservedDay) state.optionTypeSlot.selectedDay = { ...action.payload, ...reservedDay }
      else state.optionTypeSlot.selectedDay = action.payload
    },

    UPDATE_RESERVED_OPTION_DATA(state, action: PayloadAction<IOptionTypeSlot>) {
      state.optionTypeSlot = { ...state.optionTypeSlot, ...action.payload }
    },

    UPDATE_OPTION_TYPE_FIXED(state, action: PayloadAction<IOptionSlot[]>) {
      state.optionTypeFixed = action.payload
    },

    UPDATE_OPTION_TYPE_SELECTED_DAYS(state) {
      const { selectedDays, selectedDay } = state.optionTypeSlot
      const { selectedSlots } = selectedDay

      const isReservedDay: boolean = state.optionTypeSlot.selectedDays.some(
        (day: IOptionSlotDay): boolean => day.date === selectedDay.date,
      )

      if (isReservedDay) {
        if (!selectedSlots.length)
          state.optionTypeSlot.selectedDays = getFilteredDaysByDate<IOptionSlotDay>(
            selectedDays,
            selectedDay.date,
          )
        else
          state.optionTypeSlot.selectedDays = selectedDays.map(
            (day: IOptionSlotDay): IOptionSlotDay =>
              day.date === selectedDay.date ? selectedDay : day,
          )
      } else state.optionTypeSlot.selectedDays = [...selectedDays, selectedDay]
    },
  },
})

export const {
  SET_OVERLAY_OPTION,
  SET_OPTION_TYPE_SLOT,
  SET_OPTION_TYPE_SLOTS,
  SET_OPTION_STAFF_LIST,
  RESET_OPTION_TYPE_SLOT,
  UPDATE_OPTION_SHOP_ITEM,
  UPDATE_OPTION_DAY_SLOTS,
  UPDATE_OPTION_TYPE_FIXED,
  SET_OPTION_TYPE_SLOT_DAYS,
  UPDATE_RESERVED_OPTION_DATA,
  SET_OPTION_TYPE_SELECT_FIXED,
  UPDATE_SELECTED_OPTION_ROOM,
  UPDATE_OPTION_EXTENSION_ROOMS,
  TOGGLE_IS_OPEN_OPTION_OVERLAY,
  SET_OPTION_TYPE_SELECTED_DAY,
  UPDATE_OPTION_SHOP_ITEMS,
  UPDATE_OPTION_TYPE_SELECTED_DAYS,
} = optionSlice.actions
