import type { FC, JSX } from 'react'

import type { IRoomSelectedDay, IRoomTimeSlot } from 'features/Booking/interfaces/ILeaseInfoRoom'
import { CartRoomDay } from 'features/Cart/components/CartRoomDay/CartRoomDay'

import styles from './cartRoomDayList.module.scss'

interface IProps {
  reservedDays: IRoomSelectedDay[]
  handleRemoveSlot: (slot: IRoomTimeSlot, date: string) => void
}

export const CartRoomDayList: FC<IProps> = ({ reservedDays, handleRemoveSlot }) => {
  return (
    <div className={styles.parent}>
      {reservedDays.map(
        (day: IRoomSelectedDay, index: number): JSX.Element => (
          <CartRoomDay key={index} day={day} handleRemoveSlot={handleRemoveSlot} />
        ),
      )}
    </div>
  )
}
