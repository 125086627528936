export enum PAYMENT_CONSTANTS {
  METHOD = 'Payment Method',
  PRODUCTS = 'Products',
  CARD_NUMBER = 'Card number',
  MANAGE_PAYMENT = 'You can manage your payment methods on your',
  ADD_PAYMENT = 'You have to add a payment method on your',
  PAYMENT = ' Payment ',
  PAGE = 'page',
  INCOMPLETE_DETAILS_TITLE = 'Incomplete account details',
  INCOMPLETE_DETAILS_DESCRIPTION = 'In order to create a new booking you need to add your mobile phone, please access',
  ACCOUNT_SETTINGS = ' Account settings ',
  COMPLETE_IT = 'to complete it',
  PAY_NOW = 'Pay now',
  RETURN = 'Cancel & Return',
  SUBTOTAL = 'Subtotal',
  TOTAL = 'Total',
  GO_CHECKOUT = 'Go to Checkout',
  TAXES_SALES = 'Taxes (sales & rental)',
  FEES_INSURANCE = 'Fees (insurance)',
  FEES_EHR = 'Fees (EHR setup)',
  DEPOSIT = 'Deposit',
  CURRENTLY = 'We currently can’t process payments over ',
}
