import * as Yup from 'yup'

import { RegexService } from 'common/services/regexService'

import { USA_COUNTRY_ID } from 'features/SettingsOld/constants/settingsAddCard'

export const SETTINGS_BILLING_SCHEMA = Yup.object().shape({
  name: Yup.string()
    .required('Full name is required field')
    .matches(RegexService.fullName(), 'Please enter a valid name')
    .nullable(true),
  address_zip: Yup.string()
    .required('Zip code is required field')
    .nullable(true)
    .matches(RegexService.onlyNumbers(), 'Enter a valid zip code'),
  address_country: Yup.mixed().required('Country is required field').nullable(true),
  address_state: Yup.mixed()
    .nullable(true)
    .when('address_country', {
      is: USA_COUNTRY_ID,
      then: Yup.mixed().required('State is required field'),
    }),
  address_city: Yup.string()
    .required('City is required field')
    .nullable(true)
    .matches(RegexService.onlyLatin(), 'Please enter a valid city'),
  address_line1: Yup.string().required('Address is required field').nullable(true),
  address_line2: Yup.string().nullable(true),
})
