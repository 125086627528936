import { type FC, memo, useEffect, useState } from 'react'

import { Table } from 'common/components/Table/Table'
import { DEFAULT_TABLE_PARAMS } from 'common/constants/defaultTableParamsConstants'
import type { ITriggerPagRequest, ITriggerRequest } from 'common/interfaces/IRequestResponse'
import { TableService } from 'common/services/tableService'

import { SETTINGS_CONSTANTS } from 'features/Profile/features/Payment/constants/constants'
import type { ISettingHistory } from 'features/SettingsOld/interfaces/ISettingHistory'
import {
  useLazyBillingSiteHistoryQuery,
  useLazyDownloadInvoiceQuery,
} from 'features/SettingsOld/state/api/settingsApi'

import styles from './billingHistory.module.scss'
import type { BillingHistoryProps } from './billingHistory.types'
import { HISTORY_TABLE_COLUMNS } from './columns'
import type { ObjectType } from 'common/interfaces/object'
import type { TableParamsType } from 'common/interfaces/ITable'
import { useParams } from 'react-router-dom'
import { TableSearchAndFilters } from 'common/components/TableSearchAndFilters/TableSearchAndFilters'

const { withDownloadPdfForColumn } = TableService

export const BillingHistory: FC<BillingHistoryProps> = memo(({ bookingId }) => {
  const [columns, setColumns] = useState<ObjectType[]>([])
  const [tableParams, setTableParams] = useState<TableParamsType>({
    ...DEFAULT_TABLE_PARAMS,
  })

  const { siteId } = useParams()

  const [
    fetchSiteHistory,
    { data: siteHistory, isFetching: isLoadingSiteHistory },
  ]: ITriggerPagRequest<ISettingHistory[]> = useLazyBillingSiteHistoryQuery()

  const [downloadContract, { isLoading: isDownloadingInvoice }]: ITriggerRequest =
    useLazyDownloadInvoiceQuery()

  useEffect(() => {
    setColumns(
      withDownloadPdfForColumn<ISettingHistory>({
        columnKey: 'invoice_number',
        columns: HISTORY_TABLE_COLUMNS,
        downloadContract: downloadContract,
        tooltipContent: SETTINGS_CONSTANTS.INVOICE,
      }),
    )
  }, [])

  useEffect(() => {
    if (siteHistory?.total_items >= 0)
      setTableParams({ ...tableParams, total: siteHistory.total_items })
  }, [siteHistory])

  useEffect(() => {
    if (tableParams.sorters) {
      setColumns((prev) =>
        prev.map((column) => ({ ...column, sortOrder: tableParams.sorters?.[column.key]?.order })),
      )
    }
  }, [tableParams.sorters])

  useEffect(() => {
    if (!siteId) {
      return
    }

    fetchSiteHistory({
      site: siteId,
      tableParams: {
        ...tableParams,
        search: tableParams.debouncedValue?.length >= 2 ? tableParams.debouncedValue : '',
      },
      bookingId,
    })
  }, [
    tableParams.pageSize,
    tableParams.current,
    tableParams.debouncedValue,
    tableParams.sorters,
    siteId,
    bookingId,
  ])

  return (
    <div className={styles.parent}>
      <h1 className={styles.parentTitle}>{SETTINGS_CONSTANTS.BILLING_HISTORY}</h1>
      <TableSearchAndFilters
        setTableParams={setTableParams}
        setColumns={setColumns}
        tableParams={tableParams}
        inputHint='* Search by invoice, booking id, amount, points'
      />
      <Table
        itemKey='id'
        haveActions={false}
        tableParams={tableParams}
        setTableParams={setTableParams}
        dataSource={siteHistory?.items}
        columns={columns}
        isLoading={isLoadingSiteHistory || isDownloadingInvoice}
      />
    </div>
  )
})
