import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import type { ISiteSelector } from 'common/interfaces/ISiteSelector'
import { useFetchSitesQuery } from 'common/store/api/rootApi'

export type useSitesReturnType = {
  sites?: ISiteSelector[]
  isFetchingSites?: boolean
  selectedSite?: ISiteSelector
}

export const useSites = (): useSitesReturnType => {
  const params = useParams()

  const { data: { sites = [] } = {}, isFetching } = useFetchSitesQuery()

  const selectedSite = useMemo(() => {
    return sites?.find((site) => site.code === params?.siteId)
  }, [sites, params])

  return {
    sites,
    isFetchingSites: isFetching,
    selectedSite,
  }
}
