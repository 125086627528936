import { useToggle } from 'react-use'

import { ModalAction } from 'common/components/Modal'
import { BUTTON_CONSTANTS } from 'common/constants/buttonConstants'
import { useApiResponse } from 'common/hooks/useApiResponse'

import { SETTINGS_PROFILE_LABELS } from 'features/SettingsOld/constants/settingsProfileLabels'
import {
  useDeleteImageMutation,
  useFetchProfileQuery,
} from 'features/SettingsOld/state/api/settingsApi'

import { ProfileForm } from './components/ProfileForm'

export const PersonalProfile = () => {
  const { processApiResponse } = useApiResponse()
  const [isConfirmModalOpened, toggleIsConfirmModalOpened] = useToggle(false)
  const { data } = useFetchProfileQuery()

  const [deleteImage] = useDeleteImageMutation()

  const handleConfirmDeleteAvatar = async (): Promise<void> => {
    const response = await deleteImage(data?.profile?.image?.id)
    processApiResponse(response, {
      error: 'Delete avatar error',
      success: 'Delete avatar success',
    })
    toggleIsConfirmModalOpened()
  }

  return (
    <>
      <ModalAction
        isOpen={isConfirmModalOpened}
        title={SETTINGS_PROFILE_LABELS.REMOVE_IMAGE}
        confirmText={BUTTON_CONSTANTS.DELETE}
        cancelText={BUTTON_CONSTANTS.CANCEL}
        onConfirm={handleConfirmDeleteAvatar}
        onClose={toggleIsConfirmModalOpened}
        description={SETTINGS_PROFILE_LABELS.CONFIRM_DELETE_AVATAR}
      />
      <ProfileForm onDeleteAvatar={toggleIsConfirmModalOpened} />
    </>
  )
}
