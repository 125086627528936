import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

import { REDUCERS } from 'common/store/reducerConstants'

import type { PromotionBookingType } from 'features/Home/Promotion/types/promotion.types'

import type { PromotionSliceType } from './interfaces/promotion.type'

const initialState: PromotionSliceType = {
  promotionErrorSlots: {},
  signature: null,
}

export const promotionSlice = createSlice({
  name: REDUCERS.PROMOTIONS,
  initialState,
  reducers: {
    updatePromotionLatestCrud(state, action: PayloadAction<PromotionBookingType>) {
      const item = action.payload
      if (item) {
        state.promotionErrorSlots = {
          ...state.promotionErrorSlots,
          [item.date]: item.slots.map((sl) => {
            return { ...sl, rooms: sl.rooms.filter((rm) => rm?.status === 'error') }
          }),
        }
      }
    },
    removeRoomFromErrorSlots(
      state,
      action: PayloadAction<{ date: string; slotId: number; roomId: number }>,
    ) {
      const { date, slotId, roomId } = action.payload
      const slots = state.promotionErrorSlots[date]
      if (!slots) return

      const updatedSlots = slots.map((slot) => {
        if (slot.id === slotId) {
          const updatedRooms = slot.rooms.filter((room) => room.id !== roomId)
          return { ...slot, rooms: updatedRooms }
        }
        return slot
      })
      state.promotionErrorSlots[date] = updatedSlots
    },

    updateSignature(state, action: PayloadAction<string | null>) {
      state.signature = action.payload
    },
  },
})

export const { updatePromotionLatestCrud, removeRoomFromErrorSlots, updateSignature } =
  promotionSlice.actions
