import type { FC } from 'react'
import React, { memo, useEffect, useMemo } from 'react'
import { PATH_SEGMENT } from 'routes/pathSegments'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { generateRouter } from 'routes/routes.utils'
import { Staff } from './Staff/Staff'
import { Documents } from './Documents/Documents'
import { Shop } from './Shop/Shop'
import { TabPanel } from 'common/components/TabPanel/TabPanel'
import { bookingConfig } from './config'
import { useLazyFetchLeaseInfoQuery } from 'features/Home/Book/state/api/bookApi'
import { UPDATE_INFO_TIME_ZONE } from 'features/Home/Book/state/slice/bookSlice'
import { useAppDispatch } from 'common/hooks/redux'
import { BookingDetails } from './BookingDetails/BookingDetails'
import { Spinner } from 'common/components/Spinner'
import { PusherProvider } from 'app/providers/pusher/PusherProvider'
import { usePrevious } from 'react-use'
import Schedule from './Schedule/Schedule'
import { useAuth } from 'app/providers'

export const Booking: FC = memo(() => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { isStaff } = useAuth()

  const { bookingId, siteId } = useParams()

  const [fetchBooking, { data: booking, isFetching: isFetchingBooking }] =
    useLazyFetchLeaseInfoQuery()

  const statusCode = booking?.status_code

  const previousSiteId = usePrevious(siteId)

  const tabs = useMemo(() => {
    return bookingConfig.getTabs(booking?.lease_type_name, statusCode, isStaff)
  }, [booking, statusCode])

  const routesConfig = useMemo(
    () => ({
      [PATH_SEGMENT.DETAILS]: (
        <BookingDetails booking={booking} isFetchingBooking={isFetchingBooking} />
      ),
      [`${PATH_SEGMENT.DETAILS}/:day`]: (
        <BookingDetails booking={booking} isFetchingBooking={isFetchingBooking} />
      ),
      [PATH_SEGMENT.SCHEDULE]: <Schedule booking={booking} />,
      [`${PATH_SEGMENT.SCHEDULE}/:day`]: <Schedule booking={booking} />,
      ...(!isStaff
        ? {
            [PATH_SEGMENT.STAFF]: <Staff />,
            [`${PATH_SEGMENT.DOCUMENTS}/*`]: <Documents />,
          }
        : {}),

      [PATH_SEGMENT.SHOP]: <Shop />,
      [PATH_SEGMENT.ROOT]: <Navigate to={PATH_SEGMENT.DETAILS} replace />,
    }),
    [booking, isFetchingBooking],
  )

  useEffect(() => {
    if (!bookingId || !siteId) {
      return
    }

    fetchBooking({ bookingId, siteId })
  }, [bookingId, siteId])

  useEffect(() => {
    if (!booking) {
      return
    }

    dispatch(UPDATE_INFO_TIME_ZONE(booking.time_zone))
  }, [booking])

  useEffect(() => {
    if (!!siteId && !!previousSiteId && siteId !== previousSiteId) {
      navigate(PATH_SEGMENT.ROOT)
    }
  }, [siteId, previousSiteId])

  return (
    <>
      {!!booking && !!bookingId && !!siteId ? (
        <PusherProvider selectedSite={siteId} selectedLease={bookingId}>
          <>
            <TabPanel tabs={tabs} />
            {generateRouter(routesConfig)}
          </>
        </PusherProvider>
      ) : (
        <div className='content-page-wrapper'>
          <Spinner size='large' isLoading={true} />
        </div>
      )}
    </>
  )
})

export type BookingType = typeof Booking

Booking.displayName = 'Booking'
