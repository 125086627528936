import type { FC } from 'react'
import { useId } from 'react'

import { Input as AntInput, Skeleton } from 'antd'
import classNames from 'classnames'

import { Label } from 'common/components/Label/Label'
import { Show } from 'common/components/Show/Show'
import { INPUT_PLACEHOLDER } from 'common/constants/inputConstants'
import type { IInput } from 'common/interfaces/IInput'

import styles from './input.module.scss'

export const Input: FC<IInput> = ({
  label,
  isLoading,
  required,
  placeholder,
  parentClassName,
  inputContainerClassName,
  isActive,
  ...rest
}) => {
  const id = useId()
  return (
    <div className={classNames(styles.parent, parentClassName)}>
      <Label id={id} required={required} label={label} />
      <div className={classNames(styles.container, inputContainerClassName)} data-active={isActive}>
        <Show
          when={!isLoading}
          fallback={<Skeleton.Input className={styles.containerSkeleton} active />}>
          <AntInput id={id} placeholder={placeholder || INPUT_PLACEHOLDER.TYPE_HERE} {...rest} />
        </Show>
      </div>
    </div>
  )
}
