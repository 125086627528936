import type { FC } from 'react'
import React, { memo, useMemo } from 'react'
import { generateRouter } from 'routes/routes.utils'
import { PATH_SEGMENT } from 'routes/pathSegments'
import { Dashboard } from './Dashboard/Dashboard'
import { Navigate } from 'react-router-dom'
import { ChooseRoom } from './Book/ChooseRoom/ChooseRoom'
import { Booking } from './Book/Booking/Booking'
import { Promotion } from './Promotion/Promotion'

export const Home: FC = memo(() => {
  const routesConfig = useMemo(
    () => ({
      [PATH_SEGMENT.ROOT]: <Dashboard />,
      [`${PATH_SEGMENT.BOOK}/*`]: <ChooseRoom />,
      [`${PATH_SEGMENT.BOOKING}/:bookingId/*`]: <Booking />,
      [`${PATH_SEGMENT.PROMOTION}/:bookingId/*`]: <Promotion />,
      [PATH_SEGMENT.OTHER]: <Navigate to={PATH_SEGMENT.ROOT} replace />,
    }),
    [],
  )

  return <>{generateRouter(routesConfig)}</>
})

export type HomeType = typeof Home

Home.displayName = 'Home'
