import type { FC, JSX } from 'react'
import { memo, useEffect, useMemo } from 'react'

import { DatePicker, Skeleton } from 'antd'
import classNames from 'classnames'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'

import prevIcon from 'assets/icons/left.svg'
import nextIcon from 'assets/icons/right.svg'

import { ALT_CONSTANTS } from 'common/constants/altConstants'
import { BOOKING_STATUS } from 'common/constants/calendarConstants'
import { DATE_FORMAT } from 'common/constants/dateFormatConstants'

import './calendar.scss'
import type { INewLeaseDays } from 'features/Booking/interfaces/ILeaseInfoRoom'

interface IProps {
  days?: INewLeaseDays
  currentDay?: string
  showCurrentDay?: boolean
  activeDays?: string[]
  selectedDays?: string[]
  isStrictDisable?: boolean
  withFakeRerender?: boolean
  isLoading?: boolean
  disabledCells?: boolean
  handleChangeDate?: (date: string) => void
  handleChangeMonth?: (id?: number | null, date?: string) => void
  minDate?: Dayjs
  maxDate?: Dayjs
}

export const Calendar: FC<IProps> = memo(
  ({
    days = {},
    currentDay,
    selectedDays = [],
    activeDays = [],
    isLoading = false,
    handleChangeDate = () => undefined,
    handleChangeMonth = () => undefined,
    isStrictDisable = false,
    withFakeRerender = false,
    disabledCells = false,
    showCurrentDay = true,
    minDate,
    maxDate,
  }) => {
    useEffect(() => {
      const todayPickerCell = document.querySelector('.ant-picker-cell-today')
      if (todayPickerCell) {
        todayPickerCell.classList.remove('ant-picker-cell-today')
      }

      const formatDate = (dateString: string) => {
        const [year, month, day] = dateString.split('-')
        return `${month}/${day}/${year}`
      }

      const tdElements = document.querySelectorAll('.ant-picker-content td[title]')

      if (!tdElements.length || tdElements[0].getAttribute('title')?.includes('/')) {
        return
      }

      tdElements.forEach((td) => {
        const originalTitle = td.getAttribute('title')
        const formattedTitle = formatDate(originalTitle)
        td.setAttribute('title', formattedTitle)
      })
    })

    const getBookingStatus = (date: Dayjs): string => {
      const dayData = days[date.format(DATE_FORMAT.PATIENT_TRACKER_DATE)]
      if (!dayData || dayData.active !== 1) return ''

      switch (dayData.occupancy_type) {
        case BOOKING_STATUS.EMPTY:
          return `booked-${BOOKING_STATUS.EMPTY}`
        case BOOKING_STATUS.QUARTER:
          return `booked-${BOOKING_STATUS.QUARTER}`
        case BOOKING_STATUS.HALF:
          return `booked-${BOOKING_STATUS.HALF}`
        case BOOKING_STATUS.THREE_QUARTERS:
          return `booked-${BOOKING_STATUS.THREE_QUARTERS}`
        case BOOKING_STATUS.FULL:
          return `booked-${BOOKING_STATUS.FULL}`
        default:
          return ''
      }
    }

    const selectedDate = useMemo(() => {
      if (!days) return

      const tomorrow: string = dayjs().add(1, 'day').format(DATE_FORMAT.PATIENT_TRACKER_DATE)
      let nextActiveDate: Dayjs | null = null

      for (
        let date: string = tomorrow;
        date in days;
        date = dayjs(date).add(1, 'day').format(DATE_FORMAT.PATIENT_TRACKER_DATE)
      ) {
        if (days[date].active === 1) {
          nextActiveDate = dayjs(date)
          break
        }
      }

      return nextActiveDate
    }, [days])

    const onChangePickerDate = (date: string): void => {
      if (currentDay !== date) handleChangeDate(date)
    }

    const dateRender = (date: Dayjs): JSX.Element => {
      const isFormattedDate = date.format(DATE_FORMAT.PATIENT_TRACKER_DATE)
      const bookingStatus = getBookingStatus(date)

      const isActiveDay = activeDays.some((day) => dayjs(day).isSame(date, 'day'))

      const isCurrentActiveDay =
        currentDay === date.format(DATE_FORMAT.PATIENT_TRACKER_DATE) && showCurrentDay

      return (
        <>
          {isLoading ? (
            <div>
              <Skeleton.Avatar active size='large' shape='circle' />
            </div>
          ) : (
            <div
              onClick={() => {
                onChangePickerDate(isFormattedDate)
              }}
              className={classNames('ant-picker-cell-inner', {
                activeDay: selectedDays.includes(isFormattedDate),
                'booking-availability': isFormattedDate,
                day: isFormattedDate,
                [bookingStatus]: isFormattedDate,
                currentActiveDay: isCurrentActiveDay || isActiveDay,
              })}>
              {date.date()}
            </div>
          )}
        </>
      )
    }

    const isDisableDate = (date: Dayjs): boolean => {
      if (disabledCells) return true

      if (!Object.keys(days).length) return true

      const isInvalidCurrentDate = !Boolean(days[date.format(DATE_FORMAT.PATIENT_TRACKER_DATE)])
      const isNotActiveCurrentDate =
        days[date.format(DATE_FORMAT.PATIENT_TRACKER_DATE)]?.active === 0

      if (
        (isStrictDisable && isInvalidCurrentDate) ||
        // !checkActiveStatus(date) ||
        isNotActiveCurrentDate
      ) {
        return true
      } else if (isStrictDisable && !isInvalidCurrentDate) {
        return false
      }

      // if (date.isBefore(dayjs().startOf('month'))) {
      //   return true
      // }

      return false
    }

    const checkActiveStatus = (date: Dayjs): boolean => {
      const targetMonth = date.month() + 1
      const targetYear = date.year()
      const currentMonth = dayjs(currentDay).month() + 1
      const currentYear = dayjs(currentDay).year()

      if (targetMonth === currentMonth && targetYear === currentYear) {
        return true
      }

      for (const key in days) {
        const objDate = dayjs(key)
        const objMonth = objDate.month() + 1

        if (objMonth === targetMonth) {
          return true
        }
      }

      return false
    }

    useEffect(() => {
      handleChangeDate(currentDay)
    }, [])

    return (
      <div className='calendar'>
        <DatePicker
          open
          autoFocus
          dateRender={dateRender}
          minDate={minDate}
          maxDate={maxDate}
          value={withFakeRerender ? null : selectedDate}
          onPanelChange={(date: Dayjs, type: string) => {
            if (type === 'date') {
              handleChangeMonth(null, date.format('YYYY-MM'))
            }
          }}
          disabledDate={isDisableDate}
          prevIcon={<img src={prevIcon} alt={ALT_CONSTANTS.ACTION_ICON} />}
          nextIcon={<img src={nextIcon} alt={ALT_CONSTANTS.ACTION_ICON} />}
          getPopupContainer={(trigger: any) => trigger.parentNode}
        />
      </div>
    )
  },
)
