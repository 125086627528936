import './mailbox-profile.scss'

import { useMemo, useRef } from 'react'
import { shallowEqual } from 'react-redux'

import { Col, Row, Tooltip } from 'antd'
import classNames from 'classnames'
import { Form, Formik } from 'formik'

import { useCartMonitor } from 'app/providers'

import { Button } from 'common/components/Button/Button'
import { FormControl } from 'common/components/FormItems/FormControl/FormControl'
import { Show } from 'common/components/Show/Show'
import { BUTTON_CONSTANTS } from 'common/constants/buttonConstants'
import { FORM_CONTROL_TYPE } from 'common/constants/formControlConstants'
import { INPUT_TYPES } from 'common/constants/inputConstants'
import { useFetchStatesQuery } from 'common/store/api/rootApi'

import { mailboxSchema } from 'features/SettingsOld/schemas/settingsMailboxSchema'

import { MailboxProfileText } from './profile.constants'
import type { MailboxProfileType } from './types'

const initialValues: MailboxProfileType = {
  applicant_initials: '',
  applicant_home_address: '',
  applicant_home_city: '',
  applicant_home_state: null,
  applicant_home_zip: '',
  applicant_home_phone_number: '',
  business_name: '',
  business_address: '',
  business_city: '',
  business_state: null,
  business_zip: '',
  business_phone_number: '',
  business_type: '',
  is_forward_mailbox: false,
  forwarding_address: '',
  forwarding_city: '',
  forwarding_state: null,
  forwarding_zip: '',
  forwarding_phone_number: '',
}

interface MailboxFormProps {
  onSubmit: any
  containerClassName?: string
  data?: MailboxProfileType
  isLoading?: boolean
  verifyInitialValues?: boolean
}

const MailboxForm = ({
  onSubmit,
  containerClassName,
  data,
  isLoading: isLoadingData = false,
  verifyInitialValues = true,
}: MailboxFormProps) => {
  const formRef = useRef(null)
  const { data: states, isLoading: isFetchingStates } = useFetchStatesQuery()
  const { isFetchingCart } = useCartMonitor()

  const initialData = useMemo(() => {
    if (data) return data
    return initialValues
  }, [data, initialValues])

  const isLoading = isFetchingStates || isLoadingData || isFetchingCart

  return (
    <section className={classNames('mailbox-container', containerClassName)}>
      <Formik
        innerRef={formRef}
        validateOnBlur
        initialValues={initialData}
        onSubmit={onSubmit}
        validationSchema={mailboxSchema}
        enableReinitialize>
        {({ values }) => {
          const isSubmitDisabled = verifyInitialValues && shallowEqual(initialData, values)

          return (
            <Form>
              <h2 className='section-margin'>{MailboxProfileText.APPLICANT}</h2>
              <Row gutter={[32, 16]}>
                <Col xs={24} md={12}>
                  <FormControl
                    isLoading={isLoading}
                    maxLength={3}
                    type={INPUT_TYPES.TEXT}
                    control={FORM_CONTROL_TYPE.INPUT}
                    required
                    label={MailboxProfileText.APPLICANT_INITIALS}
                    name='applicant_initials'
                  />
                </Col>
                <Col xs={24} md={12}>
                  <FormControl
                    isLoading={isLoading}
                    type={INPUT_TYPES.TEXT}
                    control={FORM_CONTROL_TYPE.INPUT}
                    required
                    label={
                      <Tooltip
                        title={`${MailboxProfileText.APPLICANT_HOME_ADDRESS} ${MailboxProfileText.NO_STREET_ADDRESS}`}>
                        <span>{MailboxProfileText.APPLICANT_HOME_ADDRESS}</span>
                      </Tooltip>
                    }
                    name='applicant_home_address'
                  />
                </Col>
                <Col xs={24} md={12}>
                  <FormControl
                    isLoading={isLoading}
                    type={INPUT_TYPES.TEXT}
                    control={FORM_CONTROL_TYPE.INPUT}
                    required
                    label={MailboxProfileText.CITY}
                    name='applicant_home_city'
                  />
                </Col>
                <Col xs={24} md={12}>
                  <FormControl
                    isLoading={isLoading}
                    type={INPUT_TYPES.TEXT}
                    control={FORM_CONTROL_TYPE.SELECT}
                    required
                    label={MailboxProfileText.STATE}
                    name='applicant_home_state'
                    options={states}
                  />
                </Col>
                <Col xs={24} md={12}>
                  <FormControl
                    isLoading={isLoading}
                    type={INPUT_TYPES.TEXT}
                    control={FORM_CONTROL_TYPE.INPUT}
                    required
                    label={MailboxProfileText.ZIP}
                    name='applicant_home_zip'
                  />
                </Col>
                <Col xs={24} md={12}>
                  <FormControl
                    isLoading={isLoading}
                    type={INPUT_TYPES.TEXT}
                    control={FORM_CONTROL_TYPE.PHONE}
                    required
                    label={
                      <Tooltip
                        title={`${MailboxProfileText.APPLICATION_PHONE} ${MailboxProfileText.INCLUDING_AREA_CODE}`}>
                        <span>{MailboxProfileText.APPLICATION_PHONE}</span>
                      </Tooltip>
                    }
                    name='applicant_home_phone_number'
                  />
                </Col>
              </Row>

              <h2 className='section-margin'>Business</h2>
              <Row gutter={[32, 16]}>
                <Col xs={24} md={12}>
                  <FormControl
                    isLoading={isLoading}
                    type={INPUT_TYPES.TEXT}
                    control={FORM_CONTROL_TYPE.INPUT}
                    required
                    label={MailboxProfileText.NAME_OF_CORPORATION}
                    name='business_name'
                  />
                </Col>
                <Col xs={24} md={12}>
                  <FormControl
                    isLoading={isLoading}
                    type={INPUT_TYPES.TEXT}
                    control={FORM_CONTROL_TYPE.INPUT}
                    required
                    label={
                      <Tooltip
                        title={`${MailboxProfileText.BUSINESS_ADDRESS} ${MailboxProfileText.NO_STREET_ADDRESS}`}>
                        <span>{MailboxProfileText.BUSINESS_ADDRESS}</span>
                      </Tooltip>
                    }
                    name='business_address'
                  />
                </Col>
                <Col xs={24} md={12}>
                  <FormControl
                    isLoading={isLoading}
                    type={INPUT_TYPES.TEXT}
                    control={FORM_CONTROL_TYPE.INPUT}
                    required
                    label={MailboxProfileText.CITY}
                    name='business_city'
                  />
                </Col>
                <Col xs={24} md={12}>
                  <FormControl
                    isLoading={isLoading}
                    type={INPUT_TYPES.TEXT}
                    control={FORM_CONTROL_TYPE.SELECT}
                    required
                    label={MailboxProfileText.STATE}
                    name='business_state'
                    options={states}
                  />
                </Col>
                <Col xs={24} md={12}>
                  <FormControl
                    isLoading={isLoading}
                    type={INPUT_TYPES.TEXT}
                    control={FORM_CONTROL_TYPE.INPUT}
                    required
                    label={MailboxProfileText.ZIP}
                    name='business_zip'
                  />
                </Col>
                <Col xs={24} md={12}>
                  <FormControl
                    isLoading={isLoading}
                    type={INPUT_TYPES.TEXT}
                    control={FORM_CONTROL_TYPE.PHONE}
                    required
                    label={
                      <Tooltip
                        title={`${MailboxProfileText.BUSINESS_PHONE} ${MailboxProfileText.INCLUDING_AREA_CODE}`}>
                        <span>{MailboxProfileText.BUSINESS_PHONE}</span>
                      </Tooltip>
                    }
                    name='business_phone_number'
                  />
                </Col>
              </Row>

              <Row className='section-margin' gutter={[32, 16]}>
                <Col xs={24} md={12}>
                  <FormControl
                    isLoading={isLoading}
                    type={INPUT_TYPES.TEXT}
                    control={FORM_CONTROL_TYPE.INPUT}
                    required
                    label={MailboxProfileText.TYPE_OF_BUSINESS}
                    name='business_type'
                  />
                </Col>
              </Row>

              <Row className='section-margin'>
                <Col>
                  <FormControl
                    control={FORM_CONTROL_TYPE.CHECKBOX}
                    required
                    label={MailboxProfileText.FORWARDING_MAILBOX}
                    name='is_forward_mailbox'
                  />
                </Col>
              </Row>
              <Show when={values.is_forward_mailbox}>
                <h2 className='section-margin'>{MailboxProfileText.FORWARDING_MAILBOX}</h2>
                <Row gutter={[32, 16]}>
                  <Col xs={24} md={12}>
                    <FormControl
                      isLoading={isLoading}
                      type={INPUT_TYPES.TEXT}
                      control={FORM_CONTROL_TYPE.INPUT}
                      required
                      label={
                        <Tooltip
                          title={`${MailboxProfileText.FORWARDING_ADDRESS} ${MailboxProfileText.NO_STREET_ADDRESS}`}>
                          <span>{MailboxProfileText.FORWARDING_ADDRESS}</span>
                        </Tooltip>
                      }
                      name='forwarding_address'
                    />
                  </Col>
                  <Col xs={24} md={12}>
                    <FormControl
                      isLoading={isLoading}
                      type={INPUT_TYPES.TEXT}
                      control={FORM_CONTROL_TYPE.INPUT}
                      required
                      label={MailboxProfileText.CITY}
                      name='forwarding_city'
                    />
                  </Col>
                  <Col xs={24} md={12}>
                    <FormControl
                      isLoading={isLoading}
                      type={INPUT_TYPES.TEXT}
                      control={FORM_CONTROL_TYPE.SELECT}
                      required
                      label={MailboxProfileText.STATE}
                      name='forwarding_state'
                      options={states}
                    />
                  </Col>
                  <Col xs={24} md={12}>
                    <FormControl
                      isLoading={isLoading}
                      type={INPUT_TYPES.TEXT}
                      control={FORM_CONTROL_TYPE.INPUT}
                      required
                      label={MailboxProfileText.ZIP}
                      name='forwarding_zip'
                    />
                  </Col>
                  <Col xs={24} md={12}>
                    <FormControl
                      isLoading={isLoading}
                      type={INPUT_TYPES.TEXT}
                      control={FORM_CONTROL_TYPE.PHONE}
                      required
                      label={
                        <Tooltip
                          title={`${MailboxProfileText.FORWARDING_PHONE} ${MailboxProfileText.INCLUDING_AREA_CODE}`}>
                          <span>{MailboxProfileText.FORWARDING_PHONE}</span>
                        </Tooltip>
                      }
                      name='forwarding_phone_number'
                    />
                  </Col>
                </Row>
              </Show>

              <Button
                className='section-margin'
                data-extra={true}
                htmlType='submit'
                disabled={isSubmitDisabled || isLoading}>
                {BUTTON_CONSTANTS.SAVE}
              </Button>
            </Form>
          )
        }}
      </Formik>
    </section>
  )
}

export default MailboxForm
