import { NavLink } from 'react-router-dom'

import { PATH_SEGMENT } from 'routes/pathSegments'

import { TagStatus } from 'common/components/TagStatus/TagStatus'
import type { IOption } from 'common/interfaces/IOption'
import { UtilService } from 'common/services/utilService'
import type { TAG_STATUS } from 'common/types/tagStatusType'

import type { ITicket } from 'features/Support/interfaces/ISupport'

export const SUPPORT_TABLE_COLUMNS = [
  {
    title: 'ID',
    dataIndex: 'ticket_id',
    key: 'ticket_id',
    editable: false,
    fixed: 'left',
    render: (id: string) => (
      <NavLink to={`${PATH_SEGMENT.TICKET_DETAILS}/${id}`} className='navigation-link'>
        #{id}
      </NavLink>
    ),
    sorter: (a: ITicket, b: ITicket) => Number(a.ticket_id) - Number(b.ticket_id),
  },
  {
    title: 'Category',
    dataIndex: 'category',
    key: 'category',
    editable: false,
    render: (category: IOption) => category.name,
    sorter: (a: ITicket, b: ITicket) => a.category?.name.localeCompare(b.category?.name),
  },

  {
    title: 'Subject',
    dataIndex: 'subject',
    key: 'subject',
    editable: false,
    sorter: (a: ITicket, b: ITicket) => a.subject.localeCompare(b.subject),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    editable: false,
    sorter: (a: ITicket, b: ITicket) => a.status.name.localeCompare(b.status.name),
    render: ({ name }: IOption) => <TagStatus status={name as TAG_STATUS} />,
  },
  {
    title: 'Last Update',
    dataIndex: 'last_update',
    key: 'last_update',
    editable: false,
    sorter: (a: ITicket, b: ITicket) => Date.parse(a.last_update) - Date.parse(b.last_update),
    render: (last_update: string) => UtilService.formatTableDate(last_update),
  },
]
