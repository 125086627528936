/* eslint-disable @typescript-eslint/no-unused-vars */
import type { FC } from 'react'
import { useEffect, useMemo, useState } from 'react'

import type { TimeClockProps } from '@mui/x-date-pickers'
import { Select } from 'antd'
import classNames from 'classnames'
import {
  addMinutes,
  format,
  getHours,
  getMinutes,
  isBefore,
  isEqual,
  isValid,
  parse,
  setHours,
  setMinutes,
} from 'date-fns'

import { TIME_FORMAT } from 'common/constants/timeFormat'
import { RegexService } from 'common/services/regexService'

import { INFO_CONSTANTS } from 'features/Home/constants/infoConstants'
import type { AppointmentDate } from 'features/Home/interfaces/IInfoSchedule'

import styles from './TimePickerBySlots.module.scss'

interface IProps extends TimeClockProps<Date> {
  index?: string
  options: any[]
  date: string
  appointment?: AppointmentDate
  onUpdateField: (value: AppointmentDate) => void
  onFieldChange?: (index: string, value: string[]) => void
  setFieldError?: Function
  fieldName?: string
}

export const TimePickerBySlots: FC<IProps> = ({
  options,
  appointment,
  date,
  onUpdateField,
  setFieldError,
  fieldName,
  ...rest
}) => {
  const [selectedStartTime, setSelectedStartTime] = useState<string | null>(null)
  const [selectedEndTime, setSelectedEndTime] = useState<string | null>(null)
  const [customTime, setCustomTime] = useState<string | null>(null)
  const [startOpen, setStartOpen] = useState(false)
  const [endOpen, setEndOpen] = useState(false)
  const { disabled } = rest

  useEffect(() => {
    const handleScroll = () => {
      setStartOpen(false)
      setEndOpen(false)
    }

    const modal = document.querySelector('.ant-modal-wrap')
    const elementToCheck = modal || window

    elementToCheck.addEventListener('scroll', handleScroll)
    return () => {
      elementToCheck.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    if (appointment?.length) {
      if (appointment[0] && isValid(appointment[0])) {
        setSelectedStartTime(format(appointment[0], TIME_FORMAT.TWELVE_HOUR_FORMAT).toString())
      }
      if (appointment[1] && isValid(appointment[1])) {
        setSelectedEndTime(format(appointment[1], TIME_FORMAT.TWELVE_HOUR_FORMAT).toString())
      }
    }
    if (!appointment) {
      setSelectedStartTime(null)
      setSelectedEndTime(null)
    }
  }, [appointment])

  const roundUpTime = (timeStr: string): string => {
    if (!timeStr) return
    const time = parse(timeStr, 'HH:mm', new Date())
    let minutes = time.getMinutes()

    const remainder = minutes % 5
    if (remainder !== 0) {
      minutes += 5 - remainder
    }

    let roundedTimeToFix = addMinutes(time, minutes - time.getMinutes())

    return format(roundedTimeToFix, 'HH:mm')
  }

  const generateTimeSlots = () => {
    if (!options.length) {
      return []
    }
    const result: Date[] = []

    options.forEach((option, i) => {
      if (i === options.length - 1) return
      let startTime = roundUpTime(option?.end_time)
      let endTime = options?.[i + 1]?.start_time
      const [startHour, startMinute] = startTime.split(':').map(Number)
      const [endHour, endMinute] = endTime.split(':').map(Number)
      let current = setHours(setMinutes(new Date(date), startMinute), startHour)
      const end = setHours(setMinutes(new Date(date), endMinute), endHour)
      while (isBefore(current, end) || isEqual(current, end)) {
        result.push(new Date(current))
        current = addMinutes(current, 5)
      }
    })

    const startTime = options[0].end_time
    const [currentHour, currentMinute] = startTime.split(':').map(Number)

    const firstOption = setHours(setMinutes(new Date(date), currentMinute), currentHour)

    // Check if current hour and minute are different from the first element in the result array
    const isCurrentTimeDifferent =
      result[0] && getHours(result[0]) !== currentHour && getMinutes(result[0]) !== currentMinute

    if (isCurrentTimeDifferent) {
      result.unshift(new Date(firstOption)) // Add current time as first option
    }

    return result
  }
  const timeSlots = generateTimeSlots()

  // Removes spaces and converts the time to 12 hour format if it is in 24 hour format from  the custom input
  const formatTimeInput = (inputTime: string): string | null => {
    const cleanedTime = inputTime.replace(RegexService.removeSpaces(), '').toUpperCase()

    const format12HourTime = (match: RegExpExecArray) => {
      const hours = parseInt(match[1], 10)
      const minutes = match[2]
      const ampm = match[3]
      return `${hours}:${minutes} ${ampm}`
    }

    const format24HourTime = (match: RegExpExecArray) => {
      let hours = parseInt(match[1], 10)
      const minutes = match[2]
      const ampm = hours >= 12 ? 'PM' : 'AM'
      if (hours > 12) hours -= 12
      return `${hours}:${minutes} ${ampm}`
    }

    const twelveHourFormatMatch = RegexService.timeFormat12Hour().exec(cleanedTime)
    if (twelveHourFormatMatch) {
      return format12HourTime(twelveHourFormatMatch)
    }

    const twentyFourHourFormatMatch = RegexService.timeFormat24Hour().exec(cleanedTime)
    if (twentyFourHourFormatMatch) {
      return format24HourTime(twentyFourHourFormatMatch)
    }

    return null
  }

  const formattedTimeSlots = timeSlots.map((timeSlot) => {
    return format(timeSlot, TIME_FORMAT.TWELVE_HOUR_FORMAT).toString()
  })

  const startTimeOptions = useMemo(() => {
    if (selectedEndTime) {
      const endIndex = formattedTimeSlots.indexOf(selectedEndTime)
      if (endIndex !== -1) {
        return formattedTimeSlots.slice(0, endIndex)
      }
    }
    return formattedTimeSlots.slice(0, -1)
  }, [selectedEndTime, formattedTimeSlots])

  const getEndTimeOptions = (startTime: string | null) => {
    if (startTime) {
      const index = formattedTimeSlots.indexOf(startTime)
      if (index !== -1) {
        return formattedTimeSlots.slice(index + 1)
      }
    }
    return formattedTimeSlots.slice(1)
  }

  const convertToISODate = (timeString: string): string => {
    if (typeof timeString !== 'string') {
      setFieldError && setFieldError(fieldName, INFO_CONSTANTS.INVALID_TIME)
      return
    }
    const [hoursPart, minutesPart, ampm] = timeString.split(RegexService.timeSplitter())
    let hours = parseInt(hoursPart, 10)
    const minutes = parseInt(minutesPart, 10)

    if (ampm === 'PM' && hours !== 12) {
      hours += 12
    } else if (ampm === 'AM' && hours === 12) {
      hours = 0
    }

    const dateTime = new Date() //@todo Fix the date/timezone here | old new Date(date) was returning the previous day

    dateTime.setHours(hours, minutes)

    return dateTime.toISOString()
  }
  const handleStartTimeChange = (value: string) => {
    const endTime = appointment?.[1] ? new Date(appointment[1]) : null

    if (!value) {
      setSelectedStartTime(null)
      onUpdateField([null, endTime])
    }

    if (typeof value !== 'string') {
      return
    }

    if (isValid(value) && !formattedTimeSlots.includes(value)) {
      formattedTimeSlots.push(value)
      formattedTimeSlots.sort()
    }
    setSelectedStartTime(value)
    const startTimeISO = convertToISODate(value)
    onUpdateField([new Date(startTimeISO), endTime])
  }

  const handleEndTimeChange = (value: string | null) => {
    const startTime = selectedStartTime ? new Date(convertToISODate(selectedStartTime)) : null

    if (!value) {
      setSelectedEndTime(null)
      onUpdateField([startTime, null])
    }

    if (typeof value !== 'string') {
      return
    }

    if (isValid(value) && !formattedTimeSlots.includes(value)) {
      formattedTimeSlots.push(value)
      formattedTimeSlots.sort()
    }

    setSelectedEndTime(value)
    const endTimeISO = convertToISODate(value)
    onUpdateField([startTime, new Date(endTimeISO)])
  }

  const endTimeOptions = getEndTimeOptions(selectedStartTime)

  const handleCustomTimeInput = (value: string, isStartTime: boolean) => {
    const formattedTime = formatTimeInput(value)

    if (!formattedTime || !formattedTimeSlots.includes(formattedTime)) {
      if (formattedTime) {
        if (isStartTime) {
          handleStartTimeChange(null)
          return
        }

        handleEndTimeChange(null)
      }
      return
    }

    if (isStartTime) {
      handleStartTimeChange(formattedTime)
    } else {
      handleEndTimeChange(formattedTime)
    }
  }

  return (
    <div
      className={classNames(styles.parent, {
        [styles.parentIconContainerDisabled]: disabled,
      })}>
      <Select
        bordered={false}
        showAction={['click', 'focus']}
        showSearch
        placeholder='Start time'
        className={styles.selectContainer}
        options={startTimeOptions.map((time) => ({
          value: time,
          label: time,
        }))}
        onChange={handleStartTimeChange}
        onSearch={(value) => {
          handleCustomTimeInput(value, true)
          setCustomTime(value)
        }}
        disabled={disabled}
        value={selectedStartTime}
        open={startOpen}
        onDropdownVisibleChange={(isOpen: boolean) => setStartOpen(isOpen)}
        notFoundContent={
          <div
            style={{
              color: '#272322',
              fontWeight: 500,
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              height: '100%',
            }}>
            {customTime}
          </div>
        }
        data-cy={`${fieldName}-start-time`}
      />
      <span className={styles.parentIconContainer}>—</span>
      <Select
        showAction={['click', 'focus']}
        bordered={false}
        showSearch
        placeholder='End time'
        className={styles.selectContainer}
        options={endTimeOptions.map((time) => ({ value: time, label: time }))}
        onChange={handleEndTimeChange}
        onSearch={(value) => {
          handleCustomTimeInput(value, false)
          setCustomTime(value)
        }}
        open={endOpen}
        onDropdownVisibleChange={(isOpen: boolean) => setEndOpen(isOpen)}
        disabled={disabled}
        value={selectedEndTime}
        notFoundContent={
          <div
            style={{
              color: '#272322',
              fontWeight: 500,
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              height: '100%',
            }}>
            {customTime}
          </div>
        }
        data-cy={`${fieldName}-end-time`}
      />
    </div>
  )
}
