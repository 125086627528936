import type { FC } from 'react'

import type { ButtonProps } from 'antd/es/button/button'
import classNames from 'classnames'

import { ReactComponent as CartIcon } from 'assets/icons/cart.svg'

import { Button } from 'common/components/Button/Button'
import { Show } from 'common/components/Show/Show'
import { BUTTON_CONSTANTS, BUTTON_PROPORTION } from 'common/constants/buttonConstants'
import { UtilService } from 'common/services/utilService'

import styles from './addToCartButton.module.scss'

const { numberToDollar } = UtilService

interface IProps extends ButtonProps {
  price: number
}

export const AddToCartButton: FC<IProps> = ({ price, ...rest }) => {
  const isFree = price === 0
  return (
    <Button {...rest} proportion={BUTTON_PROPORTION.LARGE}>
      <div className={styles.parent}>
        {BUTTON_CONSTANTS.ADD_TO_CART}
        <span>
          <CartIcon
            width={20}
            height={20}
            className={classNames(styles.parentIcon, {
              [styles.parentIconDisabled]: rest.disabled,
            })}
          />
          <Show when={isFree} fallback={numberToDollar(price)}>
            {BUTTON_CONSTANTS.FREE}
          </Show>
        </span>
      </div>
    </Button>
  )
}
