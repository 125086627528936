import type { FC } from 'react'
import { useState } from 'react'

import { Col, Row, Skeleton } from 'antd'
import classNames from 'classnames'

import { Button } from 'common/components/Button/Button'
import { ModalAction } from 'common/components/Modal'
import { Show } from 'common/components/Show/Show'
import { TagStatus } from 'common/components/TagStatus/TagStatus'
import { ALT_CONSTANTS } from 'common/constants/altConstants'
import { BUTTON_CONSTANTS } from 'common/constants/buttonConstants'
import { MODAL_LABELS } from 'common/constants/modalConstants'
import { useApiResponse } from 'common/hooks/useApiResponse'
import type { ApiResponse, ITriggerRequest } from 'common/interfaces/IRequestResponse'
import { INFO_CONSTANTS } from 'features/Home/constants/infoConstants'
import { useTerminateLeaseMutation } from 'features/Home/Book/state/api/bookApi'

import { CancelLeaseNoteModal } from 'features/Home/Book/Booking/BookingDetails/CancelLeaseNoteModal/CancelLeaseNoteModal'
import styles from './bookingHeader.module.scss'
import type { BookingHeaderProps } from './bookingHeader.types'
import { useBooking } from 'common/hooks/useBooking'
import { useParams } from 'react-router-dom'

export const BookingHeader: FC<BookingHeaderProps> = ({ isLoading, booking }) => {
  const [isConfirmModalOpened, setConfirmModalOpened] = useState(false)
  const [isNoteModalOpened, setNoteModalOpened] = useState(false)

  const { bookingId, siteId } = useParams()
  const { isStorage, isRecurring, isMailbox } = useBooking(booking)

  const [terminateLease]: ITriggerRequest = useTerminateLeaseMutation()
  const { processApiResponse } = useApiResponse()

  const handleCancelLease = async (): Promise<void> => {
    setConfirmModalOpened(false)
    const response: ApiResponse = await terminateLease({
      id: booking.code,
      siteId,
    })
    processApiResponse(response, {
      success: 'Cancel booking success',
      error: 'Cancel booking error',
      successCallback: () => {
        setNoteModalOpened(true)
      },
    })
  }

  const onHideNoteModal = (): void => {
    setNoteModalOpened(false)
  }

  if (isLoading)
    return (
      <Skeleton.Image
        active
        className={classNames(styles.imageSkeleton, {
          [styles.imageSkeletonLarge]: isStorage || isRecurring || isMailbox,
        })}
      />
    )

  return (
    <div
      className={classNames(styles.header, {
        [styles.headerLarge]: isStorage || isRecurring || isMailbox,
      })}>
      <img
        src={booking?.image}
        className={styles.headerImage}
        alt={ALT_CONSTANTS.ACTIVE_BOOKING_IMAGE}
      />
      <Row className={styles.headerContent} justify='space-between'>
        <Col xs={24} sm={12} className={styles.headerLeftSide}>
          <p className={styles.headerLeftSideTitle}>{INFO_CONSTANTS.BOOKING_NUMBER}</p>
          <p className={styles.headerLeftSideSubTitle}>{booking?.code || bookingId}</p>
          <Show when={isStorage}>
            <p className={styles.headerLeftSideTitleBottom}>
              {booking?.no_of_units} x {booking?.unit_info}
            </p>
          </Show>
          <Show when={isRecurring || isMailbox}>
            <p className={styles.headerLeftSideTitleBottom}>
              {booking?.lease_type_name ?? 'N/A'}
              <span className={styles.headerLeftSideSubTitle}>
                {booking?.hardware_identifier || booking?.box_number}
              </span>
            </p>
          </Show>
        </Col>
        <Col xs={24} sm={12} className={styles.headerRightSide}>
          <TagStatus status={booking?.status} isLarge />
          <Show
            when={
              (isStorage || isRecurring) &&
              booking?.status === 'Active' &&
              booking?.enable_request_complete
            }>
            <Button
              className={styles.headerCancelLease}
              onClick={() => setConfirmModalOpened(true)}>
              {BUTTON_CONSTANTS.REQUEST_TO_COMPLETE}
            </Button>
          </Show>
          <p className={styles.headerRightSideTitle}>{booking?.site_name}</p>
        </Col>
      </Row>

      <ModalAction
        isOpen={isConfirmModalOpened}
        title={MODAL_LABELS.REQUEST_TO_COMPLETE}
        confirmText={BUTTON_CONSTANTS.YES}
        cancelText={BUTTON_CONSTANTS.NO}
        onConfirm={handleCancelLease}
        onClose={() => setConfirmModalOpened(false)}
        swapButtons
        description={
          <>
            {INFO_CONSTANTS.ARE_YOU_SURE_TERMINATE_LEASE}{' '}
            <span className={styles.headerLeaseCode}>{booking?.code}</span>?
          </>
        }
      />

      <CancelLeaseNoteModal isVisible={isNoteModalOpened} onHide={onHideNoteModal} />
    </div>
  )
}
