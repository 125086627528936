import classNames from 'classnames'

import { useAuth } from 'app/providers'

import { Button } from 'common/components/Button/Button'

import { AUTH_VALIDATE_CONSTANTS } from 'features/Auth/constants/authValidateConstants'

import styles from './authLogout.module.scss'

export const AuthLogout = () => {
  const { logout } = useAuth()
  return (
    <Button onClick={logout} className={classNames('reset-button', styles.parent)}>
      {AUTH_VALIDATE_CONSTANTS.LOG_OUT}
    </Button>
  )
}
