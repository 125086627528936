import { type FC, type JSX, memo, useCallback, useEffect, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'

import type { UploadFile } from 'antd'
import { Col, Grid, Row, Spin } from 'antd'
import classNames from 'classnames'
import type { FormikValues } from 'formik'
import { Form, Formik } from 'formik'

import { useAuth } from 'app/providers'

import driverIcon from 'features/Auth/assets/icons/driver_license.svg'
import medicalIcon from 'features/Auth/assets/icons/medical_bag.svg'
import logo from 'features/Auth/assets/images/logo2.svg'

import { Button } from 'common/components/Button/Button'
import { FormControl } from 'common/components/FormItems/FormControl/FormControl'
import { Modal } from 'common/components/Modal'
import Popup from 'common/components/Popup'
import { Show } from 'common/components/Show/Show'
import { Spinner } from 'common/components/Spinner'
import { ALT_CONSTANTS } from 'common/constants/altConstants'
import { BUTTON_CONSTANTS, BUTTON_MODIFIER } from 'common/constants/buttonConstants'
import { FORM_CONTROL_TYPE } from 'common/constants/formControlConstants'
import { INPUT_TYPES } from 'common/constants/inputConstants'
import { POPUP_CONSTANTS } from 'common/constants/popupConstants'
import { useApiResponse } from 'common/hooks/useApiResponse'
import type { IOption } from 'common/interfaces/IOption'
import type {
  ApiResponse,
  IRequestResponse,
  ITriggerRequest,
} from 'common/interfaces/IRequestResponse'
import { useFetchStatesQuery } from 'common/store/api/rootApi'

import { AuthLogout } from 'features/Auth/components/AuthLogout/AuthLogout'
import { AUTH_CONSTANTS } from 'features/Auth/constants/authConstants'
import {
  AUTH_VALIDATE_IDENTITY_KEYS,
  INITIAL_VALIDATE_IDENTITY_DATA,
} from 'features/Auth/constants/authSchemaKeys'
import { AUTH_VALIDATE_CONSTANTS } from 'features/Auth/constants/authValidateConstants'
import type { IAuthValidation } from 'features/Auth/interfaces/IAuth'
import type { IUploadedValidationDocument } from 'features/Auth/interfaces/IAuthUploadedDocument'
import {
  AUTH_DRIVER_LICENSE_SCHEMA,
  AUTH_MEDICAL_LICENSE_SCHEMA,
  AUTH_VALIDATE_IDENTITY_SCHEMA,
} from 'features/Auth/schemas/authValidateIdentitySchema'
import { AuthService } from 'features/Auth/services/authService'
import {
  useFetchValidationInfoQuery,
  useRegisterSelectorQuery,
  useValidateIdentityMutation,
} from 'features/Auth/store/api/authApi'
import type { LICENSE_TYPE } from 'features/Auth/types/authLicenseType'
import { INFO_CONSTANTS } from 'features/Home/constants/infoConstants'

import styles from './authIdentity.module.scss'
import { PATH_SEGMENT } from 'routes/pathSegments'
import { PathUtils } from 'routes/routes.utils'

const { useBreakpoint } = Grid

const { getIsConfirmedDocument, getSpecificDocument, getUpdatedIdentity } = AuthService

const getCurrentValidationSchema = (documents: IUploadedValidationDocument[]) => {
  const isValidDriver = Boolean(getIsConfirmedDocument(documents, 'driver'))
  const isValidMedical = Boolean(getIsConfirmedDocument(documents, 'medical'))
  switch (true) {
    case isValidDriver && !isValidMedical:
      return AUTH_MEDICAL_LICENSE_SCHEMA
    case !isValidDriver && isValidMedical:
      return AUTH_DRIVER_LICENSE_SCHEMA
    default:
      return AUTH_VALIDATE_IDENTITY_SCHEMA
  }
}

export const AuthIdentity: FC = memo(() => {
  const { user, hasCompletedDocuments, hasIncompleteDocuments, updateDocuments } = useAuth()
  const [driverLicenseFiles, setDriverLicenseFiles] = useState<UploadFile[]>([])
  const [medicalLicenseFiles, setMedicalLicenseFiles] = useState<UploadFile[]>([])

  const navigate = useNavigate()
  const { processApiResponse } = useApiResponse()
  const [validateIdentity, { isLoading: isUploadingIdentity }]: ITriggerRequest =
    useValidateIdentityMutation()
  const { data: states, isLoading: isFetchingStates } = useFetchStatesQuery()
  const { data: stateSelectors }: IRequestResponse<IOption[]> = useRegisterSelectorQuery()
  const {
    data: validationInfo,
    isLoading: isFetchingValidationInfo,
    refetch: refetchValidationInfo,
  }: IRequestResponse<IAuthValidation> = useFetchValidationInfoQuery()
  const screens = useBreakpoint()
  const { documents = [] } = validationInfo ?? {}

  const isLoading = isFetchingValidationInfo || isFetchingStates

  useEffect(() => {
    refetchValidationInfo()
    updateDocuments(documents)
  }, [documents, refetchValidationInfo])

  const handleSubmitIdentity = async (values: FormikValues): Promise<void> => {
    let updatedData = getUpdatedIdentity(values)

    const response: ApiResponse = await validateIdentity(updatedData)
    processApiResponse(response, {
      error: 'Error validating identity',
      successCallback: (): void => {
        navigate(PATH_SEGMENT.ROOT)
      },
    })
  }

  const getIsInvalidDocument = (type: LICENSE_TYPE): boolean =>
    !getIsConfirmedDocument(documents, type) && Boolean(documents)
  const isValidDriver: boolean = Boolean(getIsConfirmedDocument(documents, 'driver'))
  const isValidMedical: boolean = Boolean(getIsConfirmedDocument(documents, 'medical'))

  const getIsUncompletedUploadedLicenses = ({
    driver_license,
    medical_license,
  }: FormikValues): boolean => {
    const licenses = [driver_license, medical_license].map((item) => item.path).flat()
    return licenses.some((license): boolean => !license.response?.file.path)
  }

  const reason = useCallback(
    (type: LICENSE_TYPE) => {
      const reason = getSpecificDocument(documents, type)?.reason

      if (!reason) {
        return null
      }

      return `${INFO_CONSTANTS.REASON} ${reason}`
    },
    [documents],
  )

  const driverReason = reason('driver')
  const medicalReason = reason('medical')

  if (user && hasCompletedDocuments) {
    return <Navigate to={PATH_SEGMENT.ROOT} />
  }

  if (user && hasIncompleteDocuments) {
    return <Navigate to={PathUtils.getValidationProcess()} />
  }

  return (
    <div className={styles.parent}>
      <Modal cancelButton={false} open={isUploadingIdentity}>
        <div className={styles.parentModalWrapper}>
          <Spinner isLoading />
          <h2 className={styles.parentModalTitle}>{AUTH_CONSTANTS.VALIDATING_DOCUMENTS}</h2>
        </div>
      </Modal>
      <div className={styles.parentContent}>
        <AuthLogout />

        <img className={styles.parentLogo} src={logo} alt={ALT_CONSTANTS.LOGO_ICON} />

        <h1 className={styles.parentTitle}>{AUTH_VALIDATE_CONSTANTS.VALIDATE_IDENTITY}</h1>
        <p className={styles.parentDescription}>{AUTH_VALIDATE_CONSTANTS.PLEASE_UPLOAD}</p>
        <div className={styles.parentUpload}>
          <Formik
            enableReinitialize
            validateOnChange
            validationSchema={getCurrentValidationSchema(documents)}
            initialValues={{
              ...INITIAL_VALIDATE_IDENTITY_DATA,
              ...(isFetchingStates ? {} : validationInfo || {}),
              site: validationInfo?.site || stateSelectors?.[0]?.code,
            }}
            onSubmit={(values: FormikValues) => {
              handleSubmitIdentity(values)
            }}>
            {({ values }): JSX.Element => {
              return (
                <Form className={styles.parentForm}>
                  <Spin size='large' spinning={isLoading}>
                    <section className={styles.parentSectionInfo}>
                      <div className={styles.parentInfoDetails}>
                        <FormControl
                          type={INPUT_TYPES.TEXT}
                          control={FORM_CONTROL_TYPE.INPUT}
                          isLoading={isLoading}
                          label={AUTH_VALIDATE_CONSTANTS.FIRST_NAME}
                          name={AUTH_VALIDATE_IDENTITY_KEYS.FIRST_NAME}
                          disabled={Boolean(validationInfo?.first_name)}
                        />
                        <FormControl
                          type={INPUT_TYPES.TEXT}
                          control={FORM_CONTROL_TYPE.INPUT}
                          isLoading={isLoading}
                          label={AUTH_VALIDATE_CONSTANTS.LAST_NAME}
                          name={AUTH_VALIDATE_IDENTITY_KEYS.LAST_NAME}
                          disabled={Boolean(validationInfo?.last_name)}
                        />
                      </div>
                      <FormControl
                        isLoading={isLoading}
                        control={FORM_CONTROL_TYPE.PHONE}
                        label={AUTH_VALIDATE_CONSTANTS.MOBILE_PHONE}
                        name={AUTH_VALIDATE_IDENTITY_KEYS.PHONE_NUMBER}
                        disabled={Boolean(validationInfo?.phone_number)}
                      />
                      <FormControl
                        type={INPUT_TYPES.TEXT}
                        isLoading={isLoading}
                        options={stateSelectors || []}
                        control={FORM_CONTROL_TYPE.SELECT}
                        label={AUTH_VALIDATE_CONSTANTS.LOCATION}
                        disabled={Boolean(validationInfo?.site)}
                        name={AUTH_VALIDATE_IDENTITY_KEYS.SITE_ID}
                      />
                    </section>
                    <Show when={getIsInvalidDocument('driver')}>
                      <section className={styles.parentSection}>
                        <h3 className={styles.parentSectionTitle}>
                          {AUTH_VALIDATE_CONSTANTS.DRIVER_LICENSE}
                        </h3>

                        <FormControl
                          isLoading={isLoading}
                          options={states || []}
                          type={INPUT_TYPES.TEXT}
                          control={FORM_CONTROL_TYPE.SELECT}
                          name={AUTH_VALIDATE_IDENTITY_KEYS.DRIVER_STATE}
                          placeholder={AUTH_VALIDATE_CONSTANTS.LICENSE_STATE}
                        />
                        <FormControl
                          isLoading={isLoading}
                          type={INPUT_TYPES.TEXT}
                          control={FORM_CONTROL_TYPE.INPUT}
                          name={AUTH_VALIDATE_IDENTITY_KEYS.DRIVER_NUMBER}
                          placeholder={AUTH_VALIDATE_CONSTANTS.LICENSE_NUMBER}
                        />
                        <Show when={!isValidDriver && driverReason}>
                          <p className={styles.parentErrorInform}>{driverReason}</p>
                        </Show>

                        <FormControl
                          isLoading={isLoading}
                          maxCount={1}
                          additional={driverIcon}
                          type={INPUT_TYPES.TEXT}
                          control={FORM_CONTROL_TYPE.UPLOAD}
                          name={AUTH_VALIDATE_IDENTITY_KEYS.DRIVER_FILE}
                          setUploadedFiles={setDriverLicenseFiles}
                          uploadedFiles={driverLicenseFiles}
                        />
                      </section>
                    </Show>

                    <Show when={getIsInvalidDocument('medical')}>
                      <section className={styles.parentSection}>
                        <h3 className={styles.parentSectionTitle}>
                          {AUTH_VALIDATE_CONSTANTS.MEDICAL_LICENSE}
                        </h3>
                        <FormControl
                          isLoading={isLoading}
                          options={states || []}
                          type={INPUT_TYPES.TEXT}
                          control={FORM_CONTROL_TYPE.SELECT}
                          name={AUTH_VALIDATE_IDENTITY_KEYS.MEDICAL_STATE}
                          placeholder={AUTH_VALIDATE_CONSTANTS.LICENSE_STATE}
                        />
                        <FormControl
                          isLoading={isLoading}
                          type={INPUT_TYPES.TEXT}
                          control={FORM_CONTROL_TYPE.INPUT}
                          name={AUTH_VALIDATE_IDENTITY_KEYS.MEDICAL_NUMBER}
                          placeholder={AUTH_VALIDATE_CONSTANTS.LICENSE_NUMBER}
                        />
                        <Show when={!isValidMedical && medicalReason}>
                          <p className={styles.parentErrorInform}>{medicalReason}</p>
                        </Show>

                        <FormControl
                          isLoading={isLoading}
                          maxCount={1}
                          type={INPUT_TYPES.TEXT}
                          additional={medicalIcon}
                          control={FORM_CONTROL_TYPE.UPLOAD}
                          name={AUTH_VALIDATE_IDENTITY_KEYS.MEDICAL_FILE}
                          setUploadedFiles={setMedicalLicenseFiles}
                          uploadedFiles={medicalLicenseFiles}
                        />
                      </section>
                    </Show>
                    <Row justify='space-between'>
                      <Col
                        xs={{ order: 2, flex: 24 }}
                        sm={{ order: 1, flex: 12 }}
                        className={classNames({ [styles.parentCenteredButton]: screens?.xs })}>
                        <Button
                          htmlType='button'
                          modifier={BUTTON_MODIFIER.DEFAULT}
                          onClick={() => {
                            Popup.showInfo(
                              {
                                title: POPUP_CONSTANTS.DOCTOR_LIMITED_TITLE,
                                message: POPUP_CONSTANTS.DOCTOR_LIMITED_DESCRIPTION,
                                mainButtonText: BUTTON_CONSTANTS.STAY_HERE,
                                secondButtonText: BUTTON_CONSTANTS.SKIP_FOR_NOW,
                              },
                              null,
                              () => {
                                navigate(PATH_SEGMENT.ROOT)
                              },
                            )
                          }}>
                          {AUTH_VALIDATE_CONSTANTS.UPLOAD_LATER}
                        </Button>
                      </Col>
                      <Col
                        xs={{ order: 1, flex: 24 }}
                        sm={{ order: 2, flex: 12 }}
                        className={classNames({ [styles.parentCenteredButton]: screens?.xs })}>
                        <Show when={!isFetchingValidationInfo}>
                          <div className={classNames({ [styles.parentSubmit]: !screens?.xs })}>
                            <Button
                              loading={getIsUncompletedUploadedLicenses(values)}
                              htmlType='submit'>
                              {AUTH_VALIDATE_CONSTANTS.UPLOAD_DOCUMENTS}
                            </Button>
                          </div>
                        </Show>
                      </Col>
                    </Row>
                  </Spin>
                </Form>
              )
            }}
          </Formik>
        </div>
      </div>
    </div>
  )
})
