import React, { type FC, memo, useEffect, useMemo } from 'react'
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom'

import { TabPanel } from 'common/components/TabPanel/TabPanel'

import { PATH_SEGMENT } from 'routes/pathSegments'
import { generateRouter, PathUtils } from 'routes/routes.utils'
import { Others } from './Others/Others'
import { Rooms } from './Rooms/Rooms'
import type { PromotionDetailsProps } from './promotionDetails.types'
import { PromotionContainer } from './PromotionContainer/PromotionContainer'
import { BOOKING_STATUS_CODE } from 'common/constants/bookingConstants'

export const PromotionDetails: FC<PromotionDetailsProps> = memo(
  ({ promotion, isLoading, isSuccess }) => {
    const location = useLocation()
    const navigate = useNavigate()
    const { siteId } = useParams()

    const tabs = useMemo(() => {
      return [
        {
          path: PATH_SEGMENT.ROOMS,
          title: 'Rooms',
        },

        {
          path: PATH_SEGMENT.OTHERS,
          title: 'Others',
        },
      ]
    }, [])

    const routesConfig = useMemo(
      () => ({
        [PATH_SEGMENT.ROOMS]: (
          <Rooms promotion={promotion} isLoading={isLoading} isSuccess={isSuccess} />
        ),
        [PATH_SEGMENT.OTHERS]: <Others />,
        [PATH_SEGMENT.ROOT]: <Navigate to={PATH_SEGMENT.ROOMS} replace />,
      }),
      [promotion],
    )

    const showMore = useMemo(() => {
      return location.pathname.includes(PATH_SEGMENT.ROOMS)
    }, [location.pathname])

    useEffect(() => {
      if (!promotion) {
        return
      }
      const isUnsigned = promotion?.status_code === BOOKING_STATUS_CODE.WAITING_TO_SIGN

      if (isUnsigned) {
        navigate(PathUtils.getPromotionContracts(siteId, promotion?.code))
      }
    }, [promotion?.status_code])

    return (
      <PromotionContainer
        displayCol
        isAdditionInfoFull
        promotion={promotion}
        isLoading={isLoading}
        showMedia={showMore}
        showAdditionInfo={showMore}>
        <TabPanel tabs={tabs} />
        {generateRouter(routesConfig)}
      </PromotionContainer>
    )
  },
)
