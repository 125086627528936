import type { FC, JSX } from 'react'

import type { FormikValues } from 'formik'
import { Form, Formik } from 'formik'

import { Button } from 'common/components/Button/Button'
import { FormControl } from 'common/components/FormItems/FormControl/FormControl'
import { BUTTON_CONSTANTS, BUTTON_MODIFIER } from 'common/constants/buttonConstants'
import type { IFormControl } from 'common/interfaces/IFormItem'

import { SUPPORT_CONSTANTS } from 'features/Support/constants/supportConstants'
import {
  INITIAL_TICKET_DATA,
  NEW_TICKET_FORM_ITEMS,
  SUPPORT_FORM_KEYS,
} from 'features/Support/constants/supportFormKeys'
import { NEW_TICKET_SCHEMA } from 'features/Support/schemas/supportNewTicketSchema'

import styles from './supportForm.module.scss'

interface IProps {
  adjustedCategories: string[]
  toggleIsOpenTicketModal: () => void
  handleSubmitTicket: (value: FormikValues, resetForm: () => void) => Promise<void>
}

export const SupportForm: FC<IProps> = ({
  adjustedCategories,
  handleSubmitTicket,
  toggleIsOpenTicketModal,
}) => {
  return (
    <Formik
      validateOnChange={false}
      initialValues={INITIAL_TICKET_DATA}
      validationSchema={NEW_TICKET_SCHEMA}
      onSubmit={(values: FormikValues, { resetForm }) => {
        handleSubmitTicket(values, resetForm)
      }}>
      {({ resetForm }): JSX.Element => {
        return (
          <Form className={styles.parent}>
            <p className={styles.parentDescription}>{SUPPORT_CONSTANTS.NEW_TICKET_DESCRIPTION}</p>
            {NEW_TICKET_FORM_ITEMS.map((formControl: IFormControl, index: number): JSX.Element => {
              if (formControl.name === SUPPORT_FORM_KEYS.CATEGORY)
                return <FormControl key={index} {...formControl} options={adjustedCategories} />
              return (
                <FormControl
                  {...formControl}
                  required
                  key={index}
                  label={formControl.name !== SUPPORT_FORM_KEYS.IMAGES && formControl.label}
                />
              )
            })}
            <div className={styles.parentFormSubmit}>
              <Button
                modifier={BUTTON_MODIFIER.DEFAULT}
                onClick={() => {
                  toggleIsOpenTicketModal()
                  resetForm()
                }}>
                {BUTTON_CONSTANTS.CANCEL}
              </Button>
              <Button htmlType='submit'>{BUTTON_CONSTANTS.SUBMIT}</Button>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}
