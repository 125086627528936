import { TABLE_ACTIONS } from 'common/constants/tableConstants'

export enum STAFF_LABELS {
  MY_STAFF = 'My Staff',
  CONFIRM_DELETE_STAFF = 'Are you sure you want to delete ',
  FROM_YOUR_TEAM = 'from your team?',
  REMOVE = 'Remove ',
  FROM_THIS_LEASE = ' from this booking: physical access will be denied and they will no longer have access to patient scheduling functionality',
  GIVEN_ACCESS = ' has been given access and has been notified via text and email',
}

export const STAFF_TABLE_ACTIONS = [TABLE_ACTIONS.DELETE, TABLE_ACTIONS.EDIT]
