import { type FC, memo } from 'react'

import styles from './longTermOption.module.scss'
import type { LongTermOptionProps } from './longTermOption.types'

export const LongTermOption: FC<LongTermOptionProps> = memo(({ option }) => {
  return (
    <details className={styles.parent}>
      <summary className={styles.parentSummary}>{option.option}</summary>
      <div className={styles.parentContent}>
        <div className={styles.parentOption}>
          <span className={styles.parentTimeInterval}>{option.time_interval}</span>
          <div>
            <span className={styles.parentIdentifierLabel}>Identifier:</span>
            <span className={styles.parentIdentifier}>{option.identifier}</span>
          </div>
        </div>
      </div>
    </details>
  )
})
