import { Button } from 'common/components/Button/Button'
import { Modal } from 'common/components/Modal'
import {
  BUTTON_CONSTANTS,
  BUTTON_MODIFIER,
  BUTTON_SEVERITY,
} from 'common/constants/buttonConstants'

import { INFO_CONSTANTS } from 'features/Home/constants/infoConstants'

import styles from './sendSMSConfirmation.module.scss'
import type { FC } from 'react'
import { memo } from 'react'
import type { SendSMSConfirmationProps } from './sendSMSConfirmation.types'

export const SendSMSConfirmation: FC<SendSMSConfirmationProps> = memo(
  ({ open, handleClose, handleConfirm }) => {
    return (
      <Modal
        open={open}
        onCancel={() => {}}
        title={INFO_CONSTANTS.NOTIFY_PATIENTS_WARNING_TITLE}
        cancelButton={false}>
        <div className={styles.parent}>
          <p className={styles.parentText}>{INFO_CONSTANTS.NOTIFY_PATIENTS_WARNING}</p>
          <div className={styles.parentActions}>
            <Button
              className={styles.parentActionsButton}
              onClick={handleConfirm}
              modifier={BUTTON_MODIFIER.PRIMARY}
              severity={BUTTON_SEVERITY.SUCCESS_FILLED}>
              {BUTTON_CONSTANTS.SEND}
            </Button>
            <Button
              className={styles.parentActionsButton}
              modifier={BUTTON_MODIFIER.DEFAULT}
              onClick={handleClose}>
              {BUTTON_CONSTANTS.CANCEL}
            </Button>
          </div>
        </div>
      </Modal>
    )
  },
)
