import { useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'

import { isSameDay } from 'date-fns'

import { usePusher } from 'app/providers/pusher/PusherProvider'

import { useAppSelector } from 'common/hooks/redux'

import type { PATIENT_STATUSES, ROOM_STATUS } from 'features/Home/constants/infoConstants'
import {
  PATIENT_STATUS_NAMES,
  PUSHER_EVENTS,
  ROOM_STATUS_NAMES,
  SMS_STATUSES,
} from 'features/Home/constants/infoConstants'
import type { IPatientRoom } from 'features/Home/interfaces/IInfoPatient'

import { useInfoManager } from './useInfoManager'

export const useInfoPusher = (): any => {
  const { channel, pusher } = usePusher()
  const { skipPusherEvents, rooms } = useAppSelector((state) => state.bookReducer)

  const { day, bookingId } = useParams()
  const {
    fetchInfoData,
    updateSkipEvents,
    insertSchedule,
    updateInfoSchedule,
    removeSchedule,
    updateScheduleLocation,
    updateScheduleStatus,
    updateInfoScheduleRoomStatus,
    removeInfoPatientToNotify,
  } = useInfoManager(day)
  const currentRooms = useRef(rooms)
  const currentSkipPusherEvents = useRef(skipPusherEvents)

  useEffect(() => {
    currentRooms.current = rooms
  }, [rooms])

  useEffect(() => {
    currentSkipPusherEvents.current = skipPusherEvents
  }, [skipPusherEvents])

  const massUpdate = (event: any) => {
    const { shouldUpdate, day } = event
    const shouldApplyUpdate = isSameDay(new Date(day), new Date(day))
    if (shouldApplyUpdate && shouldUpdate && !currentSkipPusherEvents.current) {
      fetchInfoData(false)
    }

    updateSkipEvents(false)
  }

  const patientUpdate = (event: any) => {
    const createdSchedule = event.data

    const room = currentRooms.current.find(
      (room: IPatientRoom) => room.value === createdSchedule.room,
    )
    const updatedEvent = { ...createdSchedule, room }
    if (createdSchedule.sms_notification !== SMS_STATUSES.UNCONFIRMED) {
      removeInfoPatientToNotify(createdSchedule.id)
    }
    updateInfoSchedule(updatedEvent)
    updateSkipEvents(false)
  }

  const patientCreate = (event: any) => {
    const createdSchedule = event.data
    if (
      isSameDay(new Date(createdSchedule.date), new Date(day)) &&
      !currentSkipPusherEvents.current
    ) {
      const room = currentRooms.current.find(
        (room: IPatientRoom) => room.value === createdSchedule.room,
      )

      insertSchedule({ ...createdSchedule, room })
    }

    updateSkipEvents(false)
  }

  const patientDelete = (event: any) => {
    const { appointment_id } = event
    removeSchedule(appointment_id)
    updateSkipEvents(false)
  }

  const patientLocationUpdate = (event: any) => {
    const { appointment_id, room_id, room_name } = event
    updateScheduleLocation(appointment_id, { id: room_id, name: room_name })
  }

  const patientStatusUpdate = (event: any) => {
    const { appointment_id, status_code, status_text, temperature } = event
    updateScheduleStatus(
      appointment_id,
      {
        code: status_code,
        name: PATIENT_STATUS_NAMES[status_code as PATIENT_STATUSES],
      },
      status_text,
      temperature,
    )
  }

  const roomStatusUpdate = (event: any) => {
    const { room_id, room_status_code } = event
    updateInfoScheduleRoomStatus(room_id, {
      code: room_status_code,
      name: ROOM_STATUS_NAMES[room_status_code as ROOM_STATUS],
    })
  }

  useEffect(() => {
    channel.bind(PUSHER_EVENTS.MASS_UPDATE, massUpdate)
    channel.bind(PUSHER_EVENTS.PATIENT_UPDATED, patientUpdate)
    channel.bind(PUSHER_EVENTS.PATIENT_CREATE, patientCreate)
    channel.bind(PUSHER_EVENTS.PATIENT_DELETE, patientDelete)
    channel.bind(PUSHER_EVENTS.PATIENT_LOCATION_UPDATE, patientLocationUpdate)
    channel.bind(PUSHER_EVENTS.PATIENT_STATUS_UPDATE, patientStatusUpdate)
    channel.bind(PUSHER_EVENTS.ROOM_STATUS_UPDATE, roomStatusUpdate)

    return () => {
      pusher.unsubscribe(String(bookingId))
    }
  }, [channel])

  return null
}
