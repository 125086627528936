import React, { type FC, memo, useEffect, useMemo } from 'react'
import { PATH_SEGMENT } from 'routes/pathSegments'
import { Navigate, useParams } from 'react-router-dom'
import { generateRouter } from 'routes/routes.utils'
import { TabPanel } from 'common/components/TabPanel/TabPanel'
import { BillingHistory } from 'features/Profile/features/BillingHistory/BillingHistory'
import { useLazyFetchPromotionsDocumentsQuery } from 'features/Home/Promotion/state/promotionsApi'
import { DocumentsTable } from 'features/Home/Book/Booking/Documents/DocumentsTable/DocumentsTable'

export const Documents: FC = memo(() => {
  const { bookingId, siteId } = useParams()

  const [fetchDocuments, { data, isLoading }] = useLazyFetchPromotionsDocumentsQuery()

  const tabs = useMemo(
    () => [
      {
        path: PATH_SEGMENT.CONTRACTS,
        title: 'Contracts',
      },
      {
        path: PATH_SEGMENT.BILLING,
        title: 'Billing',
      },
    ],
    [],
  )

  const routesConfig = useMemo(
    () => ({
      [PATH_SEGMENT.CONTRACTS]: <DocumentsTable data={data} isLoading={isLoading} />,
      [PATH_SEGMENT.BILLING]: <BillingHistory bookingId={bookingId} />,
      [PATH_SEGMENT.ROOT]: <Navigate to={PATH_SEGMENT.CONTRACTS} replace />,
    }),
    [data, isLoading, bookingId],
  )

  useEffect(() => {
    if (!bookingId || !siteId) {
      return
    }

    fetchDocuments({ bookingId, siteId })
  }, [bookingId, siteId])

  return (
    <>
      <TabPanel tabs={tabs} />
      {generateRouter(routesConfig)}
    </>
  )
})
