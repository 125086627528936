import React from 'react'
import { Navigate } from 'react-router-dom'

import { useAuth } from 'app/providers'

import { PATH_SEGMENT } from 'routes/pathSegments'

export const PublicRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user } = useAuth()

  if (user) {
    return <Navigate to={PATH_SEGMENT.ROOT} replace />
  }

  return <>{children}</>
}
