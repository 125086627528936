import type { FC, JSX } from 'react'
import { Fragment } from 'react'

import type { ISiteRoom } from 'common/interfaces/ISiteRoom'

import type { IRoomTimeSlot } from 'features/Booking/interfaces/ILeaseInfoRoom'
import { CartErrorList } from 'features/Cart/components/CartErrorList/CartErrorList'
import { CartItem } from 'features/Cart/components/CartItem/CartItem'
import { CartSlot } from 'features/Cart/components/CartSlot/CartSlot'
import type { IOptionSlot, IOptionSlotDay } from 'features/Option'

import styles from './cartSlot.module.scss'

interface IProps {
  options: IOptionSlot[]
  handleEdit: (option: ISiteRoom) => void
  handleRemoveOptionFixed?: (date: string, option: ISiteRoom) => void
  handleRemoveSlot?: (date: string, slot: IRoomTimeSlot, option: ISiteRoom) => void
}

export const CartSlotList: FC<IProps> = ({
  options,
  handleEdit,
  handleRemoveSlot = () => {},
  handleRemoveOptionFixed = () => {},
}) => {
  return (
    <div className={styles.parent}>
      {options.map((rOption: IOptionSlot): JSX.Element => {
        const { option, selectedDays, price } = rOption
        return (
          <Fragment key={option.id}>
            <CartItem
              price={price}
              name={option.name}
              handleEdit={() => handleEdit(option)}
              img={option.images.at(0)}>
              {selectedDays.map((day: IOptionSlotDay, index: number): JSX.Element => {
                return (
                  <CartSlot
                    day={day}
                    key={index}
                    option={option}
                    handleRemoveSlot={handleRemoveSlot}
                    handleRemoveOption={handleRemoveOptionFixed}
                  />
                )
              })}
              {option.error_list}
              <CartErrorList errorList={option.error_list} />
            </CartItem>
            <hr />
          </Fragment>
        )
      })}
    </div>
  )
}
