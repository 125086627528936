export enum INFO_GOOD_TO_KNOW_CONSTANTS {
  GOOD_TO_KNOW = 'Good to know',
  AMBIENT_MUSIC = 'Ambient music',
  PLAYING = 'Now Playing',
  SELECT_PLAYLIST = 'Select a playlist that will play during your stay',
  WIFI_ACCESS = 'Wi-fi access',
  WIFI_NAME = 'Wi-fi name',
  CONVENTION = 'Convention',
  PASSWORD = 'Password',
  NEED_HELP = 'Need help?',
  SITE_MANAGER = 'Site manager',
  REGIONAL_MANAGER = 'Regional manager',
  NEXT_PAYMENT = 'Next payment',
  SITE_LOCATION = 'Site location',
  VALUE = 'Value',
  REFUNDED = 'Refunded',
  REFUNDED_DATE = 'Refunded date',
  CLEANING_DEPOSIT = 'Cleaning deposit',
  ACCESS_CODES = 'Access codes',
  MAILBOX = 'Mailbox',
  MAILBOX_NUMBER = 'Mailbox number',
  UNITS = 'Units',
  ITEMS = 'Items',
  DESCRIPTION = 'Description',
  EQUIPMENT_STATUS = 'Equipment Status',
  LEASE_PERIOD = 'Booking Period',
  CONTRACT_PERIOD = 'Contract Period',
  HARDWARE_IDENTIFIER = 'Hardware Identifier',
  HARDWARE = 'Hardware',
  CHARGE = 'Account CC will be charged on',
  BOOKED = 'Booked',
  EHR_DETAILS = 'EHR Details',
}
