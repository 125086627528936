import { createApi } from '@reduxjs/toolkit/query/react'
import type { BaseQueryFn } from '@reduxjs/toolkit/src/query/baseQueryTypes'
import type { EndpointBuilder } from '@reduxjs/toolkit/src/query/endpointDefinitions'
import type { TablePaginationConfig } from 'antd'

import { HTTP_METHODS } from 'common/constants/httpMethodsConstants'
import type { IRequestData } from 'common/interfaces/IRequestResponse'
import { UtilService } from 'common/services/utilService'
import { baseQuery } from 'common/store/api/fetchBaseQuery'
import { REDUCERS } from 'common/store/reducerConstants'

import type { IStaff } from 'features/Staff/interfaces/IStaff'

const { withPaginationParams } = UtilService

export const staffApi = createApi({
  reducerPath: REDUCERS.STAFF,
  baseQuery: baseQuery(),
  tagTypes: ['Staff'],
  endpoints: (build: EndpointBuilder<BaseQueryFn, string, string>) => ({
    fetchStaff: build.query({
      query: (params: TablePaginationConfig) => ({
        url: withPaginationParams('/staff', params),
      }),
      transformResponse: (response: IRequestData<IStaff[]>): IRequestData<IStaff[]> => ({
        ...response,
        items: response.items.map(
          (staff: IStaff): IStaff => ({
            ...staff,
            qualification: staff.qualification['name' as keyof object],
          }),
        ),
      }),
      providesTags: ['Staff'],
    }),
    staffQualification: build.query({
      query: () => ({
        url: '/staff/qualifications',
      }),
    }),
    createStaff: build.mutation({
      query: (staff) => ({
        url: '/staff',
        method: HTTP_METHODS.POST,
        data: staff,
      }),
      invalidatesTags: ['Staff'],
    }),
    deleteStaff: build.mutation({
      query: (staffId) => ({
        url: `/staff/${staffId}`,
        method: HTTP_METHODS.DELETE,
      }),
      invalidatesTags: ['Staff'],
    }),
    updateStaff: build.mutation({
      query: ({ id, updatedStaff }) => ({
        url: `/staff/${id}`,
        method: HTTP_METHODS.PUT,
        data: updatedStaff,
      }),
      invalidatesTags: ['Staff'],
    }),
  }),
})

export const {
  useLazyFetchStaffQuery,
  useDeleteStaffMutation,
  useCreateStaffMutation,
  useUpdateStaffMutation,
  useStaffQualificationQuery,
}: any = staffApi
