import type { IBookingInfoRoom } from 'features/Booking/interfaces/ILeaseInfoRoom'
import type { IUpdateCartPriceProps } from 'features/Cart/interfaces/ICart'
import type { IOptionSelectFixed, IOptionSlot } from 'features/Option'

const calculatePrice = <T extends { price?: number }>(data: T[]): number =>
  data.reduce((total: number, item: T) => total + item.price, 0)

const getUpdatedCartPrice = ({
  rooms = [],
  options = [],
  optionSelectFixed = [],
}: IUpdateCartPriceProps) => {
  const leasesPrice: number = calculatePrice<IBookingInfoRoom>(rooms)
  const optionsPrice: number = calculatePrice<IOptionSlot>(options)
  const optionsFixedPrice: number = calculatePrice<IOptionSelectFixed>(optionSelectFixed)

  return leasesPrice + optionsPrice + optionsFixedPrice
}

export const CartService = { getUpdatedCartPrice }
