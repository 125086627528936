import React, { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'

import App from './App'
import { Scripts } from './Scripts'

const container = document.getElementById('root') as HTMLElement
const root = createRoot(container)

window.React = React

root.render(
  <StrictMode>
    <Scripts />
    <App />
  </StrictMode>,
)
