import { useEffect } from 'react'

export const useMutationObserver = (
  ref: any,
  callback: MutationCallback,
  options: {
    attributes?: boolean
    attributeFilter?: string[]
    characterData?: boolean
    childList?: boolean
    subtree?: boolean
  } = {
    attributes: true,
    attributeFilter: ['class', 'style', 'src', 'href'],
    characterData: true,
    childList: true,
    subtree: true,
  },
) => {
  useEffect(() => {
    if (Boolean(ref)) {
      const observer = new MutationObserver(callback)
      observer.observe(ref, options)
      return () => observer.disconnect()
    }
  }, [callback, options, ref])
}
