import type { FC } from 'react'
import { memo } from 'react'

import { Button } from 'common/components/Button/Button'
import { Show } from 'common/components/Show/Show'
import { ALT_CONSTANTS } from 'common/constants/altConstants'
import { BUTTON_CONSTANTS } from 'common/constants/buttonConstants'
import { UtilService } from 'common/services/utilService'

import styles from './shopCard.module.scss'
import type { ShopCardProps } from './shopCard.types'

const { numberToDollar } = UtilService

export const ShopCard: FC<ShopCardProps> = ({ shopItem, handleSelectShopItem }) => {
  return (
    <div className={styles.parent}>
      <img
        className={styles.parentPhoto}
        src={shopItem.images.at(0)}
        alt={ALT_CONSTANTS.SHOT_ITEM_ICON}
      />
      <div className={styles.parentContent}>
        <div>
          <h3 className={styles.parentName}>{shopItem.name}</h3>
          <Show when={shopItem?.price}>
            <p className={styles.parentPrice}> {numberToDollar(shopItem?.price)}</p>
          </Show>
          <p className={styles.parentDescription}>{shopItem.content || shopItem.description}</p>
        </div>
        <div className={styles.parentButton}>
          <Button onClick={() => handleSelectShopItem(shopItem)}>
            {BUTTON_CONSTANTS.ADD_TO_CART}
          </Button>
        </div>
      </div>
    </div>
  )
}
