import type { FC, JSX, RefObject } from 'react'
import { useRef } from 'react'

import { Carousel } from 'antd'
import type { CarouselRef } from 'antd/es/carousel'
import classNames from 'classnames'

import { ReactComponent as NextSlide } from 'assets/icons/next-slide.svg'
import { ReactComponent as PrevSlide } from 'assets/icons/prev-slide.svg'

import { IncludedList } from 'common/components/IncludedList/IncludedList'
import { Show } from 'common/components/Show/Show'
import { Spinner } from 'common/components/Spinner'
import { ALT_CONSTANTS } from 'common/constants/altConstants'
import type { ISiteRoom } from 'common/interfaces/ISiteRoom'

import styles from './roomThumbnail.module.scss'

interface IProps {
  roomInfo: ISiteRoom
  disabled: boolean
  isLoading: boolean
  handleClick?: (roomInfo: ISiteRoom) => void
}

export const RoomThumbnail: FC<IProps> = ({ roomInfo, isLoading, handleClick, disabled }) => {
  let carouselRef = useRef<RefObject<CarouselRef | null>>(null)
  const thumbnailRef = useRef<HTMLDivElement | null>(null)
  const isRoomDisabled = !Boolean(roomInfo?.is_visible || roomInfo?.visible_marketing) || disabled

  // @ts-ignore: Property 'next' does not exist
  const handleNextPhoto = () => carouselRef?.next()

  // @ts-ignore: Property 'prev' does not exist
  const handlePrevPhoto = () => carouselRef?.prev()

  return (
    <div
      data-cy='booking-roomInfo-card'
      ref={thumbnailRef}
      className={classNames(styles.roomThumbnail, {
        [styles.roomThumbnailDisabled]: isRoomDisabled,
      })}>
      <Show when={isLoading}>
        <div className={styles.spinnerContainer}>
          <Spinner isLoading={isLoading} />
        </div>
      </Show>

      <div className={styles.roomThumbnailPhotoContainer}>
        <button className={styles.roomThumbnailPhotoNextButton} onClick={handlePrevPhoto}>
          <NextSlide />
        </button>
        <Carousel
          dots={false}
          arrows={false}
          className={styles.roomThumbnailCarousel}
          ref={(node: any) => (carouselRef = node)}>
          {roomInfo?.images.map(
            (roomPhoto: string): JSX.Element => (
              <img
                src={roomPhoto}
                key={roomPhoto}
                alt={ALT_CONSTANTS.ROOM_PHOTO}
                className={styles.roomThumbnailPhoto}
                onClick={() => !isRoomDisabled && handleClick(roomInfo)}
              />
            ),
          )}
        </Carousel>
        <button className={styles.roomThumbnailPhotoPrevButton} onClick={handleNextPhoto}>
          <PrevSlide />
        </button>
      </div>
      <div className={styles.roomThumbnailInfo}>
        <p
          className={styles.roomThumbnailTitle}
          onClick={() => !isRoomDisabled && handleClick(roomInfo)}>
          {roomInfo.name}
        </p>
        <p className={styles.roomThumbnailDescription}>{roomInfo.description}</p>

        <div className={styles.roomThumbnailIncluded}>
          <IncludedList includedList={roomInfo.attributes} />
        </div>
      </div>
    </div>
  )
}
