export const MailboxProfileText = {
  APPLICANT: 'Applicant',
  APPLICANT_INITIALS: 'Applicant Initials',
  APPLICANT_HOME_ADDRESS: 'Applicant Home Address',
  APPLICATION_PHONE: 'Applicant Telephone Number',
  CITY: 'City',
  STATE: 'State',
  ZIP: 'ZIP + 4',
  BUSINESS: 'Business',
  NAME_OF_CORPORATION: 'Name Of Firm Or Corporation',
  BUSINESS_ADDRESS: 'Business Address',
  BUSINESS_PHONE: 'Business Telephone Number',
  TYPE_OF_BUSINESS: 'Type Of Business',
  FORWARDING_MAILBOX: 'Forwarding Mailbox',
  FORWARDING_ADDRESS: 'Forwarding Address',
  FORWARDING_PHONE: 'Forwarding Telephone Number',
  INCLUDING_AREA_CODE: '(Include area code)',
  NO_STREET_ADDRESS: '(No., Street, Apt./Ste. No)',
}
