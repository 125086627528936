import type { FC, JSX } from 'react'

import { nanoid } from 'nanoid'

import checkIcon from 'assets/icons/check.svg'

import { ALT_CONSTANTS } from 'common/constants/altConstants'
import { APP_CONSTANTS } from 'common/constants/constants'
import type { IRoomAttribute } from 'common/interfaces/ISiteRoom'

import styles from './includedList.module.scss'

interface IProps {
  includedList: IRoomAttribute[]
}

export const IncludedList: FC<IProps> = ({ includedList }) => {
  if (!includedList?.length) return null
  return (
    <div className={styles.parent}>
      <h4 className={styles.parentTitle}>{APP_CONSTANTS.INCLUDED}</h4>
      <div className={styles.parentList}>
        {includedList?.map(
          (attribute: IRoomAttribute): JSX.Element => (
            <span key={nanoid()} className={styles.parentItem}>
              <img src={checkIcon} alt={ALT_CONSTANTS.ACTION_ICON} className={styles.parentIcon} />
              {attribute.name}
            </span>
          ),
        )}
      </div>
    </div>
  )
}
