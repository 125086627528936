import { Icon } from '@iconify/react'
import { Button } from 'common/components/Button/Button'

import type { ButtonProps } from 'antd/es/button/button'

export const SaveButton = (props: ButtonProps) => {
  return (
    <Button
      icon={
        <Icon
          icon='ic:twotone-save'
          style={{
            color: 'var(--secondary-100)',
            fontSize: '24px',
            opacity: props.disabled ? 0.2 : 1,
          }}
        />
      }
      htmlType='submit'
      {...props}>
      Save Preferences
    </Button>
  )
}
