import { memo, useEffect, useState } from 'react'
import { useToggle } from 'react-use'

import type { RadioChangeEvent } from 'antd'

import { Button } from 'common/components/Button/Button'
import { Show } from 'common/components/Show/Show'
import { BUTTON_CONSTANTS } from 'common/constants/buttonConstants'
import { useApiResponse } from 'common/hooks/useApiResponse'
import { useSites } from 'common/hooks/useSites'
import type { ApiResponse, ITriggerRequest } from 'common/interfaces/IRequestResponse'

import type { ISettingPaymentCard } from 'features/SettingsOld/interfaces/ISettingPaymentCard'
import {
  useChangeDefaultPaymentMethodMutation,
  useLazyFetchPaymentMethodsQuery,
} from 'features/SettingsOld/state/api/settingsApi'

import { ManageCard } from './components/ManageCard/ManageCard'
import { PaymentCardList } from './components/PaymentCardList/PaymentCardList'
import { SETTINGS_CONSTANTS } from './constants/constants'
import styles from './CreditCards.module.scss'

export const CreditCards = memo(() => {
  const { processApiResponse } = useApiResponse()
  const { isFetchingSites } = useSites()

  const [isOpenModalAddNewCard, toggleIsOpenModalAddNewCard] = useToggle(false)
  const [updatedCardDefault, setUpdatedCardDefault] = useState<string>(null)
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string>('new_card')
  const [editedPaymentMethod, setEditedPaymentMethod] = useState<ISettingPaymentCard>(null)

  const [fetchPaymentMethods, { data, isSuccess, isFetching: isFetchingMethods }] =
    useLazyFetchPaymentMethodsQuery()

  const [changeDefaultCard, { isLoading: isChangingMethod }]: ITriggerRequest =
    useChangeDefaultPaymentMethodMutation()

  const { paymentMethods = [], default: defaultMethod } = data ?? {}

  useEffect(() => {
    fetchPaymentMethods()
  }, [])

  useEffect(() => {
    if (defaultMethod) setSelectedPaymentMethod(defaultMethod)
  }, [defaultMethod])

  const handleChangePaymentMethod = async (event: RadioChangeEvent): Promise<void> => {
    const { value: paymentMethodId } = event.target
    setSelectedPaymentMethod(paymentMethodId)
    setUpdatedCardDefault(paymentMethodId)
  }

  const handleChangeDefaultMethod = async (): Promise<void> => {
    const response: ApiResponse = await changeDefaultCard(updatedCardDefault)
    processApiResponse(response, {
      error: 'Change default card error',
      success: 'Change default card success',
    })
    setUpdatedCardDefault(null)
  }

  const handleEditCard = (card: ISettingPaymentCard): void => {
    setEditedPaymentMethod(card)
    toggleIsOpenModalAddNewCard()
  }

  const handleCloseModal = (): void => {
    if (editedPaymentMethod) setEditedPaymentMethod(null)

    if (isOpenModalAddNewCard) toggleIsOpenModalAddNewCard()
  }

  return (
    <div className={styles.parent}>
      <h1 className='m-0'>{SETTINGS_CONSTANTS.PAYMENT}</h1>
      <ManageCard
        onClose={handleCloseModal}
        editCard={editedPaymentMethod}
        isVisible={isOpenModalAddNewCard}
      />
      <div className={styles.parentPaymentMethod}>
        <h2 className={styles.parentPaymentMethodTitle}>{SETTINGS_CONSTANTS.PAYMENT_METHOD}</h2>
        <PaymentCardList
          list={paymentMethods}
          handleEditCard={handleEditCard}
          selected={selectedPaymentMethod}
          handleAddNewCard={toggleIsOpenModalAddNewCard}
          handleChangeMethod={handleChangePaymentMethod}
          isFetching={[isFetchingSites, isFetchingMethods].includes(true)}
        />
        <Show when={isSuccess && !paymentMethods.length}>
          <p className={styles.parentErrorInfo}>{SETTINGS_CONSTANTS.NEED_ADD_CARD}</p>
        </Show>
        <div className={styles.parentSave}>
          <Button
            loading={isChangingMethod}
            disabled={!updatedCardDefault}
            onClick={handleChangeDefaultMethod}>
            {BUTTON_CONSTANTS.APPLY}
          </Button>
        </div>
      </div>
    </div>
  )
})
