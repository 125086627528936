import { createApi } from '@reduxjs/toolkit/query/react'
import type { BaseQueryFn } from '@reduxjs/toolkit/src/query/baseQueryTypes'
import type { EndpointBuilder } from '@reduxjs/toolkit/src/query/endpointDefinitions'

import { HTTP_METHODS } from 'common/constants/httpMethodsConstants'
import type { IOption } from 'common/interfaces/IOption'
import { baseQuery } from 'common/store/api/fetchBaseQuery'
import { REDUCERS } from 'common/store/reducerConstants'

import type { IAuthValidation } from 'features/Auth/interfaces/IAuth'
import type { IUploadedValidationDocument } from 'features/Auth/interfaces/IAuthUploadedDocument'

export const authApi = createApi({
  reducerPath: REDUCERS.AUTH,
  baseQuery: baseQuery(),
  endpoints: (build: EndpointBuilder<BaseQueryFn, string, string>) => ({
    login: build.mutation({
      query: (user) => ({
        url: '/login',
        data: user,
        method: HTTP_METHODS.POST,
      }),
      transformResponse: (response: any) => {
        // const getUpdatedDocuments = (documents: IUploadedValidationDocument[]) =>
        //   documents?.filter((documentUploaded: IUploadedValidationDocument): boolean =>
        //     Boolean(documentUploaded.is_valid),
        //   )
        return {
          ...response,
          // documents:
          //   getUpdatedDocuments(response?.documents) ||
          //   getUpdatedDocuments(response?.doctor_documents),
        }
      },
    }),
    logout: build.mutation({
      query: (user) => ({
        url: '/logout',
        data: user,
        method: HTTP_METHODS.POST,
      }),
    }),
    register: build.mutation({
      query: (user) => ({
        url: '/register',
        data: user,
        method: HTTP_METHODS.POST,
      }),
    }),
    resetPassword: build.mutation({
      query: (data) => ({
        url: '/reset-password',
        data: data,
        method: HTTP_METHODS.POST,
      }),
    }),
    forgotPassword: build.mutation({
      query: (data) => ({
        url: '/forgot-password',
        data: data,
        method: HTTP_METHODS.POST,
      }),
    }),
    registerSelector: build.query({
      query: () => ({
        url: '/anonymous-site-selector',
      }),
      transformResponse: (data: { id: number; name: string }[]): IOption[] =>
        data.map((option): IOption => ({ code: option.id, name: option.name })),
    }),
    validateIdentity: build.mutation({
      query: (data) => ({
        url: '/doctor-documents',
        data: data,
        method: HTTP_METHODS.POST,
      }),
    }),
    fetchValidationInfo: build.query({
      query: () => ({
        url: '/doctor-documents',
      }),
      transformResponse: ({ documents, ...rest }: IAuthValidation): IAuthValidation => {
        const updatedDocuments: IUploadedValidationDocument[] =
          documents?.map(
            (uploadedDoc: IUploadedValidationDocument): IUploadedValidationDocument =>
              uploadedDoc?.reason === 'N/A' ? { ...uploadedDoc, reason: null } : uploadedDoc,
          ) || []
        return { documents: updatedDocuments, ...rest }
      },
    }),
  }),
})

export const {
  useLoginMutation,
  useLogoutMutation,
  useRegisterMutation,
  useResetPasswordMutation,
  useForgotPasswordMutation,
  useRegisterSelectorQuery,
  useValidateIdentityMutation,
  useFetchValidationInfoQuery,
}: any = authApi
