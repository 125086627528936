import { type FC, memo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useToggle } from 'react-use'

import { Typography } from 'antd'
import { PATH_SEGMENT } from 'routes/pathSegments'

import { ModalAction } from 'common/components/Modal'
import { BUTTON_CONSTANTS } from 'common/constants/buttonConstants'
import { MODAL_LABELS } from 'common/constants/modalConstants'

import { CART_CONSTANTS, useCartManager } from 'features/Cart'
import { ReactComponent as ExclamationCircleIcon } from 'assets/icons/exclamation-circle.svg'

import { BookingThumbnails } from 'features/Home/Dashboard/UserDashboard/ActiveBookings/BookingThumbnails/BookingThumbnails'

import type { ActiveBookingsProps } from './activeBookings.types'
import styles from './activeBookings.module.scss'
const { Title } = Typography

export const ActiveBookings: FC<ActiveBookingsProps> = memo(({ isLoading, bookings, isStaff }) => {
  const navigate = useNavigate()

  const [isOpenActionModal, toggleIsOpenActionModal] = useToggle(false)

  const { deleteCart } = useCartManager()

  const handleRemoveCart = async (): Promise<void> => {
    await deleteCart()
    toggleIsOpenActionModal()
    navigate(PATH_SEGMENT.BOOK)
  }

  return (
    <>
      <Title level={2} className={`${!isStaff ? 'mt-32' : 'mt-0'} mb-24 font-semibold`}>
        Active Bookings
      </Title>

      {bookings?.length === 0 && (
        <div className={styles.noBookings}>
          <ExclamationCircleIcon /> None
        </div>
      )}

      <BookingThumbnails bookings={bookings} isLoading={isLoading} />

      {isOpenActionModal && (
        <ModalAction
          isOpen={isOpenActionModal}
          onConfirm={handleRemoveCart}
          onClose={toggleIsOpenActionModal}
          cancelText={BUTTON_CONSTANTS.CANCEL}
          title={MODAL_LABELS.CONFIRM_CLEAR_CART}
          confirmText={BUTTON_CONSTANTS.CONTINUE}
          description={CART_CONSTANTS.CONFIRM_CLEAR_MESSAGE}
        />
      )}
    </>
  )
})
