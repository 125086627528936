export enum BOOKING_CONSTANTS {
  POINTS = 'pts.',
  TOTAL_PRICE = 'Cart Total',
  SELECTION_PRICE = 'Selection Price',
  CHOOSE_DATE_AND_TIME = 'Choose Date(s) & Time(s)',
  EXTRAS = 'Extras',
  PROVISION_PACKAGES = 'Provision Packages',
  IN_ORDER_TO_CONFIRM = '** In order to confirm your booking selection you need to click “Continue”',
  LEASE_INFO_MESSAGE = '* Prices might vary depending on availability, days and options selected',
}
