import type { FC, JSX } from 'react'
import { useCallback } from 'react'

import { useAuth } from 'app/providers'

import { Show } from 'common/components/Show/Show'
import ThumbnailsSkeleton from 'common/components/ThumbnailsSkeleton/ThumbnailsSkeleton'
import useNavigateParams from 'common/hooks/useNavigateParams'

import type { IBooking } from 'features/Booking'

import { BookingThumbnail } from 'features/Home/Dashboard/UserDashboard/ActiveBookings/BookingThumbnail/BookingThumbnail'

import styles from './bookingThumbnails.module.scss'
import type { BookingThumbnailsProps } from './bookingThumbnails.types'
import { PathUtils } from 'routes/routes.utils'

export const BookingThumbnails: FC<BookingThumbnailsProps> = ({ bookings, isLoading }) => {
  const navigate = useNavigateParams()
  const { isDoctor, isStaff } = useAuth()

  const navigateToBookingHandler = useCallback((booking: IBooking) => {
    const { id, is_promotion, database_connection } = booking

    if (is_promotion) {
      return navigate(PathUtils.getPromotionDetails(database_connection, id))
    }

    navigate(PathUtils.getBookingDetails(database_connection, id))
  }, [])

  return (
    <div className={styles.thumbnailsContainer}>
      <Show
        when={!isLoading}
        fallback={<ThumbnailsSkeleton total={4} rows={2} maxWidth={417} imgHeight={150} />}>
        {bookings?.map(
          (booking: IBooking, i): JSX.Element => (
            <BookingThumbnail
              booking={booking}
              key={`${booking.id}_${i}`}
              withPrice={isDoctor || isStaff}
              navigateToBookingHandler={navigateToBookingHandler}
            />
          ),
        )}
      </Show>
    </div>
  )
}
