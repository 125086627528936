import type { FC } from 'react'

import { UtilService } from 'common/services/utilService'

import styles from './paymentPriceItem.module.scss'

interface IProps {
  price: number
  title: string
}

export const PaymentPriceItem: FC<IProps> = ({ title, price }) => {
  return (
    <div className={styles.parent}>
      <h3 className={styles.parentContent}>{title}</h3>
      <span className={styles.parentPrice}>{UtilService.numberToDollar(price)}</span>
    </div>
  )
}
