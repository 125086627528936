import { Col, Row, Skeleton } from 'antd'
import { UtilService } from 'common/services/utilService'
import { WidgetPhoto } from 'features/Widget/components/WidgetPhoto/WidgetPhoto'
import styles from './userWidget.module.scss'
import { useSitePoints } from 'app/providers/sitePoints/SitePointsProvider'
import { useMemo } from 'react'
import { ReactComponent as StarIcon } from 'features/Widget/assets/icons/star.svg'
import { useFetchProfileQuery } from 'features/SettingsOld/state/api/settingsApi'

export const UserWidget = () => {
  const { data, isLoading } = useFetchProfileQuery()
  const { levels } = useSitePoints()
  const { points = 0, items = [] } = levels ?? {}

  const activeLevel = useMemo(() => {
    return items.findLast((item) => points >= item.points)
  }, [points, items])

  const { profile } = data || {}
  const initials = UtilService.getInitials(profile?.first_name, profile?.last_name)
  const formatPoints = new Intl.NumberFormat('en-US', {
    notation: 'compact',
  }).format(points)

  if (isLoading) return <Skeleton.Input className={styles.skeleton} active size='large' />

  return (
    <div>
      <Row gutter={12}>
        <Col>
          <WidgetPhoto initials={initials} hasShadow={false} src={profile?.image?.url} />
        </Col>
        <Col>
          <h5 className={styles.name}>
            {profile?.first_name} {profile?.last_name}
          </h5>
          <div className={styles.levelContainer}>
            <StarIcon />
            <p className={styles.level}>{activeLevel?.name}</p>
            <span className={styles.levelValue}>({formatPoints} points)</span>
          </div>
        </Col>
      </Row>
    </div>
  )
}
