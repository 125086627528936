import { type ThemeConfig } from 'antd'

export const theme: ThemeConfig = {
  token: {},
  components: {
    Button: {
      colorPrimary: 'var(--primary-100)',
      colorPrimaryHover: 'var(--primary-80)',
      colorPrimaryActive: 'var(--primary-60)',
    },
    Input: {
      colorPrimary: 'var(--secondary-50)',
      colorBorder: 'var(--light-04)',
    },
    Select: {
      colorPrimary: 'var(--tertiary-100)',
      colorBorder: 'var(--light-04)',
      activeBorderColor: 'var(--light-04)',
      boxShadow: 'none',
      boxShadowSecondary: 'none',
      colorPrimaryText: 'var(--black)',
      optionSelectedColor: 'var(--black)',
      optionSelectedBg: 'var(--primary-20)',
      hoverBorderColor: 'var(--light-04)',
    },
    Menu: {
      algorithm: true,
      itemBg: 'var(--white)',
      subMenuItemBg: '#00000005',
      itemActiveBg: 'var(--primary-20)',
      itemHoverBg: 'var(--primary-20)',
      itemHoverColor: 'var(--secondary-100)',
      itemSelectedBg: 'var(--primary-20)',
      itemSelectedColor: 'var(--secondary-100)',
      groupTitleColor: 'var(--secondary-100)',
      colorItemText: 'var(--secondary-100)',
      colorItemTextSelected: 'var(--secondary-100)',
      colorItemBgSelected: 'var(--primary-20)',
      colorIcon: 'var(--secondary-100)',
    },
    Radio: {
      colorPrimary: 'var(--primary-100)',
      buttonSolidCheckedActiveBg: 'var(--primary-100)',
      buttonSolidCheckedBg: 'var(--primary-100)',
      buttonSolidCheckedHoverBg: 'var(--primary-100)',
    },
    Table: {
      // colorBgContainer: 'var(--light-01)',
      // colorBorderSecondary: 'var(--light-04)',
    },
  },
}
