import type { FC, JSX } from 'react'

import { LeaseOption } from 'features/Booking/components/LeaseOption/LeaseOption'
import type { IRoomOptionProduct } from 'features/Booking/interfaces/ILeaseInfoRoom'

import styles from './leaseOptionList.module.scss'

interface IProps {
  siteValue: number
  options: IRoomOptionProduct[]
  selectedOptions: IRoomOptionProduct[]
  disabledList?: boolean
  handleUpdateOption: (option: IRoomOptionProduct) => void
  handleSelectOption: (option: IRoomOptionProduct) => void
}

export const LeaseOptionList: FC<IProps> = ({
  siteValue,
  selectedOptions,
  handleUpdateOption,
  options,
  handleSelectOption,
  disabledList = false,
}) => {
  return (
    <div className={styles.parent}>
      {options?.map(
        (option: IRoomOptionProduct): JSX.Element => (
          <LeaseOption
            disabled={disabledList}
            key={option.id}
            option={option}
            siteValue={siteValue}
            onCheck={handleSelectOption}
            onUpdate={handleUpdateOption}
            selectedOption={selectedOptions.find(
              (selectedOption: IRoomOptionProduct): boolean => selectedOption.id === option.id,
            )}
          />
        ),
      )}
    </div>
  )
}
