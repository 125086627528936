import { Show } from 'common/components/Show/Show'
import { Spinner } from 'common/components/Spinner'

import styles from './SpinnerOverla.module.scss'

type Props = {
  isLoading?: boolean
}

const SpinnerOverlay = ({ isLoading = true }: Props) => {
  return (
    <Show when={isLoading}>
      <div className={styles.parent}>
        <div className={styles.parentSpinnerContainer}>
          <Spinner isLoading={isLoading} />
        </div>
      </div>
    </Show>
  )
}

export default SpinnerOverlay
