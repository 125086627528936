import { Tooltip } from 'antd'

import { SmsIcon } from 'common/components/SmsIcon'

import { INFO_CONSTANTS, SMS_STATUSES } from 'features/Home/constants/infoConstants'
import type { FC } from 'react'
import { memo } from 'react'
import type { smsIconProps } from './smsIcon.types'

export const SMSIcon: FC<smsIconProps> = memo(({ status, color }) => {
  switch (status) {
    case SMS_STATUSES.FAILED:
      return (
        <Tooltip title={INFO_CONSTANTS.FAILED_SMS} placement='topLeft'>
          <svg xmlns='http://www.w3.org/2000/svg' height='20' viewBox='0 96 960 960' width='20'>
            <path
              fill='#f67167'
              d='M480 624 168 445v347h337q0 17 .5 36.5T509 864H168q-29 0-50.5-21.5T96 792V360q0-29.7 21.5-50.85Q139 288 168 288h624q29 0 50.5 21.15T864 360v211q-17-8-35.185-12-18.186-4-36.815-6V445L480 624Zm0-85 312-179H168l312 179Zm287.774 469Q688 1008 632 951.774t-56-136Q576 736 632.226 680t136-56Q848 624 904 680.226t56 136Q960 896 903.774 952t-136 56ZM672 840h192v-48H672v48Zm-504-48V360v432Z'
            />
          </svg>
        </Tooltip>
      )

    case SMS_STATUSES.PENDING:
      return (
        <Tooltip title={INFO_CONSTANTS.PENDING_SEND_SMS} placement='topLeft'>
          <svg xmlns='http://www.w3.org/2000/svg' height='20' viewBox='0 96 960 960' width='20'>
            <path
              fill={color}
              d='M144 864V288l542 216q-40 0-67 5.5T557 531L216 395v109l216 72-216 72v109l210-83q-8 20-12.5 41.5T408 758L144 864Zm528 96q-80 0-136-56t-56-136q0-80 56-136t136-56q80 0 136 56t56 136q0 80-56 136t-136 56Zm55-103 34-34-65-65v-86h-48v106l79 79ZM216 648V395v362-109Z'
            />
          </svg>
        </Tooltip>
      )

    case SMS_STATUSES.DELIVERED:
      return (
        <Tooltip title={INFO_CONSTANTS.SUCCESS_SMS} placement='topLeft'>
          <span>
            <SmsIcon color={color} />
          </span>
        </Tooltip>
      )

    default:
      return null
  }
})
