import type { FC } from 'react'
import { memo } from 'react'

import classNames from 'classnames'
import parse from 'html-react-parser'

import styles from './bookingNotifications.module.scss'
import type { BookingNotificationsProps } from './bookingNotifications.types'

export const BookingNotifications: FC<BookingNotificationsProps> = memo(
  ({ index, notification }) => {
    const highlightCode = (text: string): string => {
      const regex = /(?:#|\*)\s*([^ ]+)/
      const match = text.match(regex)

      if (match && match[1]) {
        const code = match[1]
        return text.replace(regex, `<span style="color: #CB7D61;">#${code}</span>`)
      } else {
        return text
      }
    }

    return (
      <div
        className={classNames(
          styles.parent,
          { [styles.parentFirst]: index === 0 },
          { [styles.parentOthers]: index > 0 },
        )}
        key={index}>
        <div>
          <p className={styles.parentTitle}>{notification.title}</p>
          <p className={styles.parentDescription}>
            {parse(highlightCode(notification.description))}
          </p>
        </div>
        <div>
          <p className={styles.parentDescription}>{notification.created_at}</p>
        </div>
      </div>
    )
  },
)
