import type { FC } from 'react'
import { memo, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLocalStorage } from 'react-use'

import { Show } from 'common/components/Show/Show'
import { GuestLocationThumbnails } from 'common/components/Thumbnail/GuestLocationThumbnails/GuestLocationThumbnail'
import ThumbnailsSkeleton from 'common/components/ThumbnailsSkeleton/ThumbnailsSkeleton'
import { LOCALE_STORAGE_KEYS } from 'common/constants/localeStorageConstants'
import type { ILevels } from 'common/interfaces/ILevels'
import { useFetchSitesQuery } from 'common/store/api/rootApi'

import styles from './guestDashboard.module.scss'
import { PathUtils } from 'routes/routes.utils'

export const GuestDashboard: FC = memo(() => {
  const [_, setLevels] = useLocalStorage<ILevels | null>(LOCALE_STORAGE_KEYS.LEVELS, null)

  const { data: selector, isLoading } = useFetchSitesQuery()
  const { sites = [] } = selector ?? {}

  const navigate = useNavigate()

  const handleSelectSite = useCallback(
    (siteSelector: string) => {
      navigate(PathUtils.getGuestBook(siteSelector))
      setLevels({ items: [], point_per_dollar: {}, points: 0 })
    },
    [navigate, setLevels],
  )

  return (
    <div>
      <p className={styles.locationTitle}>Choose location</p>

      <Show when={isLoading}>
        <div className={styles.locationSkeleton}>
          <ThumbnailsSkeleton imgHeight={178} rows={1} total={2} maxWidth={450} />
        </div>
      </Show>
      <GuestLocationThumbnails handleSelectSite={handleSelectSite} sites={sites} />
    </div>
  )
})

export type GuestType = typeof GuestDashboard

GuestDashboard.displayName = 'GuestDashboard'
