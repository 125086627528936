import { isValidPhoneNumber } from 'react-phone-number-input'

import * as Yup from 'yup'

import { RegexService } from 'common/services/regexService'

export const SETTINGS_PROFILE_STAFF_SCHEMA = Yup.object().shape({
  email: Yup.string()
    .email()
    .required('Email is required field')
    .matches(RegexService.email(), 'Please enter your valid email'),
  name: Yup.string()
    .required('Full Name is required field')
    .matches(RegexService.onlyLatin(), 'Full name can only contain latin letters'),
  phone_number: Yup.string()
    .nullable()
    .min(1, 'Please enter a valid mobile phone.')
    .test('is-valid-phone', 'Please enter a valid mobile phone', function (value) {
      return value && isValidPhoneNumber(value)
    })
    .required('Please enter the phone'),

  current_password: Yup.string().when(['new_confirm_password'], {
    is: (new_password: string, new_confirm_password: string): boolean =>
      Boolean(new_password) || Boolean(new_confirm_password),
    then: (schema) => schema.required('Current password is required'),
  }),

  new_password: Yup.string()
    .when('current_password', {
      is: (current_password: string): boolean => Boolean(current_password),
      then: (schema) => schema.required('New password is required'),
    })
    .test('password-validation', 'Invalid password', function (value: string) {
      const { path, createError } = this
      if (value)
        switch (Boolean(value)) {
          case !RegexService.includeLowerCase().test(value):
            return createError({ path, message: 'Password must include one lowercase character' })
          case !RegexService.includeUpperCase().test(value):
            return createError({ path, message: 'Password must include one uppercase character' })
          case !RegexService.number().test(value):
            return createError({ path, message: 'Password must include one number' })
          case !RegexService.includeSpecialChar().test(value):
            return createError({ path, message: 'Password must include special character' })
          case value.length < 8:
            return createError({ path, message: 'Password must include minimum 8 characters' })
          default:
            return true
        }
      return true
    }),
  new_confirm_password: Yup.string()
    .required('Please enter the confirm password')
    .oneOf([Yup.ref('new_password'), null], 'Passwords must match'),
})
