import type { IOption } from 'common/interfaces/IOption'

import type { IUploadedValidationDocument } from 'features/Auth/interfaces/IAuthUploadedDocument'

export enum USER_ROLE {
  STAFF = 'Staff',
  DOCTOR = 'Doctor',
}

type USER_ROLE_TYPE = USER_ROLE.DOCTOR | USER_ROLE.STAFF

export interface IUser {
  access_token: string
  points: number
  name: string
  email: string
  last_name: string
  first_name: string
  phone_number: string
  role: IOption<USER_ROLE_TYPE>
  documents: IUploadedValidationDocument[]
  old_access_token?: string
  is_super_doctor?: boolean
}
