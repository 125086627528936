import type { FC } from 'react'
import React, { memo, useCallback, useMemo } from 'react'

import { Col, Row, Skeleton, Typography } from 'antd'
import { PATH_SEGMENT } from 'routes/pathSegments'

import Show from 'common/components/Show2/Show'
import type { CustomCSSProperties } from 'common/interfaces/CustomCss'

import { BOOKING_TYPE } from 'features/Booking/constants/booking.constants'
import { useCartManager } from 'features/Cart'
import { NewLeasesTypes } from 'features/Home/constants/homeConstants'

import styles from './chooseBookingType.module.scss'
import type { ChooseBookingTypeProps } from './chooseBookingType.types'
import { useNavigate } from 'react-router-dom'

const { Title } = Typography

const newBookingOption = {
  label: NewLeasesTypes.RoomLease,
  value: BOOKING_TYPE.BOOKING,
  image: require('../../../assets/booking.png'),
}

export const ChooseBookingType: FC<ChooseBookingTypeProps> = memo(({ isLoading, bookingTypes }) => {
  const navigate = useNavigate()

  const { cart, setActiveBooking } = useCartManager()

  const availableBookings = useMemo(() => {
    if (!bookingTypes) {
      return []
    }

    return (
      bookingTypes.booking_types?.filter(
        ({ sites, request_url_id }) => sites.length && !cart?.[request_url_id],
      ) || []
    )
  }, [bookingTypes, cart])

  const selectBookingTypeHandler = useCallback(
    (type: { bookingType: BOOKING_TYPE; requestId?: number }) => {
      setActiveBooking({ ...type, visible: true })
    },
    [],
  )

  return (
    <>
      <Title level={2} className='m-0 mb-24 font-semibold'>
        Book
      </Title>

      <Row gutter={[32, 32]}>
        <Show>
          <Show.When isVisible={!isLoading}>
            <>
              <Col xs={24} md={12} lg={8} xxl={4}>
                <button
                  onClick={() => {
                    selectBookingTypeHandler({ bookingType: BOOKING_TYPE.BOOKING })
                    navigate(PATH_SEGMENT.BOOK)
                  }}
                  className={styles.bookingSelection}
                  style={
                    {
                      '--image-url': `url(${newBookingOption.image})`,
                    } as CustomCSSProperties
                  }>
                  {newBookingOption.label}
                </button>
              </Col>

              {availableBookings.map(({ data, request_url_id, type }) => {
                return (
                  <Col xs={24} md={12} lg={8} xxl={4} key={data.id}>
                    <button
                      onClick={() => {
                        selectBookingTypeHandler({ bookingType: type, requestId: request_url_id })
                      }}
                      className={styles.bookingSelection}
                      style={
                        {
                          '--image-url': `url(${data.images[0]})`,
                        } as CustomCSSProperties
                      }>
                      {data.name}
                    </button>
                  </Col>
                )
              })}
            </>
          </Show.When>
          <Show.Else>
            {Array.from({ length: 6 }).map((_, i) => {
              return (
                <Col xs={24} md={12} lg={8} xxl={4} key={i}>
                  <Skeleton.Node active className={styles.bookingSkeleton} />
                </Col>
              )
            })}
          </Show.Else>
        </Show>
      </Row>
    </>
  )
})

export type BookType = typeof ChooseBookingType

ChooseBookingType.displayName = 'ChooseBookingType'
