import { createApi } from '@reduxjs/toolkit/query/react'
import type { BaseQueryFn } from '@reduxjs/toolkit/src/query/baseQueryTypes'
import type { EndpointBuilder } from '@reduxjs/toolkit/src/query/endpointDefinitions'

import { HTTP_METHODS } from 'common/constants/httpMethodsConstants'
import { baseQuery } from 'common/store/api/fetchBaseQuery'
import { REDUCERS } from 'common/store/reducerConstants'

import type { IOptionShopCartItem } from 'features/Option/interfaces/IOptionShopCartItem'

export const optionApi = createApi({
  reducerPath: REDUCERS.OPTION,
  baseQuery: baseQuery(),
  endpoints: (build: EndpointBuilder<BaseQueryFn, string, string>) => ({
    optionInfo: build.query({
      query: ({ id, siteId }) => ({
        url: `/cart/option/${id}`,
        headers: { 'Database-Connection': siteId },
      }),
    }),
    shopOptionInfo: build.query({
      query: ({ bookingId, siteId, optionId, optionType }) => ({
        url: `leases/${bookingId}/shop/${optionType}/${optionId}`,
        headers: { 'Database-Connection': siteId },
      }),
    }),
    createShopCart: build.mutation({
      query: (data) => ({
        data,
        url: `/leases/shop-cart`,
        method: HTTP_METHODS.POST,
      }),
      transformResponse: (response: { data: IOptionShopCartItem }[]): IOptionShopCartItem[] =>
        response.map(({ data }) => data),
    }),
    fetchShopCart: build.query({
      query: () => ({
        url: `/leases-shop-cart`,
      }),
      transformResponse: (response: { data: IOptionShopCartItem }[]): IOptionShopCartItem[] =>
        response.map(({ data }) => data),
    }),
    updateShopCart: build.mutation({
      query: (data) => ({
        data,
        url: `/leases/shop-cart`,
        method: HTTP_METHODS.PUT,
      }),
      transformResponse: (response: { data: IOptionShopCartItem }[]): IOptionShopCartItem[] =>
        response.map(({ data }) => data),
    }),
    checkoutLeaseShop: build.mutation({
      query: ({ bookingId, paymentMethodId, billing_details, siteId }) => ({
        data: { paymentMethodId, billing_details },
        url: `/leases/${bookingId}/checkout`,
        method: HTTP_METHODS.POST,
        headers: { 'Database-Connection': siteId },
      }),
      invalidatesTags: ['Levels'],
    }),
    deleteShopCart: build.mutation({
      query: () => ({
        url: `/leases/shop-cart`,
        method: HTTP_METHODS.DELETE,
      }),
    }),
  }),
})

export const {
  useOptionInfoQuery,
  useShopOptionInfoQuery,
  useLazyShopOptionInfoQuery,
  useLazyFetchShopCartQuery,
  useCreateShopCartMutation,
  useUpdateShopCartMutation,
  useDeleteShopCartMutation,
  useCheckoutLeaseShopMutation,
  useLazyOptionInfoQuery,
}: any = optionApi
